<template>
  <div class="div">
    <h1 class="module-title">Applications</h1>
    <div class="progress-summary-container">
      <div class="header-wrapper flex align-items-center justify-content-between pr-4">
        <div class="header p-d-flex p-ai-center">
          <h3 class="title active">Summary</h3>
        </div>
      </div>
      <Summary />
    </div>
  </div>
</template>

<script>
import Summary from './index.vue';

export default {
  components: { Summary },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.progress-summary-container {
  font-family: 'HK-MEDIUM';
  background-color: $white-color;
  // display: inline-block;
  margin: 1rem 0;

  .header-wrapper {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #02003826;

    .header {
      gap: 2rem;
      padding: 0 1rem;

      .title {
        color: #adadad;
        cursor: pointer;
        font-size: 1.4rem;
        margin: 0;
        padding: 1rem 0 1.5rem 0;
      }
      .active {
        color: $primary-color;
        border-bottom: 3px solid $primary-color;
        font-weight: 500;
      }
    }
  }
}
</style>
