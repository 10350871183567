<template>
  <div>
    <h1 class="module-title">Mobile</h1>
    <TextField
      class="mt-3"
      label="Contact phone number"
      @onInput="getFormData($event, 'mobile')"
      :value="formData.mobile"
      type="number"
      placeholder="Your contact phone number"
    />
    <div class="flex align-items-center">
      <Button class="mt-4" label="Submit" type="primary" @click="goToNext" :disabled="isSubmitDisabled()" />
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { minLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
// import { checkMobileNumber } from "../../../../services/validatePhNo";

export default {
  name: 'Licensing',
  components: {},
  data() {
    return {
      formData: {
        mobile: '',
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        mobile: {
          required,
          // checkMobileNumbers: (mobile) => {
          //   const result = checkMobileNumber(mobile);
          //   return result.isValid;
          // },
          minLength: minLength(10),
        },
      },
    };
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'referralUpdateApplicantProgress',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedApplicantPrgressIndex',
      'referralUpdateIsActiveValue',
    ]),
    ...mapActions({
      setFormData: 'brokerSignup/setReferralFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goBack() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[2].isActive = true;
      currentObj[2].color = '#54B7F9';
      currentObj[2].isCompleted = false;
      currentObj[3].isActive = false;
      currentObj[3].isCompleted = false;
      currentObj[3].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.$router.push('/referral-signup/email');
    },
    goToNext() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[3].isActive = false;
      currentObj[3].isCompleted = true;
      currentObj[3].color = '#54B7F9';
      currentObj[2].isActive = false;
      currentObj[2].color = 'rgba(255, 255, 255, 0.3)';
      currentObj[1].isActive = false;
      currentObj[1].color = 'rgba(255, 255, 255, 0.3)';
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateApplicantProgress(25);
      this.referralUpdateSelectedSubProgressIndex(1);
      this.referralUpdateSelectedApplicantPrgressIndex(1);
      this.referralUpdateIsActiveValue({ index1: 1, index2: 0 });
      this.setFormData(this.formData);
      this.$router.push('/referral-signup/proof-of-identity');
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.formData.mobile = this.referralFormData.mobile ? this.referralFormData.mobile : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 300px;
  color: $secondary-color;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
.send-code {
  top: 10px;
  right: 10px;
  color: #54b7f9;
  font-weight: 600;
  font-size: 1rem;
}
</style>
