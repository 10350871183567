<template>
  <div class="authentication-wrapper">
    <Header :class="isMD ? 'p-4' : ''" />
    <!-- <div class="flex h-100 overflow-y">
      <div class="col flex align-items-center justify-content-center">
        <router-view></router-view>
      </div>
      <div class="col">
        <ProgressPart />
      </div>
    </div> -->
    <div class="flex">
      <div class="router-component">
        <div class="left-section" :class="isMD ? '' : 'p-8'">
          <router-view></router-view>
        </div>
        <div class="right-section">
          <ProgressPart />
        </div>
      </div>
    </div>
    <Footer v-if="!isMD" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Footer from '../Login/Footer.vue';
import Header from '../Login/Header.vue';
import ProgressPart from './ProgressPart.vue';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'index',
  mixins: [Screensize],
  components: {
    Header,
    Footer,
    ProgressPart,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  },
  methods: {
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
    }),
  },
  created() {
    this.setReferralFormData({ referredBy: this.$route?.query?.ref_id });
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.login-screen {
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(180deg, #eef4fa 0%, #ffffff 100%);
}
.authentication-wrapper {
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  display: flex;
  background: linear-gradient(180deg, #eef4fa 0%, #ffffff 100%);
  @media (max-width: 992px) {
    height: 101vh;
  }
}
.col {
  padding: unset !important;
}
.router-component {
  display: flex;
  background-image: linear-gradient(#f1f6fb, white);
  height: calc(100vh - 220px);
  width: 100%;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    height: unset;
  }
  .left-section {
    width: 60%;
    overflow: auto;
    @media (max-width: 992px) {
      width: 100%;
      padding: 25px;
    }
  }
  .right-section {
    width: 40%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}
</style>
