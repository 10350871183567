<template>
  <div>
    <label class="p-float-label label hk-grotest-medium"
      >{{ label }}<span v-if="required" class="required">*</span></label
    >
    <div class="input-wrapper" :style="{ width: width }">
      <span v-if="icon" class="icon-container"><img width="10" height="10" :src="icon" class="icons field" /></span>
      <AutoComplete
        v-model="selectedValue"
        :suggestions="filteredValues"
        @complete="searchValue($event)"
        :placeholder="placeholder"
        :style="{ width: `${width}`, height: `${height}` }"
        style="margin-top: -1rem"
        :disabled="disable"
        field="name"
        @update:model-value="getSelectedValue"
        @change.stop
      >
        <template #item="slotProps">
          <div v-if="slotProps.item.id === 0">
            <span class="plus">+</span> Add new group (My business/broker group isn't listed)
          </div>
          <div v-else>{{ slotProps.item.name }}</div>
        </template>
      </AutoComplete>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AutoComplete from 'primevue/autocomplete';

export default {
  name: 'Autocomplete',
  components: {
    AutoComplete,
  },
  props: [
    'width',
    'height',
    'items',
    'value',
    'placeholder',
    'optionLabel',
    'errorMessages',
    'required',
    'label',
    'disable',
    'icon',
  ],
  data() {
    return {
      filteredValues: null,
      selectedValue: null,
      list: [],
    };
  },
  computed: {
    ...mapGetters('brokerSignup', ['contractedAggregatorGroups']),
  },
  methods: {
    ...mapActions({
      getBrokerGroups: 'brokerSignup/getBrokerGroups',
    }),
    async getSelectedValue(event) {
      this.$emit('change', event);
      const result = await this.getBrokerGroups(event);
      if (result && result.data && result.data.brokerGroups) {
        this.list = result.data.brokerGroups.filter((obj) => {
          return (
            obj.aggregationGroup &&
            (this.contractedAggregatorGroups.includes(obj.aggregationGroup) || obj.aggregationGroup.endsWith('(*)'))
          );
        });
      }
      if (this.value) {
        let item = this.list.find((obj) => obj.name === this.value);
        this.selectedValue = item ? item : { name: "My business/broker group isn't listed", id: 0 };
      }
    },
    getValues(query) {
      const newList = this.list.filter(({ name }) => {
        return name.toLowerCase().includes(query.toLowerCase());
      });
      return newList;
    },
    searchValue(event) {
      this.filteredValues = this.getValues(event.query);
      this.filteredValues = [{ name: "My business/broker group isn't listed", id: 0 }, ...this.filteredValues];
    },
  },
  async created() {
    const result = await this.getBrokerGroups();
    if (result && result.data && result.data.brokerGroup) {
      this.list = result.data.brokerGroups.filter((obj) => {
        return (
          obj.aggregationGroup &&
          (this.contractedAggregatorGroups.includes(obj.aggregationGroup) || obj.aggregationGroup.endsWith('(*)'))
        );
      });
    }
    if (this.value) {
      let item = this.list.find((obj) => obj.name === this.value);
      this.selectedValue = item ? item : { name: "My business/broker group isn't listed", id: 0 };
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-dropdown:not(.p-disabled):hover {
  border: 1px solid rgba(2, 0, 56, 0.3);
  border-left: unset !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: none !important;
  border-left: unset !important;
}
.p-dropdown {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-left: unset !important;
}
.label {
  color: $secondary-color;
}
:deep(.p-inputtext) {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-left: unset !important;
  border-radius: 4px !important;
  font-family: HK-medium !important;
  border-left: unset !important;
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  width: 100% !important;
}
:deep(.p-inputtext) {
  font-family: HK-medium !important;
}
:deep(.p-inputtext:enabled:focus) {
  border-color: rgba(2, 0, 56, 0.15) !important;
  border: 1px solid rgba(100, 97, 170, 0.3) !important;
  box-shadow: unset !important;
  border-left: unset !important;
}
.p-inputtext:enabled:hover {
  border-color: rgba(2, 0, 56, 0.15) !important;
  border: 1px solid rgba(100, 97, 170, 0.3) !important;
  box-shadow: unset !important;
  border-left: unset !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.label {
  color: $secondary-color;
}
.input-wrapper {
  display: flex;
  align-items: center;
}
.icons {
  font-weight: bold !important;
  height: 42px;
  font-size: 1.2rem;
  padding: 10px;
  min-width: 40px;
  border: 1px solid #ced4da;
  border-right: unset !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-bottom: 11px;
  background: #ffffff;
}
.plus {
  color: $primary-color;
  font-weight: bold;
}
</style>
