export const getBrokerPayload = (formData) => {
  const obj = {
    // broker
    firstName: formData.firstName,
    lastName: formData.lastName,
    middleName: formData.middleName,
    businessline1: formData.businessLine1,
    businessline2: formData.businessLine2,
    businessState: formData?.businessState?.toLowerCase(),
    businessCity: formData.businessCity,
    businessCountry: 'au',
    businessPostCode: formData?.businessPostCode,
    email: formData.email,
    licensingType: formData.licensingType,
    aclRegistrationName: formData.aclRegistrationName,
    aclNumber: formData.aclNumber,
    name: formData.accountName,
    crNumber: formData.crNumber,
    proofDocumentNumber: formData.proofDocNumber,
    proofDocumentExpireDate: formData.proofDocExpDate,
    mobilePhoneNumber: formData.mobile,
    brokerGroupId: formData.brokerGroupId,
    otp: formData.otp,
    // optional new broker group
    brokerGroupName: formData.brokerGroupName,
    aggregationGroup: formData.aggregationGroup,
    brokerCount: 1,
    australianBusinessNumber: formData.australianBusinessNumber,
    primaryBusinessActivity: formData.primaryBusinessActivity,
    businessMobileNumber: formData.businessMobileNumber,
  };
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) delete obj[key];
  });
  return obj;
};
