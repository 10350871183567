<template>
  <div class="modal-container">
    <Dialog :visible="display" :modal="true" :style="{ width: '70vw' }">
      <template #header>
        <h1 class="title pm-txt-color">Accept Conditional Offer</h1>
      </template>
      <div class="modal-content">
        <p class="desc lt-txt-color bold-600">
          Based on the information you have provided us, after assessing your financial position we are pleased to
          advise that subject to the conditions outlined in the offer letter, your loan has been conditionally approved.
          <br /><br />
          In order for us to proceed with a formal credit assessment, you will need to pay us an upfront fee, which
          includes your Loan deposit and Valuation Fee. Your Loan Deposit will be credited against the Setup Fee on
          settlement.
          <br /><br />
          Once we receive the Upfront Fee, we can proceed with your loan and undertaking a formal credit assessment.
        </p>

        <div class="icon-wrapper p-d-flex p-ai-center">
          <img :src="require('@/assets/loan_summ_icon.svg')" alt />
          <p class="heading pm-txt-color loan-page">Loan Summary</p>
        </div>
        <div class="section-1 bold-600">
          <div class="grid-items">
            <div class="wrap">
              <p class="heading lt-txt-color">Loan</p>
              <p class="text pm-txt-color">Bridging Home Loan</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Interest free period</p>
              <p class="text pm-txt-color">3 months</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Term</p>
              <p class="text pm-txt-color">6 months</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Interest rate</p>
              <p class="text pm-txt-color">5.99% after interest free period</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Valuation Fee</p>
              <p class="text pm-txt-color">$</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Total set-up fee (%)</p>
              <p class="text pm-txt-color">1.99%</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Deposit</p>
              <p class="text pm-txt-color">$</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Amount</p>
              <p class="text primary-color">$1,000,000</p>
            </div>
          </div>
        </div>

        <div class="section-2">
          <p class="sec-heading pm-txt-color font-size-1 bold-600">Billing address</p>
          <div class="p-d-flex p-ai-center inputs hk-grotest-medium">
            <TextField width="100%" placeholder="25 Bondi Road" label="Address Line 1" />
            <TextField width="100%" placeholder="Bondi Beach" label="Address Line 2" />
            <TextField width="100%" placeholder="Sydney" label="City" />
            <TextField width="100%" placeholder="New south wale" label="State" />
            <TextField width="100%" placeholder="2010" label="Postal Code" />
            <div class="dropdown">
              <Dropdown
                label="Country"
                width="100%"
                placeholder="Enter country"
                :items="assignTo"
                class="mt-2"
                @change="getAssignValue"
              />
            </div>
          </div>
        </div>

        <div class="section-3">
          <p class="sec-heading pm-txt-color font-size-1 bold-600">Card Details</p>
          <div class="p-d-flex p-ai-center inputs hk-grotest-medium">
            <TextField width="100%" placeholder="Jane" label="Cardholder - First name" />
            <TextField width="100%" placeholder="Brown" label="Cardholder - Last name" />
            <TextField width="100%" placeholder="Your credit card number" label="Card number" />
            <TextField width="100%" placeholder="Your credit card expiration" label="Expration date" />
            <TextField width="100%" placeholder="Your credit card CVC" label="CVC" />
          </div>
          <div class="mail-total">
            <TextField width="100%" placeholder="johnbrown@gmail.com" label="Send receipt to" />
            <InputWithIcon
              :format="true"
              :icon="dollar"
              width="100%"
              placeholder="1,600,000"
              label="Total"
              :disable="true"
              class="disable-input"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <div class="modal-foodter bold-600">
          <p class="close" @click="closeDialog">Back</p>
          <Button label="Submit" type="primary" @click="closeDialog" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Dollar from '@/assets/dollar.svg';

export default {
  props: ['display', 'openDialog', 'closeDialog'],
  data() {
    return {
      dollar: Dollar,
      assignTo: ['Australia'],
    };
  },
  methods: {
    getAssignValue(value) {
      return (this.assignedTo = value.value);
    },
  },
  components: { Dialog },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
:deep(.p-inputtext) {
  font-size: 0.875rem !important;
}
:deep(.p-float-label) {
  font-size: 0.875rem !important;
}
:deep(.icons) {
  font-weight: bold !important;
  height: 39px !important;
  padding: 10px;
  min-width: 40px;
  border: 1px solid #ced4da;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-bottom: 11px;
}
.title {
  font-weight: bold;
  color: $black-color;
  font-size: 2.1rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 1.7;
}

.text {
  margin-bottom: 0.3rem;
}

.field-not {
  width: 100%;
}

.heading,
.text {
  font-size: 0.875rem !important;
}
.loan-page {
  font-size: 1.25rem !important;
}
.inputs {
  gap: 1rem;
  align-items: flex-end;
  font-size: 0.875rem !important;
  font-family: HK Grotesk !important;
}

.modal-content {
  border: none;

  .section-1 {
    margin-top: 2rem;
    width: 90%;

    .details-content {
      gap: 1.375rem;
    }

    .grid-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
    }
  }

  .section-2 {
    margin-top: 1.875rem;

    .label {
      margin-bottom: 1rem;
      white-space: nowrap !important;
    }
  }

  .section-3 {
    margin-top: 1.875rem;
    width: 100%;

    .label {
      margin-bottom: 1rem;
      font-size: 0.875rem !important;
    }

    .mail-total {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      margin-top: 1rem;
      gap: 1rem;
    }
  }

  .icon-wrapper {
    gap: 1rem;

    .icon {
      font-size: 2rem;
    }
  }

  .heading {
    margin: 0;
    font-weight: bold;
    font-size: 1.25rem;
  }
}
.modal-foodter {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  float: right;

  .close {
    margin: 0;
    cursor: pointer;
  }
}
</style>
