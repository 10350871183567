<template>
  <Card>
    <template #title><div class="module-sub-title">Fees</div> </template>
    <template #content>
      <div class="justify-content-between" :class="isMD ? '' : 'flex'">
        <div class="col" :class="isMD ? '' : 'col-6'">
          <div>Additional funds requested<span class="required-class">*</span></div>
          <InputWithIcon
            placeholder="Enter amount"
            width="100%"
            :icon="dollar"
            :format="true"
            @onInput="getFormData($event, 'add_funds_requested')"
            type="number"
            :inValid="v$.formData.add_funds_requested.$invalid && onSave"
            :value="formData.add_funds_requested"
          />
        </div>
        <div class="col" :class="isMD ? '' : 'col-6'">
          <div>Set-up fee</div>
          <InputWithIcon
            class="disable-input"
            :disable="true"
            width="100%"
            :icon="percentage"
            placeholder="TBD"
            :value="setup_fee ? (setup_fee * 100).toFixed(4) : ''"
            type="number"
            :key="setupKey"
          />
        </div>
      </div>
      <div class="align-items-center" :class="isMD ? '' : 'flex'">
        <div class="col" :class="isMD ? '' : 'col-6'">
          <div class="margin-top">Total savings to contribute to purchase<span class="required-class">*</span></div>
          <InputWithIcon
            placeholder="Enter amount"
            width="100%"
            :format="true"
            :icon="dollar"
            @onInput="getFormData($event, 'totalSavingsToPurchase')"
            :inValid="v$.formData.totalSavingsToPurchase.$invalid && onSave"
            type="number"
            :value="formData.totalSavingsToPurchase"
          />
        </div>
        <div class="col" :class="isMD ? '' : 'col-6'">
          <div class="margin-top">Broker fee inc GST<span class="required-class">*</span></div>
          <InputWithIcon
            placeholder="Max value is 0.25"
            width="100%"
            :icon="percentage"
            @onInput="getFormData($event, 'brokerFeeIncGst')"
            type="number"
            :value="formData.brokerFeeIncGst"
            :format="true"
            mode="decimal"
            :minFractionDigits="3"
            :maxValue="0.25"
            :key="brokerFeeKey"
            :inValid="v$.formData.brokerFeeIncGst.$invalid && onSave"
          />
        </div>
      </div>
      <div class="align-items-center" :class="isMD ? '' : 'flex'">
        <div class="col" :class="isMD ? '' : 'col-6'">
          <div class="margin-top">Savings to contribute to end debt<span class="required-class">*</span></div>
          <InputWithIcon
            placeholder="Enter amount"
            width="100%"
            :format="true"
            :icon="dollar"
            @onInput="getFormData($event, 'savings_end_debt')"
            type="number"
            :inValid="v$.formData.savings_end_debt.$invalid && onSave"
            :value="formData.savings_end_debt"
          />
        </div>
        <div class="col" :class="isMD ? '' : 'col-6'">
          <div class="margin-top">Quoted set-up fee</div>
          <InputWithIcon
            class="disable-input"
            :disable="true"
            placeholder="TBD"
            width="100%"
            :icon="percentage"
            :value="quoted_fee ? quoted_fee : ''"
            :key="quotedKey"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { integer, maxValue, minValue, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Card from 'primevue/card';
import Dollar from '@/assets/dollar.svg';
import Percentage from '@/assets/percentage.svg';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  name: 'AdditionalInformation',
  setup: () => ({ v$: useVuelidate() }),
  mixins: [ScreenSize],
  validations() {
    return {
      formData: {
        add_funds_requested: {
          required,
          minValue: minValue(0),
          integer,
          maxValue: maxValue(999999999),
        },
        totalSavingsToPurchase: {
          required,
          minValue: minValue(0),
          integer,
          maxValue: maxValue(999999999),
        },
        brokerFeeIncGst: { required, maxValue: maxValue(0.25) },
        savings_end_debt: {
          required,
          minValue: minValue(0),
          integer,
          maxValue: maxValue(999999999),
        },
      },
    };
  },
  props: ['item', 'setup_fee', 'brokerFeeIncGst'],
  components: {
    Card,
  },
  watch: {
    setup_fee() {
      this.setupKey += 1;
    },

    brokerFeeIncGst() {
      this.quoted_fee = (Number(this.setup_fee) * 100 + Number(this.brokerFeeIncGst) * 100).toFixed(4);
      this.quotedKey += 1;
    },
  },
  data() {
    return {
      quoted_fee: '',
      dollar: Dollar,
      setupKey: 0,
      quotedKey: 10,
      percentage: Percentage,
      formData: {
        add_funds_requested: 0,
        totalSavingsToPurchase: 0,
        brokerFeeIncGst: 0.25,
        savings_end_debt: 0,
      },
      brokerFeeKey: 0,
    };
  },
  methods: {
    ...mapActions({
      setFees: 'dashboard/setFees',
      fetchCalculatorFields: 'dashboard/fetchCalculatorFields',
    }),
    getFormData(value, fieldName) {
      if (fieldName === 'brokerFeeIncGst' && value && Number(value) > 0.25) {
        value = 0.25;
        this.formData.brokerFeeIncGst = 0.25;
        this.brokerFeeKey += 1;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.setFees({
        key: fieldName,
        value: Number(value),
      });
      this.$emit('validateFees', this.v$.$invalid);
    },
  },
  computed: {
    ...mapGetters({
      calculatorFields: 'dashboard/calculatorFields',
      onSave: 'dashboard/onSave',
    }),
  },
  async created() {
    await this.fetchCalculatorFields();
    this.$emit('validateFees', this.v$.$invalid);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  min-height: 282px !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 1rem !important;
}
.margin-top {
  margin-top: -1rem;
}
</style>
