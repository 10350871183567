<template>
  <div>
    <h1 class="module-title">Address</h1>
    <div class="mt-3">
      <TextField
        @onInput="(e) => (businessPostCode = e)"
        label="Business Address"
        placeholder="Type address here"
        width="100%"
      />
    </div>
    <div v-if="businessPostCode">
      <TextField
        class="mt-3"
        width="100%"
        label="Street Address"
        placeholder="Address line 1"
        @onInput="getFormData($event, 'businessLine1')"
        :value="formData.businessLine1"
      />
      <TextField
        width="100%"
        placeholder="Address line 2"
        class="mt-2"
        @onInput="getFormData($event, 'businessLine2')"
        :value="formData.businessLine2"
      />

      <div class="flex mt-2">
        <div class="col col-6 mt-3">
          <TextField
            width="95%"
            label="City"
            @onInput="getFormData($event, 'businessCity')"
            :value="formData.businessCity"
          />
        </div>
        <div class="ml-2 col col-6 mt-3">
          <Dropdown
            width="96%"
            @change="getFormData($event, 'businessState')"
            :value="formData.businessState"
            label="State"
            :items="['LA', 'NY', 'SV']"
          />
        </div>
      </div>
      <div class="flex mt-2">
        <div class="col col-6 mt-3">
          <TextField
            width="95%"
            label="Postal code"
            type="number"
            @onInput="getFormData($event, 'businessPostCode')"
            :value="formData.businessPostCode"
            :format="false"
          />
        </div>
        <div class="ml-2 col col-6 mt-3">
          <Dropdown
            width="96%"
            label="Country"
            :items="['USA', 'AUS', 'IND']"
            @change="getFormData($event, 'businessCountry')"
            :value="formData.businessCountry"
          />
        </div>
      </div>
    </div>
    <div class="flex align-items-center">
      <Button
        class="mt-4"
        icon="pi pi-chevron-right"
        iconPos="right"
        label="Next"
        type="primary"
        @click="goToNext"
        :disabled="isSubmitDisabled()"
      />
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'Address',
  components: {},
  data() {
    return {
      businessPostCode: '',
      formData: {
        businessLine1: '',
        businessLine2: '',
        businessCity: '',
        businessState: '',
        businessPostCode: '',
        businessCountry: '',
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      businessPostCode: { required },
      formData: {
        businessLine1: { required },
        businessLine2: { required },
        businessCity: { required },
        businessState: { required },
        businessPostCode: { required },
        businessCountry: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      'updateSubProgressList',
      'updateApplicantProgress',
      'updateSelectedSubProgressIndex',
      'updateSelectedSubProgressIndex',
      'updateSelectedApplicantPrgressIndex',
      'updateIsActiveValue',
    ]),
    ...mapActions({
      setFormData: 'brokerSignup/setFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goBack() {
      let currentObj = this.subProgressList[this.selectedSubProgressIndex];
      currentObj[0].isActive = true;
      currentObj[0].color = '#54B7F9';
      currentObj[0].isCompleted = false;
      currentObj[1].isActive = false;
      currentObj[1].color = 'rgba(255, 255, 255, 0.3)';
      this.updateSubProgressList(currentObj);
      this.$router.push('/signup/contract');
    },
    goToNext() {
      let currentObj = this.subProgressList[this.selectedSubProgressIndex];
      currentObj[1].isActive = false;
      currentObj[1].isCompleted = true;
      currentObj[1].color = '#54B7F9';
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.updateSubProgressList(currentObj);
      this.updateApplicantProgress(75);
      this.updateSelectedSubProgressIndex(3);
      this.updateSelectedApplicantPrgressIndex(3);
      this.updateIsActiveValue({ index1: 3, index2: 0 });
      this.setFormData(this.formData);
      this.$router.push('/signup/proof-of-identity');
    },
  },
  computed: {
    ...mapGetters(['selectedSubProgressIndex', 'subProgressList']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 350px;
  color: $secondary-color;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
</style>
