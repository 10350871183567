<template>
  <div class="property-deposti-wrapper">
    <h1 class="module-title loan-progress-title">Declaration</h1>
    <div>
      <p class="label pm-txt-color hk-grotest-medium">
        I have never been declared bankrupt or been subject to control under the Bankruptcy Act 1966 within the last 10
        years.
      </p>
      <form action="" class="flex alignt-items-center pl-2">
        <div class="radio-input flex align-items-center">
          <input type="radio" id="bankrupt" name="bankrupt" v-model="bankrupt" :value="true" />
          <label
            :class="{ disabled: bankrupt === null || bankrupt === false }"
            class="radio-label hk-grotest-medium"
            for="bankrupt"
            >True</label
          >
        </div>
        <div class="radio-input flex align-items-center">
          <input type="radio" id="bankrupt_false" name="bankrupt" v-model="bankrupt" :value="false" />
          <label
            :class="{ disabled: bankrupt === null || bankrupt }"
            class="radio-label hk-grotest-medium"
            for="bankrupt_false"
            >False</label
          >
        </div>
      </form>
    </div>
    <div>
      <p class="label pm-txt-color hk-grotest-medium">
        I have never been found guilty of any offence or crime or been charged with any offence which has not been fully
        determined before a registration or otherwise withdrawn or dismissed within the last 5 years.
      </p>
      <form action="" class="flex alignt-items-center pl-2">
        <div class="radio-input flex align-items-center">
          <input type="radio" id="crime_true" name="crime" v-model="crime" :value="true" />
          <label
            :class="{ disabled: crime === null || crime === false }"
            class="radio-label hk-grotest-medium"
            for="crime_true"
            >True</label
          >
        </div>
        <div class="radio-input flex align-items-center">
          <input type="radio" id="crime_false" name="crime" v-model="crime" :value="false" />
          <label :class="{ disabled: crime === null || crime }" class="radio-label hk-grotest-medium" for="crime_false"
            >False</label
          >
        </div>
      </form>
    </div>
    <div>
      <p class="label pm-txt-color hk-grotest-medium">
        I have never had an application for the grant or renewal of a financial services license or registration been
        refused or declined.
      </p>
      <form action="" class="flex alignt-items-center pl-2">
        <div class="radio-input flex align-items-center">
          <input type="radio" id="registration_true" name="registration" v-model="registration" :value="true" />
          <label
            :class="{
              disabled: registration === null || registration === false,
            }"
            class="radio-label hk-grotest-medium"
            for="registration_true"
            >True</label
          >
        </div>
        <div class="radio-input flex align-items-center">
          <input type="radio" id="registration_false" name="registration" v-model="registration" :value="false" />
          <label
            :class="{ disabled: registration === null || registration }"
            class="radio-label hk-grotest-medium"
            for="registration_false"
            >False</label
          >
        </div>
      </form>
    </div>
    <div>
      <p class="label pm-txt-color hk-grotest-medium">
        I have never had a lender, originator or industry association decline an application or withdraw your/their
        accreditation of membership.
      </p>
      <form action="" class="flex alignt-items-center pl-2">
        <div class="radio-input flex align-items-center">
          <input type="radio" id="lender_true" name="lendr" v-model="lender" :value="true" />
          <label
            :class="{ disabled: lender === null || lender === false }"
            class="radio-label hk-grotest-medium"
            for="lender_true"
            >True</label
          >
        </div>
        <div class="radio-input flex align-items-center">
          <input type="radio" id="lender_false" name="lender" v-model="lender" :value="false" />
          <label
            :class="{ disabled: lender === null || lender }"
            class="radio-label hk-grotest-medium"
            for="lender_false"
            >False</label
          >
        </div>
      </form>
    </div>
    <div>
      <p class="label pm-txt-color hk-grotest-medium">
        I have never had any license, registration or permission to carry a business under any enactment suspended or
        cancelled, or otherwise been disqualified from carrying on any occupation, profession or business.
      </p>
      <form action="" class="flex alignt-items-center pl-2">
        <div class="radio-input flex align-items-center">
          <input type="radio" id="license_true" name="license" v-model="license" :value="true" />
          <label
            :class="{ disabled: license === null || license === false }"
            class="radio-label hk-grotest-medium"
            for="license_true"
            >True</label
          >
        </div>
        <div class="radio-input flex align-items-center">
          <input type="radio" id="license_false" name="license" v-model="license" :value="false" />
          <label
            :class="{ disabled: license === null || license }"
            class="radio-label hk-grotest-medium"
            for="license_false"
            >False</label
          >
        </div>
      </form>
    </div>
    <div>
      <p class="label pm-txt-color hk-grotest-medium">
        I have never had any Professional Indemnity Insurance (PI) declined in the last 5 years.
      </p>
      <form action="" class="flex alignt-items-center pl-2">
        <div class="radio-input flex align-items-center">
          <input type="radio" id="indemnity_true" name="indemnity" v-model="indemnity" :value="true" />
          <label
            :class="{ disabled: indemnity === null || indemnity === false }"
            class="radio-label hk-grotest-medium"
            for="indemnity_true"
            >True</label
          >
        </div>
        <div class="radio-input flex align-items-center">
          <input type="radio" id="indemnity_false" name="indemnity" v-model="indemnity" :value="false" />
          <label
            :class="{ disabled: indemnity === null || indemnity }"
            class="radio-label hk-grotest-medium"
            for="indemnity_false"
            >False</label
          >
        </div>
      </form>
    </div>

    <div class="flex align-items-center mt-4">
      <Button
        icon="pi pi-chevron-right"
        iconPos="right"
        label="Next"
        type="primary"
        @click="goToNext"
        :disabled="isSubmitDisabled()"
      />
      <div class="back ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      bankrupt: { required },
      crime: { required },
      lender: { required },
      license: { required },
      registration: { required },
      indemnity: { required },
    };
  },
  data() {
    return {
      bankrupt: null,
      crime: null,
      lender: null,
      license: null,
      registration: null,
      indemnity: null,
    };
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSubProgressList',
      'referralUpdateApplicantProgress',
    ]),
    ...mapActions({
      setReferralBrokerDeclaration: 'brokerSignup/setReferralBrokerDeclaration',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    goBack() {
      let currentObj = this.referralSubProgressList[5];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateSelectedSubProgressIndex(4);
      let prevObj = this.referralSubProgressList[4];
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      prevObj[0].isCompleted = false;
      this.referralUpdateSubProgressList(prevObj);
      this.referralUpdateSelectedSubProgressIndex(4);
      this.referralUpdateApplicantProgress(82);
      this.$router.push('/referral-signup/verification');
    },
    getFormData() {
      let newArr = [
        {
          type: 'declaration',
          description: 'bankrupt',
          accepted: this.bankrupt,
        },
        {
          type: 'declaration',
          description: 'crime',
          accepted: this.crime,
        },
        {
          type: 'declaration',
          description: 'lender',
          accepted: this.lender,
        },
        {
          type: 'declaration',
          description: 'license',
          accepted: this.license,
        },
        {
          type: 'declaration',
          description: 'registration',
          accepted: this.registration,
        },
        {
          type: 'declaration',
          description: 'indemnity',
          accepted: this.indemnity,
        },
      ];
      this.setReferralBrokerDeclaration(newArr);
    },
    goToNext() {
      this.getFormData();
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[1].isActive = true;
      currentObj[1].color = '#54B7F9';
      currentObj[0].isActive = false;
      currentObj[0].isCompleted = true;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.$router.push('/referral-signup/agreement');
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.bankrupt = this.referralFormData.declaration[0].accepted;
    this.crime = this.referralFormData.declaration[1].accepted;
    this.lender = this.referralFormData.declaration[2].accepted;
    this.license = this.referralFormData.declaration[3].accepted;
    this.registration = this.referralFormData.declaration[4].accepted;
    this.indemnity = this.referralFormData.declaration[5].accepted;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.content {
  width: 70%;
}

.margin-top {
  margin-top: 1rem;
}

.label,
label {
  font-size: 1.125rem;
}

.radio-label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}

form {
  gap: 1rem;
  margin-bottom: 1rem;
  // .disabled {
  //   color: $light-text-color !important;
  // }
  input[type='radio'] {
    width: 1.125rem;
    height: 1.125rem;
  }
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
input[type='radio'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(2, 0, 56, 0.3);
  border-radius: 50%;
  outline: none;
}
input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: $secondary-color;
}
</style>
