<template>
  <div class="property-deposti-wrapper">
    <h1 class="module-title loan-progress-title">Applicant signature</h1>
    <div class="margin-top text-dropdown std-margin-b">
      <div class="col">
        <div class="title">First name</div>
        <div class="content-text">
          {{ referralFormData.firstName }}
        </div>
      </div>

      <div class="col">
        <div class="title">Last name</div>
        <div class="content-text">
          {{ referralFormData.lastName }}
        </div>
      </div>
    </div>
    <div class="title p-float-label label hk-grotest-medium mt-4 mb-1">
      Draw your signature with your mouse or touch.
    </div>
    <div class="signature-container mt-4">
      <VueSignaturePad
        :customStyle="{
          background: 'white',
          border: '1px solid #02003826',
          borderRadius: '4px',
        }"
        height="200px"
        ref="signaturePad"
        :options="{ onBegin, onEnd }"
      />
      <div class="clear"><span @click="clearSignature">Clear</span></div>
    </div>
    <div class="spinner-container" v-if="isLoadingSpinner">
      <span>Uploading signature..</span><Spinner class="flex align-items-center" />
    </div>
    <br />
    <div class="flex align-items-center">
      <Button label="Submit" type="primary" @click="goToNext" :disabled="buttonDisabled || !imgsrc" />
      <div class="back-button ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
    <div class="spinner-container" v-if="buttonDisabled">
      <span>Generating Application..</span><Spinner class="flex align-items-center" />
    </div>
    <Toast group="bc">
      <template #message>
        <div class="p-d-flex p-flex-column">
          <div class="p-text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            <h4>You have an existing active application, are you sure to proceed?</h4>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
              <Button class="p-button-success" label="Yes" @click="onConfirm" />
            </div>
            <div class="p-col-6">
              <Button class="p-button-secondary" label="No" @click="onReject" />
            </div>
          </div>
        </div>
      </template>
    </Toast>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Toast from 'primevue/toast';
// import { SERVER_URL_5 } from "../../../../../constants";
// import { _post } from "../../../../services/crudService";

export default {
  data() {
    return {
      isToast: false,
      buttonDisabled: false,
      imgsrc: '',
      loadingLabel: 'Generating Broker..',
      isLoading: false,
      isLoadingSpinner: false,
    };
  },
  components: {
    Toast,
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'updateApplicantProgress',
      'updateSelectedSubProgressIndex',
      'updateSelectedSubProgressIndex',
      'updateSelectedApplicantPrgressIndex',
      'setLogout',
    ]),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
      createReferral: 'brokerSignup/createReferral',
    }),
    async onConfirm() {},
    onReject() {},
    async goToNext() {
      try {
        this.buttonDisabled = true;
        this.setReferralFormData({
          broker_signature: this.imgsrc,
          broker_signature_name: this.referralFormData.mobile,
        });
        await this.createReferral();
        await this.setLogout({ token: '' });
        history.pushState(null, null, `${location.origin}/success`);
        history.go(1);
        location.reload();
        this.buttonDisabled = false;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
        this.buttonDisabled = false;
      }
    },
    goBack() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[1].isActive = true;
      currentObj[1].color = '#54B7F9';
      currentObj[1].isCompleted = false;
      currentObj[2].isActive = false;
      currentObj[2].isCompleted = false;
      currentObj[2].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.$router.push('/referral-signup/agreement');
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.imgsrc = '';
    },
    async onEnd() {
      // isEmpty,
      try {
        this.isLoadingSpinner = true;
        // const { data } = this.$refs.signaturePad.saveSignature();
        // TODO: update when V2 referral urls are ready
        // await _post(SERVER_URL_5 + "upload_documents_before_signup", {
        //   document_name: "Signature.png",
        //   type: "broker",
        //   is_supporting: true,
        //   applicationId: "",
        //   document: data.replace("data:", "").replace(/^.+,/, ""),
        //   documentType: "supporting_documents",
        //   mobile: this.referralFormData.proofFileUrl,
        // });
        this.imgsrc = this.referralFormData.proofFileUrl;
        this.isLoadingSpinner = false;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.isLoadingSpinner = false;
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, please upload again',
          life: 3000,
        });
      }
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.imgsrc = '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.content {
  width: 70%;
}

.margin-top {
  margin-top: 1rem;
}

.label,
label {
  font-size: 1.125rem;
}

.radio-label {
  margin-left: 0.4rem;
  margin-bottom: 0;
}

form {
  gap: 1rem;

  input[type='radio'] {
    width: 1.125rem;
    height: 1.125rem;
  }
}

.col {
  padding: 0 !important;
}

.dropdown-label {
  margin-bottom: 0.7rem;
}

.text-dropdown {
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::v-deep .p-float-label,
label {
  font-size: 1.125rem;
  color: $secondary-color !important;
  margin-bottom: 0.7rem;
}
.alert {
  font-size: 14px;
  color: $primary-color;
}
.margin-top-space {
  margin-top: -1rem;
}
.content-text {
  border: 1px solid rgba(2, 0, 56, 0.15);
  padding: 1rem;
  color: rgba(2, 0, 56, 0.4);
  border-radius: 4px;
}
.col .title {
  margin-bottom: 1rem;
}
.signature-container {
  position: relative;
  canvas {
    background: white !important;
  }
  .clear {
    font-weight: 600;
    font-size: 18px;
    color: #54b7f9;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
    .save {
      color: green;
    }
  }
}
.spinner-container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  span {
    font-weight: 800;
  }
  :deep(.p-progress-spinner) {
    margin: 0 !important;
  }
}
</style>
