<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// import Application from "./Applications.vue";
// import ToDo from "./ToDo.vue";
// import Earnings from "./Earnings.vue";
// import SendReferral from "./SendReferral.vue";
// import ReferAndEarn from "./ReferAndEarn.vue";
// import ReferanceLink from "./ReferanceLink.vue";
// import ContactCards from "./Contact.vue";
// import Payment from "./Payment.vue";

export default {
  name: 'index',
  components: {
    // Application,
    // ToDo,
    // Earnings,
    // SendReferral,
    // ReferAndEarn,
    // ReferanceLink,
    // ContactCards,
    // Payment,
  },
  data() {
    return {
      isDashboard: true,
    };
  },
  methods: {
    goToCalculator() {
      this.$router.push('/servicing-calculator');
    },
    goToClient() {
      this.isDashboard = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
