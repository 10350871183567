<template>
  <div class="checkbox-wrapper">
    <h4 class="title">{{ title }}</h4>
    <div v-for="category of list" :key="category.id">
      <div :class="customClass" class="p-field-checkbox sd">
        <Checkbox :id="category.id" :name="name" :value="category.label" v-model="value" @change="EmitToParent" />
        <label class="label-text" :for="category.id">{{ category.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'primevue/checkbox';

export default {
  props: {
    categories: {
      type: Array,
    },
    name: {
      type: String,
      required: true,
    },
    title: String,
    customClass: String,
  },
  emits: ['getInputValue'],
  data() {
    return {
      value: [],
      list: [],
    };
  },
  methods: {
    EmitToParent() {
      this.$emit('getInputValue', this.value);
    },
  },
  components: { Checkbox },
  created() {
    this.list = [...this.categories];
    this.value = this.list.map((obj) => {
      return obj.value;
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.label-text {
  font-family: HK-Medium;
}
:deep(.p-checkbox .p-checkbox-box .p-checkbox-icon) {
  color: $secondary-color;
}
:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: #ced4da !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover) {
  border-color: #ced4da !important;
}
</style>
