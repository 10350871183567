export const applicationProgress = [
  { id: 1, title: 'Broker contracting party details', isActive: true },
  { id: 2, title: 'Personal details', isActive: false },
  { id: 3, title: 'Verify identify', isActive: false },
  { id: 4, title: 'Licensing/registration', isActive: false },
  { id: 5, title: 'Declaration', isActive: false },
];

export const selectedApplicantPrgressIndex = 0;
export const progressBarPercentage = 6;
export const subProgressList = [
  [
    {
      id: 1,
      title: 'Contracting party details',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
  ],
  [
    {
      id: 1,
      title: 'Name',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
    {
      id: 2,
      title: 'Email',
      isActive: false,
      isCompleted: false,
      color: 'rgba(255, 255, 255, 0.3)',
    },
  ],
  [
    {
      id: 1,
      title: 'Mobile number verification',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
  ],
  [
    {
      id: 1,
      title: 'Licensing/registration details',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
  ],
  [
    {
      id: 1,
      title: 'Agreement',
      isActive: false,
      isCompleted: false,
      color: '#2cdd80',
    },
  ],
];

// Referral singup

export const referralApplicationProgress = [
  { id: 1, title: 'Personal details', isActive: true },
  { id: 2, title: 'Proof of identity', isActive: false },
  { id: 3, title: 'Licensing/registration', isActive: false },
  { id: 4, title: 'Payment details', isActive: false },
  { id: 5, title: 'Verify identify', isActive: false },
  { id: 6, title: 'Declaration', isActive: false },
];

export const referralSelectedApplicantPrgressIndex = 0;
export const referralProgressBarPercentage = 6;
export const referralSubProgressList = [
  [
    {
      id: 1,
      title: 'Name',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
    {
      id: 2,
      title: 'Address',
      isActive: false,
      isCompleted: false,
      color: 'rgba(255, 255, 255, 0.3)',
    },
    {
      id: 3,
      title: 'Email',
      isActive: false,
      isCompleted: false,
      color: 'rgba(255, 255, 255, 0.3)',
    },
    {
      id: 4,
      title: 'Mobile',
      isActive: false,
      isCompleted: false,
      color: 'rgba(255, 255, 255, 0.3)',
    },
  ],
  [
    {
      id: 1,
      title: 'Proof of identity',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
  ],
  [
    {
      id: 1,
      title: 'Company Address',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
    // {
    //     id: 2, title: 'Company Address', isActive: false, isCompleted: false, color: "rgba(255, 255, 255, 0.3)",
    // },
  ],
  [
    {
      id: 1,
      title: 'Account details',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
  ],
  [
    {
      id: 1,
      title: 'Mobile number verification',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
  ],
  [
    {
      id: 1,
      title: 'Decalaration',
      isActive: true,
      isCompleted: false,
      color: '#2cdd80',
    },
    {
      id: 2,
      title: 'Agreement',
      isActive: false,
      isCompleted: false,
      color: 'rgba(255, 255, 255, 0.3)',
    },
    {
      id: 3,
      title: 'Signature',
      isActive: false,
      isCompleted: false,
      color: 'rgba(255, 255, 255, 0.3)',
    },
  ],
];

export const selectedSubProgressIndex = 0;

export const brokerInitialFormData = {
  type: 'broker',
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  mobile: '',
  line1: '',
  line2: '',
  state: '',
  city: '',
  country: '',
  postCode: 0,
  businessLine1: '',
  businessLine2: '',
  businessState: '',
  businessCity: '',
  businessCountry: '',
  businessPostCode: 0,
  businessMobileNumber: '',
  accountName: '',
  bsb: '',
  accNo: '',
  licensingType: '',
  aclRegistrationName: '',
  aclNumber: '',
  name: '',
  crNumber: '',
  proofType: '',
  proofDocNumber: '',
  proofDocExpDate: '',
  aggregationGroup: '',
  brokerGroupName: '',
  numberOfBrokers: '',
  primaryBusinessActivity: '',
};
