<template>
  <div class="toolbar-container">
    <div class="menu-icon-container mr-2">
      <img class="menu-icon" v-if="!toggleSideNavClicked" @click="toggleSidenav" src="@/assets/menu_icon.svg" />
      <img class="menu-icon" v-if="toggleSideNavClicked" @click="closeSidenav" src="@/assets/close_icon.svg" />
    </div>
    <img v-if="customLogo" :src="customLogo" class="cursor-pointer logo-icon" @click="goToDashboard" />
    <img v-else src="@/assets/bridgit.svg" class="cursor-pointer logo-icon" @click="goToDashboard" />
    <div class="right-col p-d-flex p-ai-center" v-click-outside="clickOutside">
      <!-- <div class="bell-icon icon-wrapper">
        <img src="@/assets/notification.svg" />
      </div> -->
      <div class="p-d-flex p-ai-center user-avatar-name-wrapper">
        <div class="user-name">
          <h3 class="name">
            {{ profileDetails.firstName }} {{ profileDetails.middleName }}
            {{ profileDetails.lastName }}
          </h3>
        </div>
        <div class="drop-down-menu">
          <div @click="toggleDropdownMenu" class="icon-wrapper">
            <img src="@/assets/down-arrow.svg" class="cursor-pointer" />
          </div>
          <div @click="showDropdownMenu = !showDropdownMenu" v-if="showDropdownMenu" class="menu-items">
            <div @click="goToTerms" class="item-list p-d-flex p-ai-center p-jc-sm-between">
              <p class="text">Terms & Conditions</p>
              <div class="list-icon-wrapper p-d-flex p-ai-center p-jc-center">
                <i class="icon-right pi pi-angle-right"></i>
              </div>
            </div>
            <div @click="goToPrivacy" class="item-list p-d-flex p-ai-center p-jc-sm-between">
              <p class="text">Privacy Policy</p>
              <div class="list-icon-wrapper p-d-flex p-ai-center p-jc-center">
                <i class="icon-right pi pi-angle-right"></i>
              </div>
            </div>
            <div @click="goToContact" class="item-list p-d-flex p-ai-center p-jc-sm-between">
              <p class="text">Contact Us</p>
              <div class="list-icon-wrapper p-d-flex p-ai-center p-jc-center">
                <i class="icon-right pi pi-angle-right"></i>
              </div>
            </div>
            <div @click="goToSettings" class="item-list p-d-flex p-ai-center p-jc-sm-between">
              <p class="text">Settings</p>
              <div class="list-icon-wrapper p-d-flex p-ai-center p-jc-center">
                <i class="icon-right pi pi-angle-right"></i>
              </div>
            </div>
            <div class="item-list p-d-flex p-ai-center p-jc-sm-between">
              <p class="text" @click="signOut">Sign Out</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Toolbar from "primevue/toolbar";
import { mapActions, mapGetters } from 'vuex';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  props: ['toggleSideNavClicked', 'toolBarKey'],
  name: 'ToolbarComponent',
  mixins: [ScreenSize],
  components: {
    // Toolbar,
  },
  data() {
    return {
      showDropdownMenu: false,
      showSideNav: true,
    };
  },
  methods: {
    ...mapActions(['setLogin']),
    ...mapActions({
      getBrokerById: 'settings/getBrokerById',
    }),
    toggleDropdownMenu() {
      return (this.showDropdownMenu = !this.showDropdownMenu);
    },
    signOut() {
      this.setLogin(false);
    },
    goToTerms() {
      const url =
        process.env.VUE_APP_CUSTOM_THEME_BROKER_TERMS_AND_CONDITIONS_URL ??
        'https://www.bridgit.com.au/broker-terms-and-conditions';
      window.open(url, '_blank');
    },
    goToSettings() {
      this.$router.push('/settings');
    },
    goToContact() {
      this.$router.push('/contact');
    },
    goToPrivacy() {
      const url = process.env.VUE_APP_CUSTOM_THEME_PRIVACY_POLICY_URL ?? 'https://www.bridgit.com.au/privacy-policy';
      window.open(url, '_blank');
    },
    clickOutside() {
      this.showDropdownMenu = false;
    },
    goToDashboard() {
      this.$router.push('/dashboard');
    },
    toggleSidenav() {
      this.showSideNav = true;
      this.$emit('change', this.showSideNav);
    },
    closeSidenav() {
      this.showSideNav = false;
      this.$emit('change', this.showSideNav);
    },
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
    customLogo() {
      return process.env.VUE_APP_CUSTOM_THEME_LOGO;
    },
  },
  async created() {
    await this.getBrokerById();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-toolbar {
  background: $white-color;
  border: unset !important;
  padding: 0;
  padding: 0 1rem;
  height: 80px;
}

.right-col {
  margin: 0 !important;
  padding: 0 !important;

  gap: 2rem;

  .bell-icon {
    position: relative;
    @media (max-width: 992px) {
      border-right: none;
      padding-right: 0;
    }
    .icon {
      font-size: 1.5rem;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: orange;
        border-radius: 50%;
        right: 0;
        top: 2px;
      }
    }
  }

  .user-avatar-name-wrapper {
    // border-left: 1px solid rgba(2, 0, 56, 0.3);
    padding-left: 2rem;
    gap: 1rem;
    position: relative;
    height: 30px;
    @media (max-width: 992px) {
      gap: 0;
      border-left: unset;
      padding-left: unset;
    }

    .user-name {
      .name {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        color: $secondary-color;
      }
      @media (max-width: 992px) {
        display: none;
      }
    }

    .drop-down-menu {
      .icon {
        width: 25px;
        height: 25px;
        border: 1px solid $secondary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        color: $secondary-color;
      }

      .menu-items {
        position: absolute;
        top: 4rem;
        right: 0;
        background-color: $white-color;
        width: 230px;
        padding: 1rem 1rem;
        border-radius: 5px;
        box-shadow: 0px 2px 3px #686868;
        z-index: 99;
        border: none;
        user-select: none;

        &:after {
          content: '';
          position: absolute;
          display: block;
          top: -13px;
          right: 4%;
          border-right: 12px solid transparent;
          border-left: 12px solid transparent;
          border-bottom: 12px solid $white-color;
          font-size: 0;
          line-height: 0;
          width: 0;
        }

        .item-list {
          cursor: pointer;

          .text {
            font-size: 1.05rem;
            margin: 0;
            margin: 0.5rem 0;
          }
        }
      }
    }
  }
}
.menu-icon-container {
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
  .menu-icon {
    width: 40px;
    height: 40px;
  }
}
.logo-icon {
  height: 50px;
}
.toolbar-container {
  display: flex;
  background: $white-color;
  border: unset !important;
  padding: 0;
  padding: 0 1rem;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    .logo-icon {
      height: 50px;
    }
  }
}
</style>
