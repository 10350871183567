<template>
  <div class="modal-container">
    <Dialog :visible="displayBasic" :modal="displayBasic" :style="{ width: isMD ? '' : '70vw' }">
      <template #header>
        <h1 class="title pm-txt-color">Property - summary</h1>
      </template>
      <div class="modal-content">
        <div class="icon-wrapper">
          <img :src="require('@/assets/document_icon.svg')" alt />
        </div>
        <form action>
          <div class="input-wrapper">
            <p class="heading pm-txt-color">Property address</p>
            <p class="text pm-txt-color">
              To complete your application submission, please update the address of the incoming property.
            </p>
            <br />
            <div>
              <AddressAutoCompleteKleberNew label="Property Address" @onChange="onAddressChange" />
            </div>
          </div>
          <div class="input-wrapper">
            <p class="heading pm-txt-color">Property details</p>
            <p class="text pm-txt-color">
              Please check to ensure the following information is true and update it if necessary.
            </p>
            <div :class="isMD ? '' : 'p-d-flex p-ai-center'">
              <div class="col">
                <label>Property type</label>
                <Dropdown
                  :items="tasktypes"
                  @change="getFormData($event.value, 'type')"
                  width="100%"
                  optionLabel="name"
                  :value="type"
                  :key="typeKey"
                />
              </div>
              <div class="col mt-0">
                <label>Deposit amount</label>
                <InputNumber
                  class="mt-3"
                  width="100%"
                  @onInput="getFormData($event, 'depositAmount')"
                  :value="formData.buying_property.depositAmount && formData.buying_property.depositAmount"
                  :prefix="'$'"
                  :format="true"
                />
              </div>
            </div>
          </div>
          <div :class="isMD ? '' : 'p-d-flex p-ai-center'">
            <div class="col">
              <label class="mb-0">Purchase price</label>
              <InputNumber
                class="mt-3"
                @onInput="getFormData($event, 'purchasePrice')"
                width="100%"
                type="number"
                :value="formData.buying_property.purchasePrice && formData.buying_property.purchasePrice"
                prefix="$"
                :format="true"
              />
            </div>
            <div class="col">
              <label>Purchase date</label>
              <DatePicker
                width="100%"
                @change="getFormData($event, 'purchase_date')"
                :value="formData.buying_property.purchase_date && formData.buying_property.purchase_date"
                :key="dateKey"
              />
            </div>
          </div>
          <div>
            <p class="label pm-txt-color mt-3">How was your deposit paid?</p>
            <form action class="flex align-items-center">
              <div class="radio-input flex align-items-center">
                <input
                  id="cash"
                  type="radio"
                  name="deposit"
                  value="cash"
                  v-model="formData.buying_property.depositPaidType"
                />
                <label class="radio-label" for="cash">Cash Payment</label>
              </div>
              <div class="radio-input flex align-items-center">
                <input
                  id="bond"
                  type="radio"
                  name="deposit"
                  value="bond"
                  v-model="formData.buying_property.depositPaidType"
                />
                <label class="radio-label" for="bond">Deposit bond</label>
              </div>
            </form>
          </div>
        </form>
      </div>
      <template #footer>
        <div class="modal-foodter">
          <p class="close" @click="closeBasic">Back</p>

          <Button :disabled="isSubmitDisabled() || isLoading" label="Update and Submit" type="primary" @click="save" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { TECHLEND_BE_URL } from '@/constants.api';
import { _put } from '@/services/crudService';
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import AddressAutoCompleteKleberNew from '@/components/common/AddressAutoCompleteKleberNew.vue';
import Dialog from 'primevue/dialog';
import InputNumber from '@/components/common/InputNumber.vue';
import Screensize from '@/mixins/screensize.mixin';

export default {
  props: ['closeBasic', 'openBasic', 'displayBasic'],
  mixins: [Screensize],
  components: { Dialog, AddressAutoCompleteKleberNew, InputNumber },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isLoading: false,
      tasktypes: [
        { name: 'Residential', value: 'residential' },
        { name: 'Rural Residential', value: 'rural_residential' },
        { name: 'Off the plan residential', value: 'off_plan_residential' },
        { name: 'Commercial', value: 'commercial' },
        { name: 'Vacant Land', value: 'vacant_land' },
      ],
      typeKey: 0,
      dateKey: 10,
      formData: {
        applicationId: '',
        buying_property_id: '',
        application_stage: '',
        buying_property: {
          line1: '',
          line2: '',
          postCode: 0,
          country: '',
          city: '',
          state: '',
          depositPaidType: '',
          depositAmount: 0,
          purchasePrice: 0,
          purchase_date: '',
          type: '',
          unitNumber: '',
          streetNumber: '',
          streetType: '',
          streetName: '',
          zone: '',
          premium: '',
          category: '',
        },
        status: '',
      },
    };
  },
  validations() {
    return {
      formData: {
        buying_property: {
          line1: { required },
          postCode: { required },
          country: { required },
          city: { required },
          state: { required },
          depositPaidType: { required },
          depositAmount: { required },
          purchasePrice: { required },
          purchase_date: { required },
          type: { required },
        },
      },
    };
  },
  methods: {
    ...mapActions({
      fetchZoneByPostal: 'dashboard/fetchZoneByPostal',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    async onAddressChange(address) {
      this.formData.buying_property.line1 = address.line1 ? address.line1 : '';
      this.formData.buying_property.line2 = address.line2 ? address.line2 : '';
      this.formData.buying_property.city = address.line1 ? address.city : '';
      this.formData.buying_property.state = address.state ? address.state : '';
      this.formData.buying_property.country = address.country ? address.country : '';
      this.formData.buying_property.postCode = address.postCode ? Number(address.postCode) : 0;
      this.formData.buying_property.unitNumber = address.unitNumber ? address.unitNumber : '';
      this.formData.buying_property.streetNumber = address.streetNumber ? address.streetNumber : '';
      this.formData.buying_property.streetType = address.streetType ? address.streetType : '';
      this.formData.buying_property.streetName = address.streetName ? address.streetName : '';
      const res = await this.fetchZoneByPostal(
        this.formData.buying_property.postCode ? this.formData.buying_property.postCode : '',
      );
      this.formData.buying_property.zone = res.zone ? res.zone : '';
      this.formData.buying_property.premium = res.premium;
      this.formData.buying_property.category = res.category ? res.category : '';
    },
    getFormData(value, fieldName) {
      this.formData.buying_property[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async save() {
      try {
        this.isLoading = true;
        await _put(TECHLEND_BE_URL + 'brokers/tasks/' + this.selectedTaskId, {
          applicationId: this.applicationId,
          status: 'review',
          buying_property: {
            ...this.formData.buying_property,
          },
          buying_property_id: this.propId,
          application_stage: 'created',
        });
        this.isLoading = false;
        this.$emit('completed');
      } catch (err) {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong',
          life: 3000,
        });
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      selectedTaskId: 'applications/selectedTaskId',
      applicationId: 'applications/applicationId',
      propId: 'applications/propId',
    }),
  },
  async created() {
    if (this.displayBasic) {
      // TODO: update with V2 API URL
      const result = {}; /*await _get(
        SERVER_URL_4 + "get_buying_property_details?id=" + this.propId
      );*/
      if (result && result.data && result.data.result && result.data.result.length) {
        this.formData.buying_property.depositAmount = result.data.result[0].depositAmount
          ? result.data.result[0].depositAmount
          : 0;
        this.formData.buying_property.purchasePrice = result.data.result[0].purchasePrice
          ? result.data.result[0].purchasePrice
          : 0;
        this.formData.buying_property.purchase_date = result.data.result[0].settlementDate
          ? new Date(result.data.result[0].settlementDate)
          : 0;
        this.formData.buying_property.depositPaidType = result.data.result[0].depositPaidType
          ? result.data.result[0].depositPaidType
          : 0;
        this.type = this.tasktypes.find((obj) => obj.value == result.data.result[0].type);
        this.formData.buying_property.type = this.type?.value;
        this.typeKey += 1;
        this.dateKey += 1;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
:deep(label) {
  color: $secondary-color !important;
}
.title {
  font-weight: bold;
  color: $black-color;
  font-size: 2.1rem;
}
.p-float-label {
  font-family: HK Grotesk !important;
}
.disabled {
  color: $light-text-color !important;
}
.radio-label {
  margin-left: 0.4rem;
  margin-bottom: 0;
}

form {
  gap: 1rem;
}
.modal-content {
  border: none;

  .icon-wrapper {
    .icon {
      font-size: 2rem;
    }
  }

  form {
    margin-top: 1rem;

    .input-wrapper {
      display: block;
      // font-weight: bold;

      &:not(:first-child) {
        margin-top: 1.5rem;
      }

      .heading {
        margin: 0;
        font-weight: bold;
        font-size: 1.1rem;
      }

      .text {
        margin-top: 0.5rem;
        font-size: 0.9rem;
        margin-bottom: 0;
      }

      .field {
        margin: 0;
        margin-top: 0.5rem;

        label {
          margin-bottom: 0;
          font-size: 1rem;
        }
      }
    }
  }
}
.modal-foodter {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;

  .close {
    margin: 0;
    cursor: pointer;
  }
}
input[type='radio'] {
  -webkit-appearance: none;
  width: 21px;
  height: 21px;
  border: 1px solid rgba(2, 0, 56, 0.3);
  border-radius: 50%;
  outline: none;
}
input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: $secondary-color;
}
</style>
