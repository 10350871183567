<template>
  <div>
    <div class="justify-content-between mt-5 mr-0" :class="isMD ? '' : 'flex'">
      <!--
      <Card class="col">
        <template #title>
          <div class="text-content module-sub-title">Servicing calculator</div>
        </template>
        <template #content>
          <div><img src="@/assets/calculator.svg" /></div>
          <Button
            class="mt-4"
            label="Calculate now"
            type="primary"
            iconPos="right"
            icon="pi pi-chevron-right"
            @click="goToCalculator"
          />
        </template>
      </Card>
      -->
      <Card class="col" :class="isMD ? 'mt-3' : 'ml-3'">
        <template #title>
          <div class="module-sub-title">Submit-Loan application</div>
        </template>
        <template #content>
          <div class="text-color-primary">
            <SvgIcon name="loan" size="4rem" />
          </div>
          <Button
            label="Submit application"
            class="mt-4"
            type="primary"
            iconPos="right"
            icon="pi pi-chevron-right"
            @click="goToLoanApp"
          />
        </template>
      </Card>
      <!-- <Card class="col" :class="isMD ? 'mt-3' : 'ml-3'">
        <template #title>
          <div class="text-content module-sub-title">Refer a client</div>
        </template>
        <template #content>
          <div><img src="@/assets/refer-client.svg" /></div>
          <br />
          <Button
            class="mt-0"
            label="Refer a client now"
            type="primary"
            iconPos="right"
            icon="pi pi-chevron-right"
            @click="goToClient"
          />
        </template>
      </Card> -->
    </div>
  </div>
</template>

<script>
import { LOAN_APPLICATION_URL } from '@/constants.api';
import { mapGetters } from 'vuex';
import { useCookies } from 'vue3-cookies';
import Card from 'primevue/card';
import ScreenSize from '@/mixins/screensize.mixin';
import SvgIcon from '../../../components/common/SvgIcon.vue';

export default {
  name: 'index',
  mixins: [ScreenSize],
  components: {
    Card,
    SvgIcon,
  },
  setup() {
    const { cookies } = useCookies();
    return cookies;
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  methods: {
    goToCalculator() {
      this.$router.replace('/dashboard/servicing-calculator');
    },
    goToClient() {
      this.$emit('refer');
    },
    goToLoanApp() {
      window.open(`${LOAN_APPLICATION_URL}`, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  padding: unset !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
  text-align: center !important;
  height: 60px;
}
:deep(.p-card-content) {
  font-size: 1rem !important;
  text-align: center !important;
}
.col {
  padding: unset;
}
img {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.text-content {
  white-space: nowrap;
}
:deep(.p-button-label) {
  white-space: nowrap !important;
}
</style>
