<template>
  <div class="content-deposti-wrapper">
    <h1 class="module-title loan-progress-title">Agreement</h1>
    <div>
      <p>
        Thanks for expressing your interest to become accredited as an authorised introducer of Bridgit! If we approve
        your application for accreditation, you will be authorised to introduce Bridgit bridging loans and such other
        products as we notify you from time to time.
      </p>
      <p>
        If you lodge any applications with us, you will owe the same obligations to us as you owe to your aggregator in
        relation to compliance, quality of information, and other obligations as set out in our agreement with your
        aggregator. By lodging an application with us, you agree to the terms of the aggregator agreement and to be
        bound by that agreement as if you were a party to it. If you require further information, or would like a copy
        of that agreement, ask your aggregator.
      </p>
      <p class="pt-4 label pm-txt-color hk-grotest-medium">
        I agree to the terms of the Aggregator Agreement and to be bound by that agreement as if I was a party to it.
      </p>
      <form action="" class="flex alignt-items-center">
        <div class="radio-input flex align-items-center">
          <SingleCheckbox :value="information" @getInputValue="(e) => (information = e)" id="Yes, I agree" />
          <label class="radio-label hk-grotest-medium pb-1" for="Yes, I agree">Yes, I agree </label>
        </div>
      </form>
    </div>
    <div v-if="showError" class="label notify-content">
      <img src="@/assets/alert-triangle.svg" />
      <p class="ml-2 notify-text">Please check the box above to proceed with your application.</p>
    </div>

    <div class="flex align-items-center">
      <Button label="Submit" type="primary" @click="goToNext" :disabled="isSubmitting" />
      <div class="back ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>

    <div class="spinner-container" v-if="isSubmitting">
      <span>Generating Broker..</span><Spinner class="flex align-items-center" />
    </div>
  </div>
</template>

<script>
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _patch } from '@/services/crudService';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      information: null,
      showError: false,
      showTerms: false,
      showPrivacy: false,
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions([
      'updateSubProgressList',
      'updateApplicantProgress',
      'updateSelectedSubProgressIndex',
      'updateSelectedSubProgressIndex',
      'updateSelectedApplicantPrgressIndex',
      'setLogout',
      'setLogin',
    ]),
    ...mapActions({
      setFormData: 'brokerSignup/setFormData',
      setBrokerDeclaration: 'brokerSignup/setBrokerDeclaration',
      setBrokerAgreement: 'brokerSignup/setBrokerAgreement',
      registerBroker: 'brokerSignup/registerBroker',
    }),
    getFormData() {
      this.setBrokerDeclaration([
        {
          type: 'declaration',
          description: 'bankrupt',
          accepted: true,
        },
        {
          type: 'declaration',
          description: 'crime',
          accepted: true,
        },
        {
          type: 'declaration',
          description: 'lender',
          accepted: true,
        },
        {
          type: 'declaration',
          description: 'license',
          accepted: true,
        },
        {
          type: 'declaration',
          description: 'registration',
          accepted: true,
        },
        {
          type: 'declaration',
          description: 'indemnity',
          accepted: true,
        },
      ]);
      this.setBrokerAgreement([
        {
          type: 'agreement',
          accepted: this.information,
          description: 'information',
        },
        {
          type: 'agreement',
          accepted: true,
          description: 'personal',
        },
      ]);
    },
    async goToNext() {
      if (!this.information) {
        this.showError = true;
        return;
      }
      try {
        const payload = {
          aclNumber: this.brokerFormData.aclNumber,
          aclRegistrationName: this.brokerFormData.aclRegistrationName,
          licensingType: this.brokerFormData.licensingType,
        };
        await _patch(TECHLEND_BE_URL + API.REGISTER.COMPLETE_REGISTRATION, payload);
        this.isSubmitting = true;
        this.$router.push('/success');
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      let currentObj = this.subProgressList[4];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.updateSubProgressList(currentObj);
      this.updateSelectedSubProgressIndex(3);
      let prevObj = this.subProgressList[3];
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      prevObj[0].isCompleted = false;
      this.updateSubProgressList(prevObj);
      this.updateApplicantProgress(77);
      this.updateSelectedApplicantPrgressIndex(3);
      this.$router.push('/signup/licensing');
    },
  },
  computed: {
    ...mapGetters(['selectedSubProgressIndex', 'subProgressList']),
    ...mapGetters('brokerSignup', ['brokerFormData']),
  },
  created() {
    this.information = this.brokerFormData.agreement[0].accepted;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.label,
label {
  font-size: 1.125rem;
}

.radio-label {
  margin-bottom: 0;
}
.privacy-policy {
  color: $primary-color;
  text-decoration: underline;
}
form {
  gap: 1rem;
  margin-bottom: 1.5rem;

  input[type='checkbox'] {
    width: 1.125rem;
    height: 1.125rem;
  }
}
.notify-content {
  border: 1px solid;
  padding: 1rem;
  border-radius: 5px;
  color: red;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.notify-text {
  margin-bottom: 0rem;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
.notify-content {
  border: 1px solid;
  padding: 1rem;
  border-radius: 5px;
  color: red;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.notify-text {
  margin-bottom: 0rem;
}
:deep(.p-checkbox .p-checkbox-box .p-checkbox-icon) {
  color: $secondary-color;
}
:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 1px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0rem #ced4da !important;
  border-color: #ced4da !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover) {
  border-color: #ced4da !important;
}
.underline {
  text-decoration: underline;
  color: $primary-color;
}
.spinner-container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  span {
    font-weight: 800;
  }
  :deep(.p-progress-spinner) {
    margin: 0 !important;
  }
}
</style>
