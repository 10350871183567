<template>
  <div>
    <div class="flex justify-content-between">
      <div class="module-sub-title">Outgoing properties</div>
      <div>
        <span>
          <img v-if="count === 2" src="@/assets/minus.svg" @click="count = 1" />
          <img v-else src="@/assets/minus-inactive.svg" />
        </span>
        <span class="pl-3 pr-3">{{ count }}</span>
        <span>
          <img v-if="count === 1" src="@/assets/plus.svg" @click="count = 2" />
          <img v-else src="@/assets/plus-inactive.svg" />
        </span>
      </div>
    </div>
    <div class="flex">
      <div class="col">
        <img src="@/assets/prop-1.svg" class="mt-2 mb-2" />
        <div>Address</div>
        <AddressAutocompleteKleberNew
          placeholder="Type address or select state and postcode"
          @onChange="onAddressChange"
          :key="addKey1"
        />
        <div>State<span class="required-class">*</span></div>
        <Dropdown
          placeholder="-Select-"
          @change="getFormData1($event, 'state')"
          :value="formData.state"
          :items="statesList"
          width="100%"
          :key="state1Key"
          :inValid="v$.formData.state.$invalid && onSave"
        />
        <div class="mt-3">Postal code<span class="required-class">*</span></div>
        <Inputnumber
          class="mt-3"
          label="Postal code"
          placeholder="Property postal code"
          width="100%"
          @onInput="getFormData1($event ? Number($event) : 0, 'postCode')"
          :key="postal1Key"
          :value="formData.postCode"
          :inValid="v$.formData.postCode.$invalid && onSave"
          :maxDigit="3"
          :format="false"
        />
        <div class="mt-3">Estimated valuation<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Enter amount"
          width="100%"
          :format="true"
          :icon="dollar"
          type="number"
          @onInput="getFormData($event ? Number($event) : 0, 'estimatedPropertyPrice')"
          :inValid="v$.formData.estimatedPropertyPrice.$invalid && onSave"
          :value="formData.estimatedPropertyPrice"
        />
        <div>Has the property been sold?<span class="required-class">*</span></div>
        <Dropdown
          placeholder="-Select-"
          :items="propertyTo"
          width="100%"
          optionLabel="name"
          :inValid="v$.formData.propertyStatus.$invalid && onSave"
          class="mt-2"
          @change="getFormData($event.value, 'propertyStatus')"
        />
        <div class="mt-3">Current mortage<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Enter amount"
          width="100%"
          type="number"
          :format="true"
          :inValid="v$.formData.outstandingLoanAmount.$invalid && onSave"
          :icon="dollar"
          @onInput="getFormData($event ? Number($event) : 0, 'outstandingLoanAmount')"
          :value="formData.outstandingLoanAmount"
        />
        <div>Settlement date<span class="required-class">*</span></div>
        <DatePicker
          placeholder="DD/MM/YYYY"
          width="100%"
          @change="getFormData($event, 'settlementDate')"
          :yearRange="'2022:2050'"
          :inValid="v$.formData.settlementDate.$invalid && onSave"
          :minDate="new Date()"
        />
        <div class="mt-3">Deposit held in trust?<span class="required-class">*</span></div>
        <Dropdown
          width="100%"
          :items="depositTo"
          class="mt-2"
          optionLabel="name"
          @change="getFormData($event.value, 'depositHeldInTrust')"
          :inValid="v$.formData.depositHeldInTrust.$invalid && onSave"
          placeholder="-Select-"
        />
        <div class="mt-3">Deposit amount<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Enter amount"
          width="100%"
          :format="true"
          :icon="dollar"
          :inValid="v$.formData.depositAmountReceived.$invalid && onSave"
          type="number"
          @onInput="getFormData($event ? Number($event) : 0, 'depositAmountReceived')"
          :value="formData.depositAmountReceived"
        />
        <div>Property type<span class="required-class">*</span></div>
        <Dropdown
          :items="assignTo"
          width="100%"
          :inValid="v$.formData.propertyType.$invalid && onSave"
          class="mt-2"
          optionLabel="name"
          @change="getFormData($event.value, 'propertyType')"
          placeholder="-Select-"
        />
      </div>
      <div class="ml-2 col" v-if="count == 2">
        <img src="@/assets/prop-2.svg" class="mt-2 mb-2" />
        <div>Address<span class="required-class">*</span></div>
        <AddressAutocompleteKleberNew
          placeholder="Type address or select state and postcode"
          @onChange="onAddress2Change"
          :key="addKey2"
        />
        <div>State<span class="required-class">*</span></div>
        <Dropdown
          placeholder="-Select-"
          @change="getForm2Data($event, 'state')"
          :value="form2Data.state"
          :items="statesList"
          width="100%"
          :inValid="count == 2 && v$.form2Data.state.$invalid && onSave"
          :key="state2Key"
        />
        <div class="mt-3">Postal code<span class="required-class">*</span></div>
        <Inputnumber
          class="mt-3"
          label="Postal code"
          width="100%"
          placeholder="Property postal code"
          @onInput="getForm2Data($event ? Number($event) : 0, 'postCode')"
          :value="form2Data.postCode"
          :maxDigit="3"
          :inValid="count == 2 && v$.form2Data.postCode.$invalid && onSave"
          :format="false"
        />
        <div class="mt-3">Estimated valuation<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Enter amount"
          width="100%"
          :format="true"
          :icon="dollar"
          type="number"
          @onInput="getForm2Data($event ? Number($event) : 0, 'estimatedPropertyPrice')"
          :value="form2Data.estimatedPropertyPrice"
          :inValid="count == 2 && v$.form2Data.estimatedPropertyPrice.$invalid && onSave"
        />
        <div>Has the property been sold?<span class="required-class">*</span></div>
        <Dropdown
          placeholder="-Select-"
          :items="propertyTo"
          width="100%"
          optionLabel="name"
          class="mt-2"
          @change="getForm2Data($event.value, 'propertyStatus')"
          :inValid="count == 2 && v$.form2Data.propertyStatus.$invalid && onSave"
        />
        <div class="mt-3">Current mortage<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Enter amount"
          width="100%"
          :format="true"
          :icon="dollar"
          type="number"
          @onInput="getForm2Data($event ? Number($event) : 0, 'outstandingLoanAmount')"
          :value="form2Data.outstandingLoanAmount"
          :inValid="count == 2 && v$.form2Data.outstandingLoanAmount.$invalid && onSave"
        />
        <div>Settlement date<span class="required-class">*</span></div>
        <DatePicker
          placeholder="DD/MM/YYYY"
          width="100%"
          @change="getForm2Data($event, 'settlementDate')"
          :yearRange="'2022:2050'"
          :minDate="new Date()"
          :inValid="count == 2 && v$.form2Data.settlementDate.$invalid && onSave"
        />
        <div class="mt-3">Deposit held in trust?<span class="required-class">*</span></div>
        <Dropdown
          width="100%"
          :items="depositTo"
          class="mt-2"
          optionLabel="name"
          @change="getForm2Data($event.value, 'depositHeldInTrust')"
          placeholder="-Select-"
          :inValid="count == 2 && v$.form2Data.depositHeldInTrust.$invalid && onSave"
        />
        <div class="mt-3">Deposit amount<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Enter amount"
          width="100%"
          :format="true"
          :icon="dollar"
          type="number"
          @onInput="getForm2Data($event ? Number($event) : 0, 'depositAmountReceived')"
          :inValid="count == 2 && v$.form2Data.depositAmountReceived.$invalid && onSave"
          :value="form2Data.depositAmountReceived"
        />
        <div>Property type<span class="required-class">*</span></div>
        <Dropdown
          :items="assignTo"
          width="100%"
          class="mt-2"
          optionLabel="name"
          @change="getForm2Data($event.value, 'propertyType')"
          placeholder="Property type"
          :inValid="count == 2 && v$.form2Data.propertyType.$invalid && onSave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { STATES } from '@/constants';
import { integer, maxValue, minValue, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import AddressAutocompleteKleberNew from '@/components/common/AddressAutoCompleteKleberNew.vue';
import Dollar from '@/assets/dollar.svg';

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'OutgoingProperties',
  components: {
    AddressAutocompleteKleberNew,
  },
  validations() {
    if (this.count === 1) {
      return {
        formData: {
          state: { required },
          postCode: { required },
          estimatedPropertyPrice: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyStatus: { required },
          outstandingLoanAmount: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          settlementDate: { required },
          depositHeldInTrust: { required },
          depositAmountReceived: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyType: { required },
          // city: { required },
          // country: { required },
          // line1: { required },
        },
      };
    } else if (this.count === 2) {
      return {
        formData: {
          state: { required },
          postCode: { required },
          estimatedPropertyPrice: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyStatus: { required },
          outstandingLoanAmount: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          settlementDate: { required },
          depositHeldInTrust: { required },
          depositAmountReceived: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyType: { required },
          // city: { required },
          // country: { required },
          // line1: { required },
        },
        form2Data: {
          state: { required },
          postCode: { required },
          estimatedPropertyPrice: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyStatus: { required },
          outstandingLoanAmount: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          settlementDate: { required },
          depositHeldInTrust: { required },
          depositAmountReceived: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyType: { required },
          // city: { required },
          // country: { required },
          // line1: { required },
        },
      };
    }
  },
  data() {
    return {
      dollar: Dollar,
      count: 1,
      state: '',
      depositTo: [
        { name: 'Yes', value: true },
        { value: false, name: 'No' },
      ],
      propertyTo: [
        { name: 'Sold', value: 'sold' },
        { name: 'No', value: 'no' },
        { name: 'Listed for sale', value: 'list_for_sale' },
      ],
      assignTo: [
        { name: 'Residential', value: 'residential' },
        { name: 'Rural Residential', value: 'rural_residential' },
        { name: 'Off the plan residential', value: 'off_plan_residential' },
        { name: 'Commercial', value: 'commercial' },
        { name: 'Vacant Land', value: 'vacant_land' },
      ],
      state1Key: 30,
      postal1Key: 40,
      state2Key: 50,
      postal2Key: 60,
      postal1Disabled: false,
      postal2Disabled: false,
      state1Disabled: false,
      state2Disabled: false,
      formData: {
        state: '',
        postCode: '',
        estimatedPropertyPrice: 0,
        propertyStatus: '',
        outstandingLoanAmount: 0,
        settlementDate: '',
        depositHeldInTrust: '',
        depositAmountReceived: 0,
        propertyType: '',
        line1: '',
        line2: '',
        city: '',
        country: '',
      },
      form2Data: {
        state: '',
        postCode: '',
        estimatedPropertyPrice: 0,
        propertyStatus: '',
        outstandingLoanAmount: 0,
        settlementDate: '',
        depositHeldInTrust: '',
        depositAmountReceived: 0,
        propertyType: '',
        line1: '',
        line2: '',
        city: '',
        country: '',
      },
      statesList: STATES,
      addKey1: 30,
      addKey2: 40,
    };
  },
  computed: {
    ...mapGetters({
      onSave: 'dashboard/onSave',
    }),
  },
  methods: {
    ...mapActions({
      setOutgoingProperties: 'dashboard/setOutgoingProperties',
      fetchZoneByPostal: 'dashboard/fetchZoneByPostal',
      setOutCount: 'dashboard/setOutCount',
    }),
    async onAddressChange(address) {
      this.formData.line1 = address.line1 ? address.line1 : '';
      this.formData.line2 = address.line2 ? address.line2 : '';
      if (address.state) {
        const isState = this.statesList.find((obj) => obj == address.state);
        if (isState) {
          this.formData.state = address.state;
          this.state1Disabled = true;
        } else {
          this.formData.state = '';
          this.state1Disabled = false;
        }
      } else {
        this.formData.state = '';
        this.state1Disabled = false;
      }
      this.formData.city = address.city ? address.city : '';
      this.formData.country = address.country ? address.country : '';
      this.formData.postCode = address.postCode ? Number(address.postCode) : 0;
      if (address.postCode) {
        this.formData.postCode = Number(address.postCode);
        this.postal1Disabled = true;
      } else {
        this.postal1Disabled = false;
        this.formData.postCode = '';
      }
      this.state1Key += 1;
      this.postal1Key += 1;
      await this.getFormData(this.formData.postCode, 'postCode');
      await this.getFormData(this.formData.line1, 'line1');
      await this.getFormData(this.formData.line2, 'line2');
      await this.getFormData(this.formData.state, 'state');
      await this.getFormData(this.formData.city, 'city');
      await this.getFormData(this.formData.country, 'country');
    },
    async onAddress2Change(address) {
      this.form2Data.line1 = address.line1 ? address.line1 : '';
      this.form2Data.line2 = address.line2 ? address.line2 : '';
      this.form2Data.city = address.line1 ? address.city : '';
      if (address.state) {
        const isState = this.statesList.find((obj) => obj == address.state);
        if (isState) {
          this.form2Data.state = address.state;
          this.state2Disabled = true;
        } else {
          this.form2Data.state = '';
          this.state2Disabled = false;
        }
      } else {
        this.form2Data.state = '';
        this.state2Disabled = false;
      }
      this.form2Data.country = address.country ? address.country : '';
      if (address.postCode) {
        this.form2Data.postCode = Number(address.postCode);
        this.postal2Disabled = true;
      } else {
        this.postal2Disabled = true;
        this.form2Data.postCode = '';
      }
      this.state2Key += 1;
      this.postal2Key += 1;
      await this.getForm2Data(this.form2Data.line1, 'line1');
      await this.getForm2Data(this.form2Data.line2, 'line2');
      await this.getForm2Data(this.form2Data.state, 'state');
      await this.getForm2Data(this.form2Data.postCode, 'postCode');
      await this.getForm2Data(this.form2Data.city, 'city');
      await this.getForm2Data(this.form2Data.country, 'country');
    },
    async getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.setOutgoingProperties({
        key: fieldName,
        value: value,
        index: 0,
      });
      if (fieldName === 'postCode' && value > 100) {
        const result = await this.fetchZoneByPostal(value);
        for (const [key, value] of Object.entries(result)) {
          if (key) {
            this.setOutgoingProperties({
              key: key,
              value: value,
              index: 0,
            });
          }
        }
      }
      if (fieldName === 'estimatedPropertyPrice') {
        this.setOutgoingProperties({
          key: 'purchasePriceUsed',
          value: value,
          index: 0,
        });
        this.setOutgoingProperties({
          key: 'salePrice',
          value: value,
          index: 0,
        });
      }
      if (fieldName === 'propertyType') {
        this.setOutgoingProperties({
          key: 'propertyType',
          value: value,
          index: 0,
        });
      }
      if (fieldName === 'outstandingLoanAmount') {
        this.setOutgoingProperties({
          key: 'mortgageAgainst',
          value: value ? true : false,
          index: 0,
        });
      }
      this.$emit('validateOutgoing', this.v$.$invalid);
    },
    async getFormData1(value, fieldName) {
      this.formData.line1 = '';
      this.setOutgoingProperties({
        key: 'line1',
        value: '',
        index: 0,
      });
      this.formData.line2 = '';
      this.setOutgoingProperties({
        key: 'line2',
        value: '',
        index: 0,
      });
      this.formData.city = '';
      this.setOutgoingProperties({
        key: 'city',
        value: '',
        index: 0,
      });

      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.setOutgoingProperties({
        key: fieldName,
        value: value,
        index: 0,
      });
      if (fieldName === 'postCode' && value > 100) {
        const result = await this.fetchZoneByPostal(value);
        for (const [key, value] of Object.entries(result)) {
          if (key) {
            this.setOutgoingProperties({
              key: key,
              value: value,
              index: 0,
            });
          }
        }
      }
      this.addKey1 += 1;
      this.$emit('validateOutgoing', this.v$.$invalid);
    },
    async getForm2Data(value, fieldName) {
      this.form2Data.line1 = '';
      this.setOutgoingProperties({
        key: 'line1',
        value: '',
        index: 1,
      });
      this.form2Data.line2 = '';
      this.setOutgoingProperties({
        key: 'line2',
        value: '',
        index: 1,
      });
      this.form2Data.city = '';
      this.setOutgoingProperties({
        key: 'city',
        value: '',
        index: 1,
      });

      this.form2Data[fieldName] = value;
      this.form2Data = { ...this.form2Data };
      this.setOutgoingProperties({
        key: fieldName,
        value: value,
        index: 1,
      });
      if (fieldName === 'postCode' && value > 100) {
        const result = await this.fetchZoneByPostal(value);
        for (const [key, value] of Object.entries(result)) {
          if (key) {
            this.setOutgoingProperties({
              key: key,
              value: value,
              index: 1,
            });
          }
        }
      }
      if (fieldName === 'estimatedPropertyPrice') {
        this.setOutgoingProperties({
          key: 'purchasePriceUsed',
          value: value,
          index: 1,
        });
        this.setOutgoingProperties({
          key: 'salePrice',
          value: value,
          index: 1,
        });
      }
      if (fieldName === 'propertyType') {
        this.setOutgoingProperties({
          key: 'propertyType',
          value: value,
          index: 1,
        });
      }
      if (fieldName === 'outstandingLoanAmount') {
        this.setOutgoingProperties({
          key: 'mortgageAgainst',
          value: value ? true : false,
          index: 1,
        });
      }
      this.$emit('validateOutgoing', this.v$.$invalid);
    },
    async getForm2Data2(value, fieldName) {
      this.form2Data[fieldName] = value;
      this.form2Data = { ...this.form2Data };
      this.setOutgoingProperties({
        key: fieldName,
        value: value,
        index: 1,
      });
      if (fieldName === 'postCode' && value > 100) {
        const result = await this.fetchZoneByPostal(value);
        for (const [key, value] of Object.entries(result)) {
          if (key) {
            this.setOutgoingProperties({
              key: key,
              value: value,
              index: 1,
            });
          }
        }
      }
      this.addKey2 += 1;
      this.$emit('validateOutgoing', this.v$.$invalid);
    },
  },
  watch: {
    count() {
      this.setOutCount(this.count);
      this.$emit('validateOutgoing', this.v$.$invalid);
    },
  },
  created() {
    this.setOutCount(this.count);
    // this.$emit("validateOutgoing", this.v$.$invalid);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
img {
  cursor: pointer;
}
</style>
