<template>
  <div class="timeline-container pl-4 pr-4">
    <h3 class="title pm-txt-color">
      {{ profileDetails.firstName }} {{ profileDetails.middleName }}
      {{ profileDetails.lastName }}
      <span v-if="profileDetails.firstName">-</span> Current application progress
    </h3>
    <div class="progress-details">
      <div :class="isMD ? '' : 'flex'">
        <div class="col">
          <TimeLine />
        </div>
        <div class="col"><LoanDetails /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoanDetails from './LoanDetails.vue';
import Screensize from '@/mixins/screensize.mixin';
import TimeLine from './TimeLine.vue';

export default {
  components: { TimeLine, LoanDetails },
  mixins: [Screensize],
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.timeline-container {
  margin-top: 2rem;

  .title {
    font-size: bold;
    color: $primary-text-color;
    font-size: 1.25rem;
  }

  .progress-details {
    border-bottom: 1px solid $light-text-color;
    padding-bottom: 2rem;
  }
}
</style>
