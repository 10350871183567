<template>
  <div>
    <label class="p-float-label label hk-grotest-medium"
      >{{ label }}<span v-if="required" class="required">*</span></label
    >
    <Dropdown
      v-model="selectedValue"
      :options="items"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :field="optionValue"
      :placeholder="placeholder"
      :style="{ width: `${width}`, height: `${height}` }"
      @change="onSelectChange"
      :class="inValid && 'p-invalid'"
      :disabled="disable"
    />
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';

export default {
  name: 'Select',
  components: {
    Dropdown,
  },
  props: [
    'width',
    'height',
    'items',
    'value',
    'placeholder',
    'optionLabel',
    'optionValue',
    'errorMessages',
    'required',
    'label',
    'disable',
    'inValid',
  ],
  data() {
    return {
      selectedValue: '',
    };
  },
  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },
  methods: {
    onSelectChange() {
      this.$emit('change', this.selectedValue);
    },
  },
  created() {
    this.selectedValue = this.value ? this.value : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

:deep(.p-invalid) {
  border: 1px solid $pink-color !important;
}

@import '@/styles/global.scss';
.p-dropdown:not(.p-disabled):hover {
  border: 1px solid rgba(2, 0, 56, 0.3);
}
.p-dropdown:not(.p-disabled).p-focus {
  // border: 1px solid rgba(100, 97, 170, 0.3);
  // box-shadow: none !important;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
.p-dropdown {
  border: 1px solid rgba(2, 0, 56, 0.15);
}
.label {
  color: $secondary-color;
}
:deep(.p-inputtext) {
  font-family: HK-medium !important;
  width: 100px;
}
</style>
