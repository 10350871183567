<template>
  <div>
    <label :class="labelClass" class="p-float-label label hk-grotest-medium"
      >{{ label }}<span v-if="required" class="required">*</span></label
    >
    <div :class="inValid && 'invalid-field'" class="datepicker-layout flex align-items-center">
      <i class="pi pi-calendar ml-2" />
      <Calendar
        v-model="editValue"
        :numberOfMonths="numberOfMonths"
        dateFormat="dd/mm/yy"
        :placeholder="placeholder"
        :manualInput="false"
        :monthNavigator="true"
        :yearNavigator="true"
        :minDate="minDate"
        :maxDate="maxDate"
        :yearRange="yearRange ? yearRange : '1922:2022'"
        @date-select="onChange"
      >
      </Calendar>
    </div>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar';
import moment from 'moment';

export default {
  name: 'Datefilter',
  components: {
    Calendar,
  },
  props: ['numberOfMonths', 'placeholder', 'required', 'label', 'value', 'minDate', 'maxDate', 'yearRange', 'inValid'],
  data() {
    return {
      editValue: '',
      displayValue: '',
      isOpen: true,
      menu: false,
    };
  },
  methods: {
    onChange(value) {
      let dateValue = moment(value).format('YYYY-MM-DD');
      this.$emit('change', new Date(dateValue).toISOString());
    },
    onClear() {
      this.editValue = '';
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss">
@import '@/styles/global.scss';
.invalid-field {
  border: 1px solid $pink-color !important;
}
.p-datepicker table td {
  padding: 0 !important;
}
.p-datepicker table td > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 0 !important;
  font-size: 14px !important;
}
.p-datepicker table th > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}
.p-datepicker table td > span.p-highlight {
  background: #b8e2b0 !important;
}
.p-datepicker .p-datepicker-header {
  border-bottom: unset !important;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: unset !important;
}
.p-datepicker {
  padding: 1rem 2rem !important;
}
.datepicker-layout {
  border: 1px solid rgba(100, 97, 170, 0.3);
  border-radius: 4px !important;
  .p-inputtext {
    border: unset !important;
    margin-left: 10px;
    border-left: 2px solid rgba(100, 97, 170, 0.3) !important;
    border-radius: unset !important;
    font-family: $font-family !important;
    width: 100% !important;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
    border-color: rgba(2, 0, 56, 0.15);
  }
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  border: unset !important;
  background: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  border: unset !important;
  background: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  box-shadow: unset !important;
}
</style>
