<template>
  <div>
    <!-- <h1 class="pm-txt-color module-title font-size-2_25 sec-title">Settings</h1> -->
    <Card class="mt-4">
      <template #content>
        <div class="flex p-3 pb-0 divider" :class="isMD ? 'font-size-14' : ''">
          <div
            class="pb-3 h-100 ml-2 cursor-pointer"
            @click="selected = 'account'"
            :class="selected === 'account' ? 'selected-class' : ''"
          >
            Account overview
          </div>
          <div
            class="h-100 pb-3 cursor-pointer"
            @click="selected = 'payment'"
            :class="{
              'ml-6': !isMD,
              'selected-class': selected === 'payment',
              'ml-3': isMD,
            }"
          >
            Payment details
          </div>
          <div
            class="h-100 pb-3 cursor-pointer"
            @click="selected = 'registration'"
            :class="{
              'ml-6': !isMD,
              'selected-class': selected === 'registration',
              'ml-3': isMD,
            }"
          >
            Registration
          </div>
        </div>
        <Account v-if="selected === 'account'" class="p-4 pt-3" />
        <Payment v-if="selected === 'payment'" class="pt-3" />
        <Registration v-if="selected === 'registration'" class="pt-3" />
      </template>
    </Card>
    <div v-if="selected === 'account'">
      <Notification class="mt-4" />
    </div>
  </div>
</template>

<script>
import Account from './Account.vue';
import Card from 'primevue/card';
import Notification from './Notification.vue';
import Payment from './Payment.vue';
import Registration from './Registration.vue';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  name: 'index',
  mixins: [ScreenSize],
  components: {
    Account,
    Notification,
    Card,
    Payment,
    Registration,
  },
  data() {
    return {
      selected: 'account',
    };
  },
  created() {
    if (this.$route.query && this.$route.query.payment) {
      this.selected = 'payment';
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
:deep(.p-card-body) {
  padding: unset !important;
}
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  font-size: 1.4rem !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
.title {
  color: rgba(2, 0, 56, 0.3);
}
.flex-column {
  flex-direction: column;
}
:deep(.p-card-content) {
  font-weight: 500;
  color: rgba(2, 0, 56, 0.3);
}
.selected-class {
  color: $primary-color;
  border-bottom: 3px solid $primary-color;
}
.divider {
  border-bottom: 2px solid rgba(2, 0, 56, 0.15);
}
.h-100 {
  height: 100%;
}
.font-size-14 {
  font-size: 14px;
}
</style>
