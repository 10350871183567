<template>
  <div>
    <label class="p-float-label hk-grotest-medium">{{ label }}<span v-if="required" class="required">*</span></label>
    <div class="input-wrapper">
      <Textarea
        v-model="editValue"
        class="input-field md:col-6"
        :placeholder="placeholder"
        :style="{
          height: `${height}`,
          width: `${width}`,
          border: `1px solid ${color} !important`,
          color: `${color}`,
        }"
        @input="onInput"
      />
    </div>
    <label v-if="errorMessages" class="error-text required bold-400">{{ errorMessages }}</label>
  </div>
</template>

<script>
import Textarea from 'primevue/textarea';
export default {
  name: 'TextArea',
  props: ['placeholder', 'label', 'height', 'width', 'border', 'color', 'value', 'errorMessages', 'required'],
  data() {
    return {
      editValue: '',
    };
  },
  components: { Textarea },
  methods: {
    onInput({ target }) {
      this.$emit('onInput', target.value);
    },
  },
  watch: {
    editValue() {
      this.$emit('change', this.editValue);
    },
    value() {
      this.editValue = this.value;
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss">
.p-float-label {
  margin-bottom: 1rem;
}

.input-wrapper {
  display: flex;
  align-items: center;
}
.input-field {
  border-color: unset;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset !important;
  border-radius: unset !important;
}

.p-inputtext {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
}
.p-inputtext:enabled:focus {
  // border-color: rgba(2, 0, 56, 0.15);
  // border: 1px solid rgba(100, 97, 170, 0.3);
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
.p-inputtext:enabled:hover {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
}
.required {
  color: red;
}
.error-text {
  font-size: 14px;
}
</style>
