<template>
  <div>
    <h1 class="module-title">Email</h1>
    <TextField
      width="100%"
      class="mt-3"
      label="Email address"
      @onInput="getFormData($event, 'email')"
      :value="formData.email"
      placeholder="Your email address"
    />
    <TextField
      width="100%"
      class="mt-3"
      label="Please confirm your email address"
      @onInput="getFormData($event, 'confirmEmail')"
      :value="formData.confirmEmail"
      placeholder="Re-enter your email address"
    />

    <div class="flex align-items-center">
      <Button
        class="mt-4"
        icon="pi pi-chevron-right"
        iconPos="right"
        label="Next"
        type="primary"
        :disabled="isSubmitDisabled()"
        @click="goToNext"
      />
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { email, required, sameAs } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'Email',
  components: {},
  data() {
    return {
      formData: {
        email: '',
        confirmEmail: '',
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        email: { required, email },
        confirmEmail: { email, required, sameAs: sameAs(this.formData.email) },
      },
    };
  },
  methods: {
    ...mapActions(['referralUpdateSubProgressList', 'referralUpdateSelectedSubProgressIndex']),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goBack() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[1].isActive = true;
      currentObj[1].color = '#54B7F9';
      currentObj[1].isCompleted = false;
      currentObj[2].isActive = false;
      currentObj[2].isCompleted = false;
      currentObj[2].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.$router.push('/referral-signup/address');
    },
    goToNext() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[3].isActive = true;
      currentObj[3].color = '#54B7F9';
      currentObj[2].isActive = false;
      currentObj[2].isCompleted = true;
      currentObj[2].color = 'rgba(255, 255, 255, 0.3)';
      currentObj[1].isActive = false;
      currentObj[1].color = 'rgba(255, 255, 255, 0.3)';
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.setReferralFormData(this.formData);
      this.$router.push('/referral-signup/mobile');
    },
  },
  computed: {
    ...mapGetters(['referralSubProgressList', 'referralSelectedSubProgressIndex']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.formData.email = this.referralFormData.email ? this.referralFormData.email : '';
    this.formData.confirmEmail = this.referralFormData.email ? this.referralFormData.email : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 350px;
  color: $secondary-color;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
</style>
