<template>
  <iframe :src="knowledgeHubLink" frameborder="0"></iframe>
</template>

<script>
export default {
  computed: {
    knowledgeHubLink() {
      return process.env.VUE_APP_CUSTOM_THEME_KNOWLEDGE_HUB_LINK ?? 'https://www.bridgit.com.au/knowledge-hub';
    },
  },
};
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
}
</style>
