<template>
  <Card class="notification-wrapper">
    <template #content>
      <div>
        <div class="title-text">Licensing/registration details</div>
        <div class="justify-content-between mt-4" :class="isMD ? '' : 'flex'">
          <Dropdown
            class="col disable-input capitalize"
            :width="isMD ? '100%' : '96%'"
            :class="isMD ? '' : 'col-4'"
            placeholder="Licensing/registration
					detail"
            label="Licensing/registration detail"
            :disable="true"
            :value="licensingValue"
            :items="[licensingTypeOptions] || [profileDetails.licensingType]"
          />
          <TextField
            class="col disable-input capitalize"
            :width="isMD ? '100%' : '96%'"
            :class="isMD ? 'mt-3' : 'col-4'"
            placeholder="Your ACL registered name"
            label="ACL registered name"
            :disable="true"
            :value="profileDetails.aclRegistrationName"
          />
          <TextField
            class="col disable-input"
            :width="isMD ? '100%' : '96%'"
            :class="isMD ? 'mt-3' : 'col-4'"
            placeholder="Your ACL number"
            label="ACL number"
            :disable="true"
            :value="profileDetails.aclNumber"
          />
        </div>
        <div class="justify-content-between mt-4" :class="isMD ? '' : 'flex'">
          <TextField
            class="col disable-input capitalize"
            :class="isMD ? '' : 'col-6'"
            :width="isMD ? '100%' : '96%'"
            placeholder="Your full name"
            label="Name"
            :disable="true"
            :value="`${profileDetails.firstName} ${profileDetails.middleName || ''} ${profileDetails.lastName}`"
          />
          <TextField
            class="col disable-input capitalize"
            :class="isMD ? 'mt-3' : 'col-6'"
            :width="isMD ? '100%' : '98%'"
            placeholder="Your CR number"
            label="CR number"
            :disable="true"
            :value="profileDetails.crNumber"
          />
        </div>
      </div>
      <div>
        <div class="divider mt-4 mb-4"></div>
        <div class="title-text">Contracting party details</div>
        <div class="justify-content-between mt-4" :class="isMD ? '' : 'flex'">
          <TextField
            class="col disable-input"
            :width="isMD ? '100%' : '96%'"
            :class="isMD ? '' : 'col-6'"
            placeholder="Aggregation group"
            label="Aggregation group"
            :disable="true"
            :value="profileDetails.brokerGroup ? profileDetails.brokerGroup.aggregationGroup : ''"
          />
          <TextField
            class="col disable-input"
            :width="isMD ? '100%' : '98%'"
            :class="isMD ? 'mt-3' : 'col-6'"
            placeholder="Your group name"
            label="Business/broker group name"
            :disable="true"
            :value="profileDetails.brokerGroup ? profileDetails.brokerGroup.name : ''"
          />
        </div>
        <div class="justify-content-between mt-4" :class="isMD ? '' : 'flex'">
          <TextField
            class="col disable-input"
            :width="isMD ? '100%' : '96%'"
            :class="isMD ? '' : 'col-6'"
            placeholder="Number of brokers in group"
            label="Number of brokers in group"
            :disable="true"
            :value="profileDetails.brokerGroup ? profileDetails.brokerGroup.numberOfBrokers : 1"
          />
          <TextField
            class="col disable-input"
            :width="isMD ? '100%' : '98%'"
            :class="isMD ? 'mt-3' : 'col-6'"
            placeholder="Eg. solicitor"
            label="Primary business activity"
            :disable="true"
            :value="profileDetails.brokerGroup ? profileDetails.brokerGroup.primaryBusinessActivity : ''"
          />
        </div>
        <div class="justify-content-between mt-4" :class="isMD ? '' : 'flex'">
          <TextField
            class="col disable-input"
            :width="isMD ? '100%' : '96%'"
            :class="isMD ? '' : 'col-6'"
            placeholder="Your business phone number"
            label="Business phone number"
            :disable="true"
            :value="profileDetails.brokerGroup ? profileDetails.brokerGroup.businessMobileNumber : ''"
          />
          <InputWithIcon
            class="col disable-input address"
            :width="isMD ? '100%' : '98%'"
            :class="isMD ? 'mt-3' : 'col-6'"
            placeholder="Your business address"
            label="Business address"
            :disable="true"
            :icon="Location"
            :value="`${profileDetails.businessLine1 || ''} ${profileDetails.businessLine2 || ''} ${
              profileDetails.businessCity || ''
            } ${profileDetails.businessState || ''} ${profileDetails.businessCountry || ''}`"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex';
import Card from 'primevue/card';
import ScreenSize from '@/mixins/screensize.mixin';
import location from '@/assets/location.svg';

export default {
  name: 'Registration',
  mixins: [ScreenSize],
  components: {
    Card,
  },
  data() {
    return {
      Location: location,
      licensingTypeOptions: [],
      licensingValue: '',
    };
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  created() {
    this.licensingTypeOptions = this.profileDetails ? this.profileDetails.licensingType : ''.split('_').join(' ');
    this.licensingValue = this.licensingTypeOptions;
  },
};
if (window.location.toString().indexOf('/signup/contract') > -1) {
  window.open('https://www.bridgit.com.au/accreditation-sign-up', '_self');
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.notification-wrapper {
  padding: 1.875rem;
}
.title-text {
  color: rgba(2, 0, 56, 0.3);
  font-size: 1rem !important;
}

:deep(.p-card-body) {
  padding: 0 !important;
}

.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $secondary-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
}
.col {
  padding: unset;
}
.divider {
  border-bottom: 2px solid rgba(2, 0, 56, 0.15);
}
.address {
  :deep(.p-inputtext) {
    border-left: unset !important;
  }
  :deep(.icons) {
    border-right: unset !important;
  }
}
</style>
