import Accreditation from '@/views/broker/JotForms/Accreditation/index.vue';
import Quote from '@/views/broker/JotForms/Quote/index.vue';
import ScheduleCall from '@/views/broker/JotForms/ScheduleCall/index.vue';

export const JotFormRoutes = [
  {
    path: '/accreditation',
    component: Accreditation,
    name: 'Acceditation',
  },
  {
    path: '/quote',
    component: Quote,
    name: 'Quote',
  },
  {
    path: '/schedule-a-call',
    component: ScheduleCall,
    name: 'ScheduleCall',
  },
];

export default JotFormRoutes;
