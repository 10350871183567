<template>
  <Button
    :label="label"
    :class="ButtonClass()"
    :style="{
      height: `${height}`,
      width: `${width}`,
      border: `1px solid ${color} !important`,
      color: `${color}`,
      background: `${bgcolor}`,
    }"
    :icon="icon"
    :iconPos="iconPos"
  />
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'Buttons',
  props: [
    'label',
    'icon',
    'button_class',
    'type',
    'bgcolor', // background color of button
    'color', // color of text
    'height',
    'width',
    'iconPos', // position of icon
  ],
  components: {
    Button,
  },
  methods: {
    ButtonClass() {
      const classes = [];
      classes.push(this.button_class);
      if (this.type === 'primary') {
        classes.push('primary-btn');
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.p-button {
  font-weight: 800;
  box-shadow: unset !important;
}
// .p-button:enabled:active {
//   background: $primary-color !important;
//   border: unset !important;
// }
:deep(.p-button:enabled:hover) {
  background: $primary-color;
  color: #0c1744;
  border-color: $primary-color;
  background-color: $primary-color;
}
.p-button:enabled:hover {
  background: $primary-color;
  color: #0c1744;
  border-color: $primary-color;
  background-color: $primary-color;
}

.primary-btn {
  color: #0c1744;
  background: $primary-color;
  border: unset !important;
  border-radius: 4px;

  &:enabled:hover {
    background-color: darken($primary-color, 5%) !important;
  }
  &:enabled:active {
    background-color: darken($primary-color, 10%) !important;
  }
}
</style>
