<template>
  <div class="h-100">
    <Card :class="isMD ? 'pl-0 pr-0' : 'pl-5 pr-5'">
      <template #content>
        <div class="mb-3">Application Progress</div>
        <ProgressBar :value="referralProgressBarPercentage" />
        <div class="flex justify-content-between mt-4">
          <div
            class="image-icon flex align-items-center justify-content-center"
            :class="referralApplicationProgress[0].isActive ? 'image-icon-active ' : ''"
            v-tooltip.bottom="referralApplicationProgress[0].title"
          >
            <img
              v-if="referralApplicationProgress[0].isActive"
              src="@/assets/personal-active.svg"
              class="cursor-pointer"
              @click="goToContract(0, referralApplicationProgress[0])"
            />
            <img
              v-else
              src="@/assets/personal.svg"
              class="cursor-pointer"
              @click="goToContract(0, referralApplicationProgress[0])"
            />
          </div>
          <div
            class="image-icon flex align-items-center justify-content-center"
            :class="referralApplicationProgress[1].isActive ? 'image-icon-active ' : ''"
            v-tooltip.bottom="referralApplicationProgress[1].title"
          >
            <img
              v-if="referralApplicationProgress[1].isActive"
              src="@/assets/license-active.svg"
              class="cursor-pointer"
              @click="goToContract(1, referralApplicationProgress[1])"
            />
            <img
              v-else
              src="@/assets/license.svg"
              class="cursor-pointer"
              @click="goToContract(1, referralApplicationProgress[1])"
            />
          </div>
          <div
            class="image-icon flex align-items-center justify-content-center"
            :class="referralApplicationProgress[2].isActive ? 'image-icon-active ' : ''"
            v-tooltip.bottom="referralApplicationProgress[2].title"
          >
            <img
              v-if="referralApplicationProgress[2].isActive"
              src="@/assets/contract-active.svg"
              class="cursor-pointer"
              @click="goToContract(2, referralApplicationProgress[2])"
            />
            <img
              v-else
              src="@/assets/contract.svg"
              class="cursor-pointer"
              @click="goToContract(2, referralApplicationProgress[2])"
            />
          </div>
          <div
            class="image-icon flex align-items-center justify-content-center"
            :class="referralApplicationProgress[3].isActive ? 'image-icon-active ' : ''"
            v-tooltip.bottom="referralApplicationProgress[3].title"
          >
            <img
              v-if="referralApplicationProgress[3].isActive"
              src="@/assets/proof-active.svg"
              class="cursor-pointer"
              @click="goToContract(3, referralApplicationProgress[3])"
            />
            <img
              v-else
              src="@/assets/proof.svg"
              class="cursor-pointer"
              @click="goToContract(3, referralApplicationProgress[3])"
            />
          </div>
          <div
            class="image-icon flex align-items-center justify-content-center"
            :class="referralApplicationProgress[4].isActive ? 'image-icon-active ' : ''"
            v-tooltip.bottom="referralApplicationProgress[4].title"
          >
            <img
              v-if="referralApplicationProgress[4].isActive"
              src="@/assets/verification-active.svg"
              class="cursor-pointer"
              @click="goToContract(4, referralApplicationProgress[4])"
            />
            <img
              v-else
              src="@/assets/verification.svg"
              class="cursor-pointer"
              @click="goToContract(4, referralApplicationProgress[4])"
            />
          </div>
          <div
            class="image-icon flex align-items-center justify-content-center"
            :class="referralApplicationProgress[5].isActive ? 'image-icon-active ' : ''"
            v-tooltip.bottom="referralApplicationProgress[5].title"
          >
            <img
              src="@/assets/verification.svg"
              class="cursor-pointer"
              @click="goToContract(5, referralApplicationProgress[5])"
            />
          </div>
        </div>
        <div class="divider-line mt-4"></div>
        <div class="progress-title mt-2">
          {{ referralApplicationProgress[referralSelectedApplicantPrgressIndex].title }}
        </div>
        <div class="divider-line mt-2 mb-3"></div>
        <Timeline :value="referralSubProgressList[referralSelectedSubProgressIndex]" align="left" :key="timelineKey">
          <template #marker="slotProps">
            <div
              class="p-timeline-event-marker flex justify-content-center align-items-center"
              :class="slotProps.item.isCompleted ? 'white-background' : ''"
              :style="
                slotProps.item.isActive ? 'border: 3px solid #2cdd80' : 'border: 3px solid rgba(255, 255, 255, 0.3)'
              "
            >
              <i class="pi pi-check" style="font-size: 14px; color: #020038" />
            </div>
          </template>
          <template #content="slotProps">
            <div class="status" :class="slotProps.item.isActive || slotProps.item.isCompleted ? 'status-active' : ''">
              {{ slotProps.item.title }}
            </div>
          </template>
          <template #connector>
            <div class="p-timeline-event-connector" style="width: 2px; height: 2px; color: blue"></div>
          </template>
        </Timeline>
      </template>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Card from 'primevue/card';
import ProgressBar from 'primevue/progressbar';
import Screensize from '@/mixins/screensize.mixin';
import Timeline from 'primevue/timeline';

export default {
  name: 'ProgressPart',
  mixins: [Screensize],
  data() {
    return {
      isActive: true,
      timelineKey: 0,
      events: [
        {
          status: 'Name',
          color: '#54B7F9',
          isActive: true,
        },
        {
          status: 'Address',
          color: 'rgba(255, 255, 255, 0.3)',
          isActive: false,
        },
        {
          status: 'Email',
          color: 'rgba(255, 255, 255, 0.3)',
          isActive: false,
        },
        {
          status: 'Payment details',
          color: 'rgba(255, 255, 255, 0.3)',
          isActive: false,
        },
      ],
    };
  },
  components: {
    Card,
    ProgressBar,
    Timeline,
  },
  computed: {
    ...mapGetters([
      'referralApplicationProgress',
      'referralProgressBarPercentage',
      'referralSubProgressList',
      'referralSelectedSubProgressIndex',
      'referralSelectedApplicantPrgressIndex',
    ]),
  },
  watch: {
    subProgressList() {
      this.timelineKey += 1;
    },
    selectedSubProgressIndex() {
      this.selectedSubProgressIndex += 1;
    },
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'referralUpdateApplicantProgress',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedApplicantPrgressIndex',
      'referralUpdateIsActiveValue',
      'referralUpdateSelectedApplicantPrgressIndexArray',
      'referralUpdateApplicantProgress',
    ]),
    goToContract(index, value) {
      if (value.isActive) {
        this.referralUpdateSelectedApplicantPrgressIndex(index);
        this.referralUpdateSelectedSubProgressIndex(index);
        let currentObj = this.referralSubProgressList[index];
        currentObj[0].isActive = true;
        currentObj[0].isCompleted = false;
        currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
        this.referralUpdateSubProgressList(currentObj);
        this.referralUpdateSelectedApplicantPrgressIndexArray(index);
        switch (index) {
          case 0:
            {
              this.$router.push('/referral-signup/name');
              this.referralUpdateApplicantProgress(6);
            }
            break;
          case 1:
            {
              this.$router.push('/referral-signup/proof-of-identity');
              this.referralUpdateApplicantProgress(25);
            }
            break;
          case 2:
            {
              this.$router.push('/referral-signup/licensing');
              this.referralUpdateApplicantProgress(44);
            }
            break;
          case 3:
            {
              this.$router.push('/referral-signup/payment');
              this.referralUpdateApplicantProgress(64);
            }
            break;
          case 4:
            {
              this.$router.push('/referral-signup/verification');
              this.referralUpdateApplicantProgress(82);
            }
            break;
          case 5:
            {
              this.$router.push('/referral-signup/declaration');
              this.referralUpdateApplicantProgress(100);
            }
            break;
        }

        this.referralUpdateIsActiveValue({ index1: index, index2: 0 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: unset !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
  background: #061238 !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.5) !important;
  font-weight: bold;
  font-size: 18px;
}
.p-progressbar {
  height: 6px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
}
:deep(.p-progressbar .p-progressbar-value) {
  background: $primary-color !important;
  border-radius: 4px !important;
}
:deep(.p-progressbar .p-progressbar-label) {
  display: none !important;
}
.image-icon {
  width: 38px;
  height: 38px;
  background: #353360;
  border-radius: 4px;
}
.image-icon-active {
  background: $primary-color;
}
.divider-line {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.progress-title {
  font-weight: bold;
  font-size: 32px;
  color: $white-color;
}
:deep(.p-timeline-event-opposite) {
  flex: unset !important;
  padding: unset !important;
}
.p-timeline-event-marker {
  background: unset !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
:deep(.p-timeline-event) {
  min-height: 47px !important;
}
.status {
  font-weight: 600;
  font-size: 16px;
}
.status-active {
  color: $white-color;
}
.white-background {
  background: $white-color !important;
}
</style>
