// import Vue from 'vue';
import { createApp } from 'vue';
import { version } from '../package.json';
import App from './App.vue';
import LogRocket from 'logrocket';
import router from './router';
import store from '@/store';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import { debounce } from 'debounce';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import VueCookies from 'vue3-cookies';
import VueSignaturePad from 'vue-signature-pad';

// Common componets
import AutoComplete from '@/components/common/Autocomplete.vue';
import Buttons from '@/components/common/Button.vue';
import Checkbox from '@/components/common/Checkbox.vue';
import DatePicker from '@/components/common/DatePIcker.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import InputNumber from '@/components/common/InputNumber.vue';
import InputWithIcon from '@/components/common/InputFieldWithIcon.vue';
import Sidebar from '@/components/common/Sidebar.vue';
import SingleCheckBox from '@/components/common/SingleCheckBox.vue';
import Spinner from '@/components/common/Spinner.vue';
import TextArea from '@/components/common/TextArea.vue';
import TextField from '@/components/common/TextField.vue';
import Toolbar from '@/components/common/Toolbar.vue';

import vClickOutside from 'click-outside-vue3';

const domainUrl = process.env.VUE_APP_CUSTOM_THEME_DOMAIN_URL ?? 'broker.bridgit.com.au';
if (window.location.host === domainUrl) {
  LogRocket.init('vuowhw/broker-v2', {
    release: version,
  });
}

const app = createApp(App);

// In vue 3 production tip is false in default
// Vue.config.productionTip = false;

app.use(PrimeVue);
app.use(store);
app.use(VueCookies);
app.use(ToastService);
app.use(vClickOutside);
app.use(VueSignaturePad);
app.use(debounce);

// Common components
app.component('TextField', TextField);
app.component('Button', Buttons);
app.component('Dropdown', Dropdown);
app.component('Toolbar', Toolbar);
app.component('Checkbox', Checkbox);
app.component('DatePicker', DatePicker);
app.component('Sidebar', Sidebar);
app.component('InputWithIcon', InputWithIcon);
app.component('TextArea', TextArea);
app.component('AutoComplete', AutoComplete);
app.component('Spinner', Spinner);
app.component('SingleCheckbox', SingleCheckBox);
app.component('Inputnumber', InputNumber);
app.directive('tooltip', Tooltip);

app.use(router).mount('#app');
