<template>
  <div class="content-deposti-wrapper">
    <h1 class="module-title loan-progress-title">Agreement</h1>
    <div>
      <p class="label pm-txt-color hk-grotest-medium">
        I have reviewed, understand and consent to
        <span @click="showPrivacy = true" class="cursor-pointer underline"> Privacy Statement </span>
        and agree to have my identity verified with the issuer or official record holder.
      </p>
      <form action="" class="flex alignt-items-center">
        <div class="radio-input flex align-items-center">
          <SingleCheckbox
            :value="information"
            @getInputValue="(e) => (information = e)"
            id="Yes, I confirm that I have read and acknowledged the above information "
          />
          <label
            class="radio-label hk-grotest-medium pb-1"
            for="Yes, I confirm that I have read and acknowledged the above information "
            >Yes, I agree
          </label>
        </div>
      </form>
    </div>
    <div>
      <p class="label pm-txt-color hk-grotest-medium">
        I have reviewed, understand and consent to
        <span class="cursor-pointer underline" @click="showTerms = true">Bridgit Broker Terms And Conditions.</span>
      </p>
      <form action="" class="flex alignt-items-center">
        <div class="radio-input flex align-items-center">
          <SingleCheckbox :value="personal" @getInputValue="(e) => (personal = e)" id="Yes, I agree" />
          <label class="radio-label hk-grotest-medium pb-1" for="True">Yes, I agree</label>
        </div>
      </form>
    </div>
    <div v-if="showError" class="label notify-content">
      <img src="@/assets/alert-triangle.svg" />
      <p class="ml-2 notify-text">Please check all the boxes above to proceed with your application.</p>
    </div>

    <div class="flex align-items-center">
      <Button icon="pi pi-chevron-right" iconPos="right" label="Next" type="primary" @click="goToNext" />
      <div class="back ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
    <Dialog :visible="showPrivacy" :modal="showPrivacy" :style="{ width: isMD ? '' : '70vw' }">
      <div class="flex justify-content-end cursor-pointer">
        <img src="@/assets/close_icon.svg" @click="showPrivacy = false" />
      </div>
      <iframe class="mt-3" width="100%" height="400px" src="https://techlend.com.au/privacy-policy" />
    </Dialog>
    <Dialog :visible="showTerms" :modal="showTerms" :style="{ width: isMD ? '' : '70vw' }">
      <div class="flex justify-content-end cursor-pointer">
        <img @click="showTerms = false" src="@/assets/close_icon.svg" />
      </div>
      <iframe class="mt-3" width="100%" height="400px" src="https://techlend.com.au/terms-conditions/" />
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Dialog from 'primevue/dialog';

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      information: null,
      personal: null,
      showError: false,
      showTerms: false,
      showPrivacy: false,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      information: { required },
      personal: { required },
    };
  },
  methods: {
    ...mapActions(['referralUpdateSubProgressList', 'updateApplicantProgress']),
    ...mapActions({
      setReferralBrokerAgreement: 'brokerSignup/setReferralBrokerAgreement',
    }),
    getFormData() {
      let newArr = [
        {
          type: 'agreement',
          accepted: this.information,
          description: 'information',
        },
        {
          type: 'agreement',
          accepted: this.personal,
          description: 'personal',
        },
      ];
      this.setReferralBrokerAgreement(newArr);
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    goToNext() {
      if (!this.information || !this.personal) {
        this.showError = true;
        return;
      }
      this.getFormData();
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[2].isActive = true;
      currentObj[2].color = '#54B7F9';
      currentObj[1].isActive = false;
      currentObj[1].isCompleted = true;
      currentObj[1].color = 'rgba(255, 255, 255, 0.3)';
      currentObj[0].isActive = false;
      currentObj[0].isCompleted = true;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.$router.push('/referral-signup/signature');
    },
    goBack() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[0].isActive = true;
      currentObj[0].color = '#54B7F9';
      currentObj[0].isCompleted = false;
      currentObj[1].isActive = false;
      currentObj[1].isCompleted = false;
      currentObj[1].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.$router.push('/referral-signup/declaration');
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.information = this.referralFormData.agreement[0].accepted;
    this.personal = this.referralFormData.agreement[1].accepted;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.label,
label {
  font-size: 1.125rem;
}

.radio-label {
  margin-bottom: 0;
}
.privacy-policy {
  color: $primary-color;
  text-decoration: underline;
}
form {
  gap: 1rem;
  margin-bottom: 1.5rem;

  input[type='checkbox'] {
    width: 1.125rem;
    height: 1.125rem;
  }
}
.notify-content {
  border: 1px solid;
  padding: 1rem;
  border-radius: 5px;
  color: red;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.notify-text {
  margin-bottom: 0rem;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
.notify-content {
  border: 1px solid;
  padding: 1rem;
  border-radius: 5px;
  color: red;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.notify-text {
  margin-bottom: 0rem;
}
:deep(.p-checkbox .p-checkbox-box .p-checkbox-icon) {
  color: $secondary-color;
}
:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 1px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0rem #ced4da !important;
  border-color: #ced4da !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover) {
  border-color: #ced4da !important;
}
.underline {
  text-decoration: underline;
  color: $primary-color;
}
</style>
