<template>
  <div class="footer-container content">
    <div class="p-d-flex p-ai-center">
      <img v-if="customLogo" :src="customLogo" class="mr-2 logo-icon" />
      <img v-else src="@/assets/bridgit.svg" class="mr-2 logo-icon" />
      <div class="ml-8 notes-section">
        <p>{{ disclaimerMessage }}</p>
        <p class="mt-1">We use bank level encryption and security across our platform.</p>
      </div>
    </div>

    <div class="copyright-section">
      <div class="terms-conditions p-d-flex">
        <h1 class="mr-3 cursor-pointer" @click="goToTerms">Terms & Conditions</h1>
        <h1 class="cursor-pointer" @click="goToPrivacy">Privacy Policy</h1>
      </div>
      <span>Copyright &#169; {{ getYear }} Bridgit. All rights reserved.</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      getYear: '',
    };
  },
  methods: {
    goToTerms() {
      const url =
        process.env.VUE_APP_CUSTOM_THEME_TERMS_AND_CONDITIONS_URL ?? 'https://www.bridgit.com.au/terms-conditions';
      window.open(url, '_blank');
    },
    goToPrivacy() {
      const url = process.env.VUE_APP_CUSTOM_THEME_PRIVACY_POLICY_URL ?? 'https://www.bridgit.com.au/privacy-policy';
      window.open(url, '_blank');
    },
  },
  computed: {
    customLogo() {
      return process.env.VUE_APP_CUSTOM_THEME_LOGO;
    },
    disclaimerMessage() {
      return (
        process.env.VUE_APP_CUSTOM_THEME_DISCLAIMER ??
        'Eligibility and approval is subject to standard credit assessment and not all amounts, term lengths or rates will be available to all applicants. Fees, terms and conditions apply.'
      );
    },
  },
  created() {
    this.getYear = new Date().getFullYear();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  background-image: linear-gradient(#f1f6fb, white, #f1f6fb) !important;
  padding: 0 8rem;

  .notes-section {
    text-align: left;
    width: 380px;
    font-size: 10px;
    color: #808080;
  }
  .copyright-section {
    justify-content: space-between;
    .terms-conditions {
      h1 {
        font-weight: bold;
        font-size: 12px;
        color: $secondary-color;
        cursor: pointer;
      }
    }
    span {
      font-size: 10px;
      color: #808080;
    }
  }
  .logo-icon {
    height: 50px;
  }
}
</style>
