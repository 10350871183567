<template>
  <div>
    <Spinner v-if="isLoading" class="flex" />
    <div>
      <Toolbar
        @change="toggleSideNav($event)"
        :toggleOptionClose="toggleOptionClose"
        :toggleSideNavClicked="toggleSideNavClicked"
        :key="toolBarKey"
      />
      <div class="flex">
        <div class="sidebar-component">
          <Sidebar
            :key="sideBarKey"
            :toggleSideNavClicked="toggleSideNavClicked"
            @close="closeSidebar"
            :menuItems="menuItems"
            @change="toggleSideNavClose($event)"
            title="hello"
          />
        </div>
        <div
          class="router-component"
          :class="$route.name === 'KnowledgeBase' ? 'kb' : isMD ? ' pl-3 pr-3 pt-3 pb-3' : 'pl-6 pr-6 pb-4'"
        >
          <router-view></router-view>
        </div>
      </div>
      <!-- <div class="fab">
        <img src="@/assets/Chat-icon.png" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { TOKEN } from '@/constants';
import { mapGetters } from 'vuex';
import { useCookies } from 'vue3-cookies';
import Screensize from '@/mixins/screensize.mixin';
const { cookies } = useCookies();

export default {
  name: 'Index',
  mixins: [Screensize],
  components: {},
  data() {
    return {
      sideBarKey: 0,
      toggleOptionClose: false,
      toolBarKey: 10,
      toggleSideNavClicked: false,
      isLoading: true,
      value: '',
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'token', 'brokerType']),
    menuItems: function () {
      if (this.brokerType !== 'broker') {
        return [
          {
            id: 1,
            text: 'Dashboard',
            icon: 'dashboard',
            link: '/dashboard',
          },
          {
            id: 3,
            text: 'Contact us',
            icon: 'contact',
            link: '/contact',
          },
          {
            id: 4,
            icon: 'settings',
            text: 'Settings',
            link: '/settings',
          },
        ];
      }
      return [
        {
          id: 1,
          text: 'Dashboard',
          icon: 'dashboard',
          link: '/dashboard',
        },
        {
          id: 2,
          text: 'Applications',
          icon: 'application',
          link: '/application',
        },
        {
          id: 3,
          text: 'Contact us',
          icon: 'contact',
          link: '/contact',
        },
        {
          id: 4,
          icon: 'settings',
          text: 'Settings',
          link: '/settings',
        },
        {
          id: 5,
          icon: 'faq',
          text: 'Knowledge hub',
          link: '/knowledge-hub',
        },
      ];
    },
  },
  methods: {
    toggleSideNav(event) {
      this.toggleSideNavClicked = event;
      this.sideBarKey += 1;
    },
    closeSidebar() {
      this.toggleSideNavClicked = false;
      this.sideBarKey += 1;
    },
    toggleSideNavClose(event) {
      this.sideBarKey += 1;
      this.toggleSideNavClicked = event;
      this.toggleOptionClose = false;
      this.toolBarKey += 1;
    },
  },
  watch: {
    isLoggedIn() {
      this.value = cookies.get(TOKEN);
    },
    token() {
      this.value = cookies.get(TOKEN);
    },
  },
  created() {
    this.isLoading = true;
    this.value = cookies.get(TOKEN);
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.sidebar-component {
  background-color: $secondary-color;
  height: calc(100vh - 80px);
  position: relative;
}

.router-component {
  background: #f1f6fb;
  // min-height: 100vh;
  height: calc(100vh - 80px);
  overflow: auto;
  width: 100%;
  padding-top: 42px;

  &.kb {
    padding-top: 0;
    overflow: hidden;
  }
}

.fab {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 1;
  background-color: $primary-color;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>
