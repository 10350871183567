import { API, TECHLEND_BE_URL } from '@/constants.api';
import { TOKEN } from '@/constants';
import { _get, _post } from '@/services/crudService';
import { brokerInitialFormData } from './constants';
import { getBrokerPayload } from '@/services/commonServices';
import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

export default {
  namespaced: true,

  state: {
    isSignupResend: false,
    formData: {
      type: 'broker',
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      mobile: '',
      line1: '',
      line2: '',
      state: '',
      city: '',
      country: '',
      postCode: 0,
      businessLine1: '',
      businessLine2: '',
      businessState: '',
      businessCity: '',
      businessCountry: '',
      businessPostCode: 0,
      businessMobileNumber: '',
      accountName: '',
      bsb: '',
      accNo: '',
      licensingType: '',
      aclRegistrationName: '',
      aclNumber: '',
      name: '',
      australianBusinessNumber: '',
      crNumber: '',
      proofType: '',
      proofDocNumber: '',
      proofDocExpDate: '',
      aggregationGroup: '',
      brokerGroupName: '',
      numberOfBrokers: '',
      primaryBusinessActivity: '',
      certOfCurrencyName: '',
      certOfMembershipName: '',
      proofFileUrlName: '',
      statementFileName: '',
      broker_signature: '',
      broker_signature_name: '',
      declaration: [
        {
          description: 'bankrupt',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'crime',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'lender',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'license',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'registration',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'indemnity',
          accepted: null,
          type: 'declaration',
        },
      ],
      agreement: [
        {
          description: 'information',
          accepted: false,
          type: 'agreement',
        },
        {
          description: 'personal',
          accepted: false,
          type: 'agreement',
        },
      ],
    },
    isReferralSignupResend: false,
    referralFormData: {
      type: 'referralBroker',
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      mobile: '',
      otp: '',
      line1: '',
      line2: '',
      state: '',
      city: '',
      country: '',
      postCode: '',
      proofType: '',
      proofDocNumber: '',
      proofDocExpDate: '',
      proofFileUrl: '',
      licensingType: '',
      businessName: '',
      'abn/acn': '',
      gstRegistered: '',
      businessLine1: '',
      businessLine2: '',
      businessState: '',
      businessCity: '',
      businessCountry: '',
      businessPostCode: '',
      accountName: '',
      bsb: '',
      accNo: '',
      broker_signature: '',
      broker_signature_name: '',
      referredBy: '',
      declaration: [
        {
          description: 'bankrupt',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'crime',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'lender',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'license',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'registration',
          accepted: null,
          type: 'declaration',
        },
        {
          description: 'indemnity',
          accepted: null,
          type: 'declaration',
        },
      ],
      agreement: [
        {
          description: 'information',
          accepted: false,
          type: 'agreement',
        },
        {
          description: 'personal',
          accepted: false,
          type: 'agreement',
        },
      ],
    },
    contractedAggregatorGroups: [
      'Finsure - Beagle Finance',
      'Finsure - Finsure Financial and Insurance',
      'Loan Market Australia',
      'Outsource Financial',
    ],
  },
  getters: {
    brokerFormData: ({ formData }) => formData,
    referralFormData: ({ referralFormData }) => referralFormData,
    isSignupResend: ({ isSignupResend }) => isSignupResend,
    isReferralSignupResend: ({ isReferralSignupResend }) => isReferralSignupResend,
    contractedAggregatorGroups: ({ contractedAggregatorGroups }) => contractedAggregatorGroups,
  },
  mutations: {
    setIsSignupResend(state, payload) {
      state.isSignupResend = payload;
    },
    setFormData(state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        state.formData[key] = value;
      }
    },
    setReferralBrokerDeclaration(state, payload) {
      state.referralFormData.declaration = payload;
    },
    setBrokerDeclaration(state, payload) {
      state.formData.declaration = payload;
    },
    setBrokerAgreement(state, payload) {
      state.formData.agreement = payload;
    },
    setReferralBrokerAgreement(state, payload) {
      state.referralFormData.agreement = payload;
    },
    setReferralFormData(state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        state.referralFormData[key] = value;
      }
    },
    setIsReferralSignupResend(state, payload) {
      state.isReferralSignupResend = payload;
    },
  },
  actions: {
    // broker signup
    setIsSignupResend({ commit }, payload) {
      commit('setIsSignupResend', payload);
    },
    setFormData({ commit }, payload) {
      commit('setFormData', payload);
    },
    async resendOtp(state, payload) {
      await _post(TECHLEND_BE_URL + API.REGISTER.REQUEST_OTP, payload);
    },
    async registerBroker({ state }) {
      const { formData } = state;
      let newPayload = getBrokerPayload(formData);
      const { data } = await _post(TECHLEND_BE_URL + API.AUTH.REGISTER_BROKER, newPayload);
      return data.token;
    },
    async createReferral({ state }) {
      let payload = { ...state.referralFormData };
      payload.declaration = [...state.referralFormData.declaration, ...state.referralFormData.agreement];
      payload.businessPhoneNo = '';
      delete payload.certOfCurrencyName;
      delete payload.certOfMembershipName;
      delete payload.proofFileUrlName;
      delete payload.statementFileName;
      delete payload.agreement;
      delete payload.aclNumber;
      delete payload.aclRegistrationName;
      delete payload.confirmEmail;
      delete payload.crNumber;
      delete payload.postCode;
      delete payload.name;
      payload.timestamp = state.referralFormData.proofFileUrl;
      // TODO: replace with v2 URL once referrals are implemented
      // await _post(SERVER_URL + "referral-broker-signup", payload);
    },
    async sendOtp(state, payload) {
      await _post(TECHLEND_BE_URL + API.REGISTER.REQUEST_OTP, payload);
    },
    async verifyOtp(state, payload) {
      const { data } = await _post(TECHLEND_BE_URL + API.AUTH.REGISTER_BROKER, payload);
      if (data?.token) {
        cookies.set(TOKEN, data.token);
      }
    },
    async getBrokerGroups(state, name) {
      if (!name || !name.length) {
        return;
      }
      return await _get(TECHLEND_BE_URL + API.PUBLIC.BROKER_GROUPS + (name ? '?search=' + name : ''));
    },
    setBrokerDeclaration({ commit }, payload) {
      commit('setBrokerDeclaration', payload);
    },
    setBrokerAgreement({ commit }, payload) {
      commit('setBrokerAgreement', payload);
    },

    // referral signup
    setIsReferralSignupResend({ commit }, payload) {
      commit('setIsReferralSignupResend', payload);
    },
    setReferralFormData({ commit }, payload) {
      commit('setReferralFormData', payload);
    },
    setReferralBrokerDeclaration({ commit }, payload) {
      commit('setReferralBrokerDeclaration', payload);
    },
    setReferralBrokerAgreement({ commit }, payload) {
      commit('setReferralBrokerAgreement', payload);
    },
    async createReferralBroker({ state, commit }) {
      const data = new FormData();
      for (const [key, value] of Object.entries(state.referralFormData)) {
        if (value) data.append(key, value);
      }
      // TODO: update with v2 URL when referrals sorted
      // await _post(SERVER_URL + "referral-broker-signup", data);
      commit('setFormData', brokerInitialFormData);
      window.localStorage.clear();
      localStorage.removeItem('vuex');
      cookies.remove(TOKEN);
      location.reload();
    },
    async getReferralOTP(/*state, payload*/) {
      // TODO: update with v2 URL when referrals sorted
      // await _post(
      // 	SERVER_URL + "send-otp-to-broker-before-sign-up?type=referral_broker",
      // 	payload
      // );
    },
    async verifyReferraOtp(/*state, payload*/) {
      // TODO: update with v2 URL when referrals sorted
      // await _post(SERVER_URL + "verify-otp-broker-signup", payload);
    },
  },
};
