<template>
  <div>
    <div class="flex justify-content-between">
      <div class="module-sub-title">Incoming properties</div>
      <!-- <div>
        <span>
          <img v-if="count === 2" src="@/assets/minus.svg" @click="count = 1" />
          <img v-else src="@/assets/minus-inactive.svg" />
        </span>
        <span class="pl-3 pr-3">{{ count }}</span>
        <span>
          <img v-if="count === 1" src="@/assets/plus.svg" @click="count = 2" />
          <img v-else src="@/assets/plus-inactive.svg" />
        </span>
      </div> -->
    </div>
    <div class="flex">
      <div class="col">
        <img src="@/assets/prop-1.svg" class="mt-2 mb-2" />
        <div>Address</div>
        <AddressAutocompleteKleberNew
          placeholder="Type address or select state and postcode"
          @onChange="onAddressChange"
          :key="addressKey"
        />
        <div>State<span class="required-class">*</span></div>
        <Dropdown
          @change="getFormData1($event, 'state')"
          :value="formData.state"
          :items="statesList"
          width="100%"
          placeholder="-Select-"
          :key="state1Key"
          :inValid="v$.formData.state.$invalid && onSave"
        />
        <div class="mt-3">Postal code<span class="required-class">*</span></div>
        <Inputnumber
          placeholder="Property postal code"
          class="mt-3"
          label="Postal code"
          width="100%"
          @onInput="getFormData1($event ? Number($event) : 0, 'postCode')"
          :key="postal1Key"
          :value="formData.postCode"
          :maxDigit="3"
          :format="false"
        />
        <div class="mt-3">Purchase price<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Enter purchase amount"
          width="100%"
          :format="true"
          :icon="dollar"
          @onInput="getFormData($event ? Number($event) : 0, 'purchasePrice')"
          type="number"
          :value="formData.purchasePrice"
          :inValid="v$.formData.purchasePrice.$invalid && onSave"
          :maxDigit="8"
        />
        <div>When will stamp duty be paid?<span class="required-class">*</span></div>
        <Dropdown
          width="100%"
          :items="stampTo"
          class="mt-2"
          :inValid="v$.formData.stampDutyStatus.$invalid && onSave"
          optionLabel="name"
          placeholder="-Select-"
          @change="getFormData($event.value, 'stampDutyStatus')"
        />
        <div class="mt-3">Stamp duty</div>
        <InputWithIcon
          class="disable-input"
          :disable="true"
          width="100%"
          :icon="dollar"
          placeholder="Enter amount"
          type="number"
          @onInput="getFormData($event ? Number($event) : 0, 'stampDuty')"
          :value="formData.stampDuty"
          :format="true"
          mode="decimal"
          :minFractionDigits="2"
          :key="stamp1Key"
        />
        <div>Settlement date<span class="required-class">*</span></div>
        <DatePicker
          placeholder="DD/MM/YYYY"
          width="100%"
          @change="getFormData($event, 'settlementDate')"
          :yearRange="'2022:2050'"
          :inValid="v$.formData.settlementDate.$invalid && onSave"
          :minDate="new Date()"
        />
        <div class="mt-3">How will the deposit be paid?<span class="required-class">*</span></div>
        <Dropdown
          width="100%"
          placeholder="-Select-"
          :items="depositTo"
          class="mt-2"
          @change="getFormData($event.value, 'depositPaidType')"
          :value="formData.depositPaidType"
          :inValid="v$.formData.depositPaidType.$invalid && onSave"
          optionLabel="name"
        />
        <div class="mt-3">Deposit amount<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Enter deposit amount"
          width="100%"
          :inValid="v$.formData.depositAmount.$invalid && onSave"
          :format="true"
          :icon="dollar"
          @onInput="getFormData($event ? Number($event) : 0, 'depositAmount')"
          type="number"
          :value="formData.depositAmount"
          :maxDigit="8"
        />
        <div>Property type<span class="required-class">*</span></div>
        <Dropdown
          :items="assignTo"
          :inValid="v$.formData.propertyType.$invalid && onSave"
          width="100%"
          optionLabel="name"
          class="mt-2"
          @change="getFormData($event.value, 'propertyType')"
          placeholder="-Select-"
        />
      </div>
      <div class="ml-2 col" v-if="count == 2">
        <img src="@/assets/prop-2.svg" class="mt-2 mb-2" />
        <div>Address<span class="required-class">*</span></div>
        <AddressAutocompleteKleberNew @onChange="onAddress2Change" />
        <div>State</div>
        <Dropdown
          @change="getForm2Data($event, 'state')"
          :value="form2Data.state"
          :items="statesList"
          width="100%"
          placeholder="-Select-"
          :key="state2Key"
          :disable="state2Disabled"
        />
        <div class="mt-3">Postal code<span class="required-class">*</span></div>
        <Inputnumber
          placeholder="Property postal code"
          class="mt-3"
          label="Postal code"
          width="100%"
          @onInput="getForm2Data($event ? Number($event) : 0, 'postCode')"
          :key="postal2Key"
          :value="form2Data.postCode"
          :disable="postal2Disabled"
          :maxDigit="3"
          :format="false"
        />
        <div class="mt-3">Purchase price<span class="required-class">*</span></div>
        <InputWithIcon
          placeholder="Property purchase price"
          width="100%"
          :format="true"
          :icon="dollar"
          @onInput="getForm2Data($event ? Number($event) : 0, 'purchasePrice')"
          type="number"
          :value="form2Data.purchasePrice"
          :maxDigit="8"
        />
        <div>When will stamp duty be paid?<span class="required-class">*</span></div>
        <Dropdown
          width="100%"
          :items="stampTo"
          class="mt-2"
          optionLabel="name"
          @change="getForm2Data($event.value, 'stampDutyStatus')"
          :value="form2Data.stampDutyStatus"
          placeholder="-Select-"
        />
        <div class="mt-3">Stamp duty<span class="required-class">*</span></div>
        <InputWithIcon
          class="disable-input"
          :disable="true"
          width="100%"
          :format="true"
          :icon="dollar"
          placeholder="0"
          @onInput="getForm2Data($event ? Number($event) : 0, 'stampDuty')"
          :key="stamp2Key"
          mode="decimal"
          :minFractionDigits="2"
          :value="form2Data.stampDuty"
        />
        <div>Settlement date<span class="required-class">*</span></div>
        <DatePicker
          placeholder="DD/MM/YYYY"
          width="100%"
          @change="getForm2Data($event, 'settlementDate')"
          :yearRange="'2022:2050'"
          :minDate="new Date()"
        />
        <div class="mt-3">How will the deposit be paid?<span class="required-class">*</span></div>
        <Dropdown width="100%" :items="depositTo" class="mt-2" placeholder="-Select-" optionLabel="name" />
        <div class="mt-3">Deposit amount<span class="required-class">*</span></div>
        <InputWithIcon
          width="100%"
          placeholder="Enter deposit amount"
          :format="true"
          :icon="dollar"
          @onInput="getForm2Data($event, 'depositAmount')"
          type="number"
          :value="form2Data.depositAmount"
          :maxDigit="8"
        />
        <div>Property type<span class="required-class">*</span></div>
        <Dropdown
          :items="assignTo"
          width="100%"
          class="mt-2"
          optionLabel="name"
          @change="getForm2Data($event.value, 'propertyType')"
          placeholder="-Select-"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { STATES } from '@/constants';
import { integer, maxValue, minValue, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import AddressAutocompleteKleberNew from '@/components/common/AddressAutoCompleteKleberNew.vue';
import Dollar from '@/assets/dollar.svg';

export default {
  name: 'IncomingProperties',
  components: {
    AddressAutocompleteKleberNew,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (this.count == 1) {
      return {
        formData: {
          state: { required },
          postCode: { required },
          purchasePrice: {
            required,
            minValue: minValue(100),
            integer,
            maxValue: maxValue(999999999),
          },
          depositPaidType: { required },
          stampDutyStatus: { required },
          settlementDate: { required },
          depositAmount: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyType: { required },
          // city: { required },
          // country: { required },
          // line1: { required },
        },
      };
    }
    if (this.count == 2) {
      return {
        formData: {
          state: { required },
          postCode: { required },
          purchasePrice: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          stampDutyStatus: { required },
          settlementDate: { required },
          depositPaidType: { required },
          depositAmount: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyType: { required },
          city: { required },
          country: { required },
          line1: { required },
        },
        form2Data: {
          state: { required },
          postCode: { required },
          purchasePrice: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          stampDutyStatus: { required },
          settlementDate: { required },
          depositPaidType: { required },
          depositAmount: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          propertyType: { required },
          city: { required },
          country: { required },
          line1: { required },
        },
      };
    }
  },
  data() {
    return {
      stamp1Key: 10,
      stamp2Key: 20,
      dollar: Dollar,
      count: 1,
      state: '',
      assignTo: [
        { name: 'Residential', value: 'residential' },
        { name: 'Rural Residential', value: 'rural_residential' },
        { name: 'Off the plan residential', value: 'off_plan_residential' },
        { name: 'Commercial', value: 'commercial' },
        { name: 'Vacant Land', value: 'vacant_land' },
      ],
      depositTo: [
        { value: 'cash', name: 'Cash' },
        { value: 'deposit_bond', name: 'Deposit bond' },
      ],
      stampTo: [
        { name: 'To be paid on settlement', value: 'at_settlement' },
        {
          name: 'To be paid prior to settlement',
          value: 'prior_to_settlement',
        },
        { name: 'Already paid', value: 'already_paid' },
      ],
      state1Key: 30,
      postal1Key: 40,
      state2Key: 50,
      postal2Key: 60,
      postal1Disabled: false,
      postal2Disabled: false,
      state1Disabled: false,
      state2Disabled: false,
      formData: {
        state: '',
        postCode: '',
        purchasePrice: 0,
        stampDutyStatus: '',
        stampDuty: '0',
        settlementDate: '',
        depositPaidType: '',
        depositAmount: 0,
        propertyType: '',
        line1: '',
        line2: '',
        city: '',
        country: '',
        postalcode_area_looking: '',
      },
      statesList: STATES,
      form2Data: {
        state: '',
        postCode: '',
        purchasePrice: 0,
        stampDutyStatus: '',
        stampDuty: '0',
        settlementDate: '',
        depositPaidType: '',
        depositAmount: 0,
        propertyType: '',
        line1: '',
        line2: '',
        city: '',
        country: '',
      },
      addressKey: 0,
    };
  },
  methods: {
    ...mapActions({
      setIncomingProperties: 'dashboard/setIncomingProperties',
      fetchZoneByPostal: 'dashboard/fetchZoneByPostal',
      setIncomeCount: 'dashboard/setIncomeCount',
      fetchStampDuty: 'dashboard/fetchStampDuty',
    }),
    async onAddressChange(address) {
      this.formData.line1 = address.line1 ? address.line1 : '';
      this.formData.line2 = address.line2 ? address.line2 : '';
      if (address.state) {
        const isState = this.statesList.find((obj) => obj == address.state);
        if (isState) {
          this.formData.state = address.state;
          this.state1Disabled = true;
        } else {
          this.formData.state = '';
          this.state1Disabled = false;
        }
      } else {
        this.formData.state = '';
        this.state1Disabled = false;
      }
      this.formData.city = address.city ? address.city : '';
      this.formData.country = address.country ? address.country : '';
      this.formData.postCode = address.postCode ? Number(address.postCode) : 0;
      if (address.postCode) {
        this.formData.postCode = Number(address.postCode);
        this.postal1Disabled = true;
      } else {
        this.postal1Disabled = false;
        this.formData.postCode = '';
      }
      this.state1Key += 1;
      this.postal1Key += 1;
      this.formData.postalcode_area_looking = 0;
      await this.getFormData(this.formData.postCode, 'postCode');
      await this.getFormData(this.formData.line1, 'line1');
      await this.getFormData(this.formData.line2, 'line2');
      await this.getFormData(this.formData.state, 'state');
      await this.getFormData(this.formData.city, 'city');
      await this.getFormData(this.formData.country, 'country');
      await this.getFormData(this.formData.postalcode_area_looking, 'postalcode_area_looking');
    },
    async onAddress2Change(address) {
      this.form2Data.line1 = address.line1 ? address.line1 : '';
      this.form2Data.line2 = address.line2 ? address.line2 : '';
      this.form2Data.city = address.line1 ? address.city : '';
      if (address.state) {
        this.form2Data.state = address.state;
        this.state2Disabled = true;
      } else {
        this.form2Data.state = '';
        this.state2Disabled = false;
      }
      this.form2Data.country = address.country ? address.country : '';
      if (address.postCode) {
        this.form2Data.postCode = Number(address.postCode);
        this.postal2Disabled = true;
      } else {
        this.postal2Disabled = true;
        this.form2Data.postCode = '';
      }
      this.state2Key += 1;
      this.postal2Key += 1;
      await this.getForm2Data(this.form2Data.line1, 'line1');
      await this.getForm2Data(this.form2Data.line2, 'line2');
      await this.getForm2Data(this.form2Data.state, 'state');
      await this.getForm2Data(this.form2Data.postCode, 'postCode');
      await this.getForm2Data(this.form2Data.city, 'city');
      await this.getForm2Data(this.form2Data.country, 'country');
    },
    async getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.setIncomingProperties({
        key: fieldName,
        value: value,
        index: 0,
      });
      if (fieldName === 'postCode' && value > 100) {
        const result = await this.fetchZoneByPostal(value);
        for (const [key, value] of Object.entries(result)) {
          if (key) {
            this.setIncomingProperties({
              key: key,
              value: value,
              index: 0,
            });
          }
        }
      }
      if (fieldName === 'purchasePrice' || fieldName === 'state') {
        if (this.formData.purchasePrice > 0) {
          const result = await this.fetchStampDuty({
            state: this.formData.state.toLowerCase(),
            purchasePrice: this.formData.purchasePrice,
          });
          this.formData.stampDuty = result.toString();
          this.stamp1Key += 1;
          this.setIncomingProperties({
            key: 'stampDuty',
            value: this.formData.stampDuty,
            index: 0,
          });
        }
      }
      if (fieldName === 'purchasePrice') {
        this.setIncomingProperties({
          key: 'purchasePriceUsed',
          value: value,
          index: 0,
        });
      }
      if (fieldName === 'propertyType') {
        this.setIncomingProperties({
          key: 'propertyType',
          value: value,
          index: 0,
        });
      }
      this.$emit('validateIncoming', this.v$.$invalid);
    },
    async getFormData1(value, fieldName) {
      this.addressKey += 1;
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.setIncomingProperties({
        key: fieldName,
        value: value,
        index: 0,
      });
      if (fieldName === 'postCode' && value > 100) {
        const result = await this.fetchZoneByPostal(value);
        for (const [key, value] of Object.entries(result)) {
          if (key) {
            this.setIncomingProperties({
              key: key,
              value: value,
              index: 0,
            });
          }
        }
      }
      if (fieldName === 'state') {
        if (this.formData.purchasePrice > 0) {
          const result = await this.fetchStampDuty({
            state: this.formData.state.toLowerCase(),
            purchasePrice: this.formData.purchasePrice,
          });
          this.formData.stampDuty = result.toString();
          this.stamp1Key += 1;
          this.setIncomingProperties({
            key: 'stampDuty',
            value: this.formData.stampDuty,
            index: 0,
          });
        }
      }
      this.formData.postalcode_area_looking = this.formData.postCode;

      this.formData.line1 = '';
      this.formData.line2 = '';
      this.formData.city = '';
      this.formData.country = '';
      // this.formData.postCode = "";
      this.setIncomingProperties({
        key: 'line1',
        value: this.formData.line1,
        index: 0,
      });
      this.setIncomingProperties({
        key: 'line2',
        value: this.formData.line2,
        index: 0,
      });
      this.setIncomingProperties({
        key: 'city',
        value: this.formData.city,
        index: 0,
      });
      this.setIncomingProperties({
        key: 'country',
        value: this.formData.country,
        index: 0,
      });
      this.setIncomingProperties({
        key: 'postCode',
        value: this.formData.postCode,
        index: 0,
      });
      this.setIncomingProperties({
        key: 'postalcode_area_looking',
        value: this.formData.postalcode_area_looking,
        index: 0,
      });
      this.$emit('validateIncoming', this.v$.$invalid);
    },
    async getForm2Data(value, fieldName) {
      this.form2Data[fieldName] = value;
      this.form2Data = { ...this.form2Data };
      this.setIncomingProperties({
        key: fieldName,
        value: value,
        index: 1,
      });
      if (fieldName === 'postCode' && value > 100) {
        const result = await this.fetchZoneByPostal(value);
        for (const [key, value] of Object.entries(result)) {
          if (key) {
            this.setIncomingProperties({
              key: key,
              value: value,
              index: 1,
            });
          }
        }
      }
      if (fieldName === 'purchasePrice' || fieldName === 'state') {
        if (this.form2Data.purchasePrice > 0) {
          const result = await this.fetchStampDuty({
            state: this.form2Data.state.toLowerCase(),
            purchasePrice: this.form2Data.purchasePrice,
          });
          this.formData.stampDuty = result.toString();
          this.stamp2Key += 1;
          this.setIncomingProperties({
            key: 'stampDuty',
            value: this.form2Data.stampDuty,
            index: 1,
          });
        }
      }
      if (fieldName === 'purchasePrice' || fieldName === 'state') {
        if (this.form2Data.purchasePrice > 0) {
          const result = await this.fetchStampDuty({
            state: this.form2Data.state.toLowerCase(),
            purchasePrice: this.form2Data.purchasePrice,
          });
          this.form2Data.stampDuty = result.toString();
          this.stamp2Key += 1;
        }
      }
      this.$emit('validateIncoming', this.v$.$invalid);
    },
  },
  computed: {
    ...mapGetters({
      calculatorFields: 'dashboard/calculatorFields',
      onSave: 'dashboard/onSave',
    }),
  },
  watch: {
    async count() {
      this.$emit('validateIncoming', this.v$.$invalid);
      await this.setIncomeCount(this.count);
    },
  },
  created() {
    this.setIncomeCount(this.count);
    // this.$emit("validateIncoming", this.v$.$invalid);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
img {
  cursor: pointer;
}
</style>
