<template>
  <div class="mr-top">
    <div class="section-3">
      <Panel class="ml-4 pr-4" header="Header" :toggleable="true" :collapsed="collapsed">
        <template #header>
          <div class="title-icon p-d-flex p-ai-center">
            <img :src="require('@/assets/home_icon_color.svg')" alt />
            <p class="pm-txt-color title">Purchase properties</p>
          </div>
        </template>

        <template #icons>
          <div @click="toggle" class="icon-wrapper">
            <img v-if="collapsed" src="@/assets/down-arrow.svg" />
            <img style="transform: rotate(180deg)" v-else src="@/assets/down-arrow.svg" />
          </div>
        </template>
        <div class="sec-content" v-for="(item, i) in items" :key="i">
          <div class="sec-row" :class="isMD ? '' : 'p-d-flex p-ai-center p-jc-between'">
            <p class="address-text">
              {{ formatAddress(item) }}
            </p>
            <div class="col" :class="isMD ? '' : 'p-d-flex p-ai-center p-jc-between'">
              <p class="text pm-txt-color p-d-flex p-ai-center">
                <span class="lt-txt-color">Settlement date:</span>
                <span v-if="item.settlementDate">{{ formatDate(item.settlementDate) }}</span>
                <span v-else>-</span>
              </p>
              <p class="text pm-txt-color p-d-flex p-ai-center">
                <span class="lt-txt-color">Purchase price:</span>
                <span>{{ formatCurrency(item.purchasePrice) }}</span>
              </p>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import { formatAddress, formatCurrency, formatDate } from '@/services/formatHelper';
import Panel from 'primevue/panel';
import Screensize from '@/mixins/screensize.mixin';

export default {
  components: { Panel },
  mixins: [Screensize],
  props: ['cmp', 'items'],
  setup() {
    return {
      formatAddress,
      formatCurrency,
      formatDate,
    };
  },
  data() {
    return {
      collapsed: true,
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

p,
:deep(td) {
  font-family: Gordita-medium !important;
}
:deep(.p-panel .p-panel-header .p-panel-header-icon) {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  display: none !important;
}
.content {
  margin-top: 2rem;
  font-family: HK-medium;
}

.sec-wrapper {
  border-bottom: 1px solid $light-text-color;
  padding-bottom: 1rem;
  font-family: $font-family !important;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 1rem;
}

.title {
  font-weight: bold;
  color: $black-color;
  font-size: 2.1rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 2;
}

.text {
  margin-bottom: 0.3rem;
  font-weight: 600;
}

.field-not {
  width: 100%;
}

.sec-title {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 600;
}

.second-row {
  margin-top: 1rem;
}

.heading,
.text {
  font-size: 0.875rem !important;
}

.inputs {
  gap: 1rem;
  align-items: flex-end;
}

.section-1 {
  margin-top: 2rem;
  border-bottom: 1px solid $light-text-color;
  padding-bottom: 1rem;

  .details-content {
    gap: 1.375rem;
  }

  .grid-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0 1rem;
    width: 90%;
  }
  .grid-items-2 {
    padding: 0 1rem;
    width: 90%;
  }
}

.section-2 {
  margin-top: 1.875rem;

  .table {
    width: 100%;
  }

  .title-icon {
    padding: 0 1rem;
    gap: 1rem;
    align-items: center;
  }

  .sec-content {
    padding: 0 1rem;
    margin-top: 1rem;
    width: 100%;
  }

  :deep(.p-datatable .p-datatable-thead > tr > th) {
    color: $light-text-color;
    background-color: #fff;
    font-size: 1rem;
    font-weight: bold !important;
  }

  :deep(.p-datatable .p-datatable-tbody > tr > td) {
    text-align: left;
    border-bottom: 1px solid $light-text-color;
    color: $primary-text-color;
    font-size: 0.875rem;
    font-weight: bold !important;
  }
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  font-weight: bold !important;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  font-weight: bold !important;
}
.section-3 {
  margin-top: 1.875rem;
  width: 100%;

  .title-icon {
    gap: 1rem;

    .title {
      font-size: 1.25rem;
      margin: 0;
    }
  }
  .sec-row {
    gap: 1rem;
    justify-content: space-between;

    .address-text {
      margin: 0;
      width: 50%;
      @media (max-width: 922px) {
        width: 100%;
      }
    }

    .col {
      gap: 2rem;
      width: 50%;

      p {
        margin: 0;
        gap: 1rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.icon-wrapper {
  gap: 1rem;
  padding: 0 1rem;

  .icon {
    font-size: 2rem;
  }
}

.heading {
  margin: 0;
  font-weight: bold;
  font-size: 1.25rem;
}
:deep(.p-panel .p-panel-content) {
  font-weight: bold !important;
}
.primary-color {
  color: $primary-color;
}
.border-items {
  font-size: 0.875rem !important;
  margin: 0;
  font-weight: bold;
  color: #0200384d !important;
  border-bottom: 1px solid #0200384d !important;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem !important;
}
.border-content {
  margin-left: -0.5rem !important;
}
</style>
