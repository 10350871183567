<template>
  <div class="checkbox-wrapper">
    <h4 class="title">{{ title }}</h4>
    <div :class="customClass" class="p-field-checkbox sd">
      <Checkbox :id="id" :name="name" :value="label" v-model="editValue" @change="EmitToParent" :binary="true" />
      <label class="label-text" :for="id">{{ label }}</label>
    </div>
  </div>
</template>

<script>
import Checkbox from 'primevue/checkbox';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: String,
    title: String,
    customClass: String,
  },
  emits: ['getInputValue'],
  data() {
    return {
      editValue: '',
    };
  },
  methods: {
    EmitToParent() {
      this.$emit('getInputValue', this.editValue);
    },
  },
  watch: {
    value() {
      this.editValue = this.value;
    },
  },
  created() {
    this.editValue = this.value;
  },
  components: { Checkbox },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.label-text {
  font-size: 1rem;
}
:deep(.p-checkbox .p-checkbox-box .p-checkbox-icon) {
  color: $secondary-color;
}
:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}
</style>
