<template>
  <ProgressSpinner style="width: 40px; height: 40px" />
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
  components: {
    ProgressSpinner,
  },
};
</script>

<style lang="scss" scoped>
:deep(.p-progress-spinner-color) {
  stroke: #d62d20 !important;
}
.p-progress-spinner-color {
  stroke: red !important;
}
</style>
