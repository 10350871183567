<template>
  <Card class="notification-wrapper p-4">
    <template #title>
      <div class="module-sub-title">Notification</div>
    </template>
    <template #content>
      <SingleCheckbox
        :value="formData.isStatementNotifications"
        @getInputValue="getFormData($event, 'isStatementNotifications')"
        label="Statements notifications"
        id="Statements notifications"
      />
      <SingleCheckbox
        :value="formData.isMarketingMails"
        @getInputValue="getFormData($event, 'isMarketingMails')"
        label="I would like to receive direct marketing offers"
        id="I would like to receive direct marketing offers"
      />
      <span @click="save"><Button class="mt-2" label="Save preferences" type="primary" /></span>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Card from 'primevue/card';
export default {
  name: 'Notification',
  components: {
    Card,
  },
  data() {
    return {
      formData: {
        isStatementNotifications: false,
        isMarketingMails: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  methods: {
    ...mapActions({
      updateNotification: 'settings/updateNotification',
      getBrokerById: 'settings/getBrokerById',
    }),
    setFormData() {
      this.formData.isStatementNotifications = this.profileDetails.isStatementNotifications;
      this.formData.isMarketingMails = this.profileDetails.isMarketingMails;
    },
    async getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async save() {
      try {
        await this.updateNotification({
          ...this.profileDetails,
          ...this.formData,
        });
        await this.getBrokerById();
        await this.$toast.add({
          severity: 'success',
          detail: 'Notifications updated Successfully',
          life: 2000,
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
  },
  created() {
    this.setFormData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
  color: $secondary-color !important;
  font-weight: 500;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover) {
  border-color: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover) {
  box-shadow: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
}
:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: gray;
}
</style>
