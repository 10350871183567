import ApplicationComponent from '@/views/broker/Applications/index.vue';
import ContactComponent from '@/views/broker/Contact/index.vue';
import CusotmerComponent from '@/views/broker';
import DashboardComponent from '@/views/broker/Dashboard/index.vue';
import DashboardMainComponent from '@/views/broker/Dashboard/Main.vue';
import LoanComponent from '@/views/broker/Applications/LoanComponent.vue';
import ServicingCalculator from '@/views/broker/ServicingCalculator/index.vue';
import SettingsComponent from '@/views/broker/Settings/index.vue';
// import Login from '@/views/broker/Login/index.vue'
// import Signup from '@/views/broker/Signup/index.vue'
// import Name from '@/views/broker/Signup/PersonalDetails/Name.vue'
// import Address from '@/views/broker/Signup/PersonalDetails/Address.vue'
// import Email from '@/views/broker/Signup/PersonalDetails/Email.vue'
// import Payment from '@/views/broker/Signup/PersonalDetails/Payment.vue'
// import Licensing from '@/views/broker/Signup/Licensing.vue'
// import Contract from '@/views/broker/Signup/ContractDetails/ContractDetails.vue'
// import CompanyAddress from '@/views/broker/Signup/ContractDetails/CompanyAddress.vue'
// import ProofOfIdentity from '@/views/broker/Signup/Documents/ProofOfIdentity.vue'
// import Statement from '@/views/broker/Signup/Documents/Statement-of-attainment.vue'
// import Indemnity from '@/views/broker/Signup/Documents/IndemnityInsurance.vue'
// import Membership from '@/views/broker/Signup/Documents/MembershipCertificate.vue'
// import Verification from '@/views/broker/Signup/MobileNoVerification.vue'
import ProgressAndSummary from '@/views/broker/Progress-Summary/index.vue';
import Summary from '@/views/broker/Progress-Summary/Summary/SummaryIndex.vue';
// import Decalaration from '@/views/broker/Signup/Declaration/Declaration.vue'
// import Agreement from '@/views/broker/Signup/Declaration/Agreement.vue'
// import Signature from '@/views/broker/Signup/Declaration/Signature.vue'
// import Success from '@/views/broker/Signup/SuccessPage.vue'
import KnowledgeBase from '@/views/broker/KnowledgeBase/index.vue';

// Referral component
// import ReferralName from '@/views/broker/ReferralSignup/PersonalDetails/Name.vue'
// import ReferralAddress from '@/views/broker/ReferralSignup/PersonalDetails/Address.vue'
// import ReferralEmail from '@/views/broker/ReferralSignup/PersonalDetails/Email.vue'
// import ReferralMobile from '@/views/broker/ReferralSignup/PersonalDetails/Mobile.vue'
// import ReferralProofOfIdentity from '@/views/broker/ReferralSignup/Documents/ProofOfIdentity.vue'
// import ReferralLicensing from '@/views/broker/ReferralSignup/Licensing.vue'
// import ReferralCompanyAddress from '@/views/broker/ReferralSignup/ContractDetails/CompanyAddress.vue'
// import ReferralPayment from '@/views/broker/ReferralSignup/PersonalDetails/Payment.vue'
// import ReferralVerification from '@/views/broker/ReferralSignup/MobileNoVerification.vue'
// import ReferralDecalaration from '@/views/broker/ReferralSignup/Declaration/Declaration.vue'
// import ReferralAgreement from '@/views/broker/ReferralSignup/Declaration/Agreement.vue'
// import ReferralSignature from '@/views/broker/ReferralSignup/Declaration/Signature.vue'
// import ReferralSignup from '@/views/broker/ReferralSignup/index.vue'

import { authGuard } from '@/services/Authentication/authGuard';

export const BrokerRoutes = [
  {
    path: '/',
    component: CusotmerComponent,
    children: [
      //     {
      //     path: 'login',
      //     component: Login,
      //     name: 'Login',
      //     beforeEnter: authGuard,
      // },
      {
        path: 'application',
        component: LoanComponent,
        name: 'Application',
        beforeEnter: authGuard,
        children: [
          {
            path: '',
            component: ApplicationComponent,
            name: 'ApplicationList',
          },
          {
            path: 'progress/:id',
            component: ProgressAndSummary,
            name: 'ProgressAndSummary',
          },
          {
            path: 'summary/:id',
            component: Summary,
            name: 'Summary',
          },
        ],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        name: 'Dashboard',
        beforeEnter: authGuard,
        children: [
          {
            path: 'servicing-calculator',
            component: ServicingCalculator,
            name: 'ServicingCalculator',
          },
          {
            path: '',
            component: DashboardMainComponent,
            name: 'DashboardMainComponent',
          },
        ],
      },
      {
        path: 'knowledge-hub',
        component: KnowledgeBase,
        name: 'KnowledgeBase',
        beforeEnter: authGuard,
      },
      {
        path: '/contact',
        component: ContactComponent,
        name: 'Contact',
        beforeEnter: authGuard,
      },
      {
        path: '/settings',
        component: SettingsComponent,
        name: 'Settings',
        beforeEnter: authGuard,
      },
    ],
  },
];

export default BrokerRoutes;
