import { API, TECHLEND_BE_URL, TECHLEND_CALCS_URL } from '@/constants.api';
import { _get, _post } from '@/services/crudService';
import lodash from 'lodash';

export default {
  namespaced: true,

  state: {
    income_count: 1,
    out_count: 1,
    onSave: false,
    serviceCalculator: {
      fees: {
        add_funds_requested: 0,
        totalSavingsToPurchase: 0,
        brokerFeeIncGst: 0.25,
        savings_end_debt: 0,
        loan_term: 0,
        monthly_liability_payment: 0,
      },
      applicantDetails: {
        // joint_application: false,
        applicant_2_relationship_with_applicant_1: '',
        application_expenses: 0,
        applicant_1_annual_income: 0,
        applicant_2_annual_income: 0,
        net_income_per_month: 0,
        applicant_1_relationship: '',
        applicant_2_relationship: '',
        new_loan_repayment_monthly: 0,
        applicant_1_dependants: '0',
        applicant_2_dependants: '0',
        total_commitments: 0,
      },
      incomingProperties: [
        {
          state: '',
          postCode: 0,
          purchasePrice: 0,
          stampDutyStatus: '',
          stampDuty: '0',
          settlementDate: '',
          // depositAmount: false,
          depositAmount: 0,
          propertyType: '',
          contract_sale: false,
          purchase_property_selected: true,
          postalcode_area_looking: 0,
          line1: '',
          line2: '',
          city: '',
          country: '',
          zone: '',
          category: '',
          premium: false,
          depositPaidType: 'cash',
          // new fields
          type_used: '',
          purchasePriceUsed: 0,
          // purchasePrice_avm: 0,
          // purchasePrice_fv: 0,
          // purchasePrice_cos: 0,
          // purchasePriceUsed_value: "application_value",
          // type_avm: '',
          // type_fv: '',
          // type_cos: '',
          // type_used_value: '',
          high_density_area: false,
        },
        {
          state: '',
          postCode: 0,
          purchasePrice: 0,
          stampDutyStatus: '',
          stampDuty: '0',
          settlementDate: '',
          // depositAmount: false,
          depositAmount: 0,
          propertyType: '',
          contract_sale: false,
          purchase_property_selected: true,
          postalcode_area_looking: 0,
          line1: '',
          line2: '',
          city: '',
          country: '',
          zone: '',
          category: '',
          premium: false,
          depositPaidType: 'cash',
          high_density_area: false,
        },
      ],
      outgoingProperties: [
        {
          state: '',
          postCode: 0,
          estimatedPropertyPrice: 0,
          propertyStatus: '',
          outstandingLoanAmount: 0,
          settlementDate: '',
          depositHeldInTrust: false,
          depositAmountReceived: 0,
          propertyType: '',
          line1: '',
          line2: '',
          city: '',
          country: '',
          zone: '',
          category: '',
          premium: false,
          institution: '',
          mortageAgainst: false,
          salesPrice: 0,
          // new fields
          type_used: '',
          purchasePriceUsed: 0,
          high_density_area: false,
          // purchasePrice_avm: 0,
          // purchasePrice_fv: 0,
          // purchasePrice_cos: 0,
          // purchasePriceUsed_value: "application_value",
          // type_avm: "",
          // type_fv: "",
          // type_cos: "",
          // type_used_value: "application_value"
        },
        {
          state: '',
          postCode: 0,
          estimatedPropertyPrice: 0,
          propertyStatus: '',
          outstanding_loanAmount: 0,
          settlementDate: '',
          depositHeldInTrust: false,
          depositAmountReceived: 0,
          propertyType: '',
          line1: '',
          line2: '',
          city: '',
          country: '',
          zone: '',
          category: '',
          premium: false,
          institution: '',
          mortageAgainst: false,
          salesPrice: 0,
          // new fields
          type_used: '',
          purchasePriceUsed: 0,
          high_density_area: false,
          // purchasePrice_avm: 0,
          // purchasePrice_fv: 0,
          // purchasePrice_cos: 0,
          // purchasePriceUsed_value: "application_value",
          // type_avm: "",
          // type_fv: "",
          // type_cos: "",
          // type_used_value: "application_value"
        },
      ],
      applicant_details: [
        {
          dob: '',
          age: 0,
        },
        {
          dob: '',
          age: 0,
        },
      ],
    },
    calculatorFields: {
      setup_fee_percentage: 0,
      qouted_setup_fee: 0,
    },
  },
  getters: {
    calculatorFields: ({ calculatorFields }) => calculatorFields[0],
    onSave: ({ onSave }) => onSave,
  },
  mutations: {
    resetCalculator(storeState) {
      storeState.serviceCalculator = {
        fees: {
          add_funds_requested: 0,
          totalSavingsToPurchase: 0,
          brokerFeeIncGst: 0.25,
          savings_end_debt: 0,
          loan_term: 0,
          monthly_liability_payment: 0,
        },
        applicantDetails: {
          applicant_2_relationship_with_applicant_1: '',
          application_expenses: 0,
          applicant_1_annual_income: 0,
          applicant_2_annual_income: 0,
          net_income_per_month: 0,
          applicant_1_relationship: '',
          applicant_2_relationship: '',
          new_loan_repayment_monthly: 0,
          applicant_1_dependants: '0',
          applicant_2_dependants: '0',
          total_commitments: 0,
        },
        incomingProperties: [
          {
            state: '',
            postCode: 0,
            purchasePrice: 0,
            stampDutyStatus: '',
            stampDuty: '0',
            settlementDate: '',
            depositAmount: 0,
            propertyType: '',
            contract_sale: false,
            purchase_property_selected: true,
            postalcode_area_looking: 0,
            line1: '',
            line2: '',
            city: '',
            country: '',
            zone: '',
            category: '',
            premium: false,
            depositPaidType: 'cash',
            type_used: '',
            purchasePriceUsed: 0,
          },
          {
            state: '',
            postCode: 0,
            purchasePrice: 0,
            stampDutyStatus: '',
            stampDuty: '0',
            settlementDate: '',
            depositAmount: 0,
            propertyType: '',
            contract_sale: false,
            purchase_property_selected: true,
            postalcode_area_looking: 0,
            line1: '',
            line2: '',
            city: '',
            country: '',
            zone: '',
            category: '',
            premium: false,
            depositPaidType: 'cash',
          },
        ],
        outgoingProperties: [
          {
            state: '',
            postCode: 0,
            estimatedPropertyPrice: 0,
            propertyStatus: '',
            outstanding_loanAmount: 0,
            settlementDate: '',
            depositHeldInTrust: false,
            depositAmountReceived: 0,
            propertyType: '',
            line1: '',
            line2: '',
            city: '',
            country: '',
            zone: '',
            category: '',
            premium: false,
            institution: '',
            mortageAgainst: false,
            salesPrice: 0,
            type_used: '',
            purchasePriceUsed: 0,
          },
          {
            state: '',
            postCode: 0,
            estimatedPropertyPrice: 0,
            propertyStatus: '',
            outstanding_loanAmount: 0,
            settlementDate: '',
            depositHeldInTrust: false,
            depositAmountReceived: 0,
            propertyType: '',
            line1: '',
            line2: '',
            city: '',
            country: '',
            zone: '',
            category: '',
            premium: false,
            institution: '',
            mortageAgainst: false,
            salesPrice: 0,
            type_used: '',
            purchasePriceUsed: 0,
          },
        ],
        applicant_details: [
          {
            dob: '',
            age: 0,
          },
          {
            dob: '',
            age: 0,
          },
        ],
      };
    },
    setFees(storeState, payload) {
      storeState.serviceCalculator.fees[payload.key] = payload.value;
    },
    setApplicantDetails(storeState, payload) {
      storeState.serviceCalculator.applicantDetails[payload.key] = payload.value;
    },
    setIncomingProperties(storeState, payload) {
      storeState.serviceCalculator.incomingProperties[payload.index][payload.key] = payload.value;
    },
    setOutgoingProperties(storeState, payload) {
      storeState.serviceCalculator.outgoingProperties[payload.index][payload.key] = payload.value;
    },
    setCalculatorFields(storeState, payload) {
      storeState.calculatorFields = payload;
    },
    setApplicant1Details(storeState, payload) {
      storeState.serviceCalculator.applicant_details[0] = payload;
    },
    setApplicant2Details(storeState, payload) {
      storeState.serviceCalculator.applicant_details[1] = payload;
    },
    async setFinalData(/*storeState*/) {
      // const payload = {
      //   incoming_properties:
      //     storeState.income_count == 0
      //       ? [storeState.serviceCalculator.incomingProperties[0]]
      //       : [...storeState.serviceCalculator.incomingProperties],
      //   outgoing_properties:
      //     storeState.out_count == 0
      //       ? [storeState.serviceCalculator.outgoingProperties[0]]
      //       : [...storeState.serviceCalculator.outgoingProperties],
      //   applicant_details: [...storeState.serviceCalculator.applicant_details],
      //   income_and_expenses: {
      //     ...storeState.serviceCalculator.applicantDetails,
      //   },
      //   // initial_amount_financed: 0,
      //   ...storeState.serviceCalculator.fees,
      // };
      // TODO: update with V2 API URL
      // return await _post(SERVER_URL_2 + "broker_sc_calc", payload);
    },
    setOutCount(storeState, payload) {
      storeState.out_count = payload;
    },
    setIncomeCount(storeState, payload) {
      storeState.income_count = payload;
    },
    setOnSave(storeState, payload) {
      storeState.onSave = payload;
    },
  },
  actions: {
    resetCalculator({ commit }) {
      commit('resetCalculator');
    },
    async sendReference(/*context, payload*/) {
      // TODO: udpate with V2 API URL
      // await _post(TECHLEND_BE_URL + API.REFER_A_CLIENT, payload);
    },
    async fetchEarnings() {
      // TODO: udpate with V2 API URL
      // return await _get(TECHLEND_BE_URL + API.REFERRAL_EARNING);
    },
    async fetchCalculatorFields({ commit }) {
      const result = await _get(`${TECHLEND_CALCS_URL}lookups/common_references`);
      commit('setCalculatorFields', result.data);
    },
    setFees({ commit }, payload) {
      commit('setFees', payload);
    },
    setApplicantDetails({ commit }, payload) {
      commit('setApplicantDetails', payload);
    },
    setIncomingProperties({ commit }, payload) {
      commit('setIncomingProperties', payload);
    },
    setOutgoingProperties({ commit }, payload) {
      commit('setOutgoingProperties', payload);
    },
    setApplicant1Details({ commit }, payload) {
      commit('setApplicant1Details', payload);
    },
    setApplicant2Details({ commit }, payload) {
      commit('setApplicant2Details', payload);
    },
    setOnSave({ commit }, payload) {
      commit('setOnSave', payload);
    },
    async fetchZoneByPostal(storeState, payload) {
      const res = await _get(`${TECHLEND_CALCS_URL}formulas/post_code?post_code=${payload}`);
      const { result } = res.data;
      return {
        zone: result?.sp_category.replace('-', '_').toLowerCase(),
        premium: result.premium ? result.premium : false,
        category: result.category ? result.category : '',
        high_density_area: result.high_density_area ? (result.high_density_area == 'true' ? true : false) : false,
      };
    },
    setIncomeCount({ commit }, payload) {
      commit('setIncomeCount', payload);
    },
    setOutCount({ commit }, payload) {
      commit('setOutCount', payload);
    },
    async fetchStampDuty(storeState, payload) {
      const res = await _post(`${TECHLEND_CALCS_URL}/formulas/stamp-duty`, payload);
      const { result } = res.data;
      return result ? result : 0;
    },
    async generateCalc({ state }) {
      const storeState = state;
      state.serviceCalculator.applicantDetails.applicant_1_dependants =
        state.serviceCalculator.applicantDetails.applicant_1_dependants == ''
          ? ''
          : state.serviceCalculator.applicantDetails.applicant_1_dependants;
      state.serviceCalculator.applicantDetails.applicant_2_dependants =
        state.serviceCalculator.applicantDetails.applicant_2_dependants == ''
          ? '0'
          : state.serviceCalculator.applicantDetails.applicant_2_dependants;
      const payload = {
        buyingProperties:
          storeState.income_count == 1
            ? [storeState.serviceCalculator.incomingProperties[0]]
            : [...storeState.serviceCalculator.incomingProperties],
        sellingProperties:
          storeState.out_count == 1
            ? [storeState.serviceCalculator.outgoingProperties[0]]
            : [...storeState.serviceCalculator.outgoingProperties],
        applicant_details: [...storeState.serviceCalculator.applicant_details],
        income_and_expenses: {
          ...storeState.serviceCalculator.applicantDetails,
        },
        ...storeState.serviceCalculator.fees,
      };
      let inputObj = lodash.cloneDeep(payload);
      inputObj.income_and_expenses.net_income_per_month = 0;
      inputObj.income_and_expenses.new_loan_repayment_monthly = 0;
      inputObj.income_and_expenses.application_expenses = 0;
      inputObj.brokerFeeIncGst = inputObj.brokerFeeIncGst / 100;
      if (inputObj.applicant_details && inputObj.applicant_details[1] && inputObj.applicant_details[1].dob == '') {
        inputObj.applicant_details = [inputObj.applicant_details[0]];
      }

      return await _post(TECHLEND_BE_URL + API.SERVICE_CALCULATOR, inputObj);
    },
    async generateApplication({ state /*rootState*/ }) {
      const storeState = state;
      const payload = {
        incoming_properties:
          storeState.income_count == 1
            ? [storeState.serviceCalculator.incomingProperties[0]]
            : [...storeState.serviceCalculator.incomingProperties],
        outgoing_properties:
          storeState.out_count == 1
            ? [storeState.serviceCalculator.outgoingProperties[0]]
            : [...storeState.serviceCalculator.outgoingProperties],
        applicant_details: [...storeState.serviceCalculator.applicant_details],
        income_and_expenses: {
          ...storeState.serviceCalculator.applicantDetails,
        },
        ...storeState.serviceCalculator.fees,
      };
      let input = lodash.cloneDeep(payload);
      input.brokerFeeIncGst = input.brokerFeeIncGst / 100;
      // const inputObj = {
      //   data: JSON.stringify(input),
      //   broker_id: rootState.settings.profileDetails.Id,
      // };
      // TODO: update with V2 API URL
      // return await _post(APP_SERVER_URL + "generate_apps_broker", inputObj);
    },
  },
};
