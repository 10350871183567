<template>
  <div class="full-width">
    <!-- <h1 class="pm-txt-color font-size-2_25 sec-title">Contact us</h1> -->
    <div class="row" :class="isXS ? 'flex-column' : ''">
      <div class="col col-4" :class="isXS ? 'col-12' : ''" v-for="(item, i) in items" :key="i">
        <Card :item="item" />
      </div>
    </div>
    <MessageComponent class="mt-4" />
  </div>
</template>

<script>
import Card from './Card.vue';
import MessageComponent from './Message.vue';
import Screensize from '@/mixins/screensize.mixin';
export default {
  name: 'index',
  mixins: [Screensize],
  components: {
    Card,
    MessageComponent,
  },
  data() {
    return {
      items: [
        {
          title: 'Give us a call',
          subheading: process.env.VUE_APP_CUSTOM_THEME_CONTACT ?? '1300 141 161',
          icon: 'call',
          content: 'Call us and have all your questions answered by our customer service rep! Weekdays 9:00AM - 5:30PM',
        },
        {
          title: 'Send us an email',
          subheading: process.env.VUE_APP_CUSTOM_THEME_EMAIL ?? 'hello@bridgit.com.au',
          icon: 'mail',
          content:
            'Send us any questions or concerns you have to the address above or use the form below to send an enquiry to our team',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '@/styles/global.scss';
</style>
