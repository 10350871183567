import { API } from '@/constants.api';
import { TECHLEND_BE_URL } from '@/constants.api';
import { _get, _patch, _post, _put } from '@/services/crudService';
import LogRocket from 'logrocket';

export default {
  namespaced: true,

  state: {
    profileDetails: {
      Id: '',
      accNo: '',
      accountName: '',
      aclNumber: '',
      aclRegistrationName: '',
      line1: '',
      line2: '',
      aggregationGroup: '',
      brokerFee: '',
      brokerGroupName: '',
      bsb: '',
      businessLine1: '',
      businessLine2: '',
      businessCity: '',
      businessCountry: '',
      businessPostCode: '',
      businessState: '',
      businessMobileNumber: '',
      city: '',
      country: '',
      crNumber: '',
      email: '',
      firstName: '',
      isMarketingMails: false,
      isStatementNotifications: false,
      lastName: '',
      licensingType: '',
      middleName: '',
      mobile: '',
      name: '',
      numberOfBrokers: '',
      otp: '',
      postCode: '',
      primaryBusinessActivity: '',
      proofDocExpDate: '',
      proofDocNumber: '',
      proofType: '',
      state: '',
      status: '',
      type: '',
    },
  },
  getters: {
    profileDetails: ({ profileDetails }) => profileDetails,
  },
  mutations: {
    setProfileDetails(state, payload) {
      state.profileDetails = payload;

      try {
        LogRocket.identify(state.profileDetails.id, {
          name: [state.profileDetails.firstName, state.profileDetails.lastName].filter(Boolean).join(' '),
          email: state.profileDetails.email,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  actions: {
    async updateNotification(storeState, payload) {
      await _patch(TECHLEND_BE_URL + API.USER.UPDATE_NOTIFICATION_SETTINGS, payload);
    },
    async updateProfile(storeState, payload) {
      await _patch(TECHLEND_BE_URL + API.USER.UPDATE_PERSONAL_INFORMATION, payload);
    },
    async getBrokerById({ commit }) {
      const result = await _get(TECHLEND_BE_URL + API.USER.CURRENT_USER);
      commit('setProfileDetails', result.data.broker);
    },
    async updatePaymentDetails(storeState, payload) {
      await _put(TECHLEND_BE_URL + API.USER.UPDATE_PAYMENT_INFORMATION, payload);
    },
    async sendMessage(context, payload) {
      await _post(TECHLEND_BE_URL + API.CONTACT.SEND_EMAIL, payload);
    },
  },
};
