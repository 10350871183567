export const TECHLEND_CALCS_URL = stripTrailingSlash(process.env.VUE_APP_TECHLEND_CALCS_URL);
export const TECHLEND_BROKER_URL = stripTrailingSlash(process.env.VUE_APP_TECHLEND_BROKER_URL);
export const TECHLEND_BE_URL = stripTrailingSlash(process.env.VUE_APP_TECHLEND_BE_URL);
export const TECHLEND_FE_URL = stripTrailingSlash(process.env.VUE_APP_TECHLEND_FE_URL);
export const LOAN_APPLICATION_URL = stripTrailingSlash(process.env.VUE_APP_LOAN_APPLICATION_URL);

export const API = {
  AUTHENTICATE: {
    REQUEST_OTP: '/brokers/authenticate/request-otp',
    VERIFY_OTP: '/brokers/authenticate/verify-otp',
  },
  APPLICATIONS: {
    RETRIEVE_APPLICATION: '/brokers/applications/application',
    LIST_APPLICATIONS: '/brokers/applications/list-applications',
  },
  CONTACT: {
    SEND_EMAIL: '/brokers/contact/send-email',
  },
  TASKS: {
    UPLOAD_SUPPORTING_DOCUMENTS: '/brokers/tasks/upload-supporting-documents',
    DELETE_SUPPORTING_DOCUMENT: '/brokers/tasks/delete-supporting-document',
    LIST_TASKS: '/brokers/tasks/list-tasks',
  },
  USER: {
    CURRENT_USER: '/brokers/user/current-user',
    UPDATE_NOTIFICATION_SETTINGS: '/users/user/update-notification-settings',
    UPDATE_PERSONAL_INFORMATION: '/users/user/update-personal-information',
    UPDATE_PAYMENT_INFORMATION: '/users/user/update-payment-information',
  },
  SOLICITORS: {
    SOLICITOR: '/brokers/solicitors/solicitor',
  },
  // NOTE: these endpoints are referenced in parts of the UI that were never used/tested
  AUTH: {
    REQUEST_OTP: '/brokers/register/request-otp',
    REGISTER_BROKER: '/brokers/auth/verify-otp',
    COMPLETE_REGISTRATION: '/brokers/register/complete-registration',
  },
  PUBLIC: {
    BROKER_GROUPS: '/public/broker-groups',
  },
  REFER_A_CLIENT: '/brokers/referrals',
  REFERRAL_EARNING: '/brokers/referrals',
  SERVICE_CALCULATOR: '/brokers/service-calculator',
  SUBMIT_DISCHARGE_REQUEST: '/brokers/tasks/submit-discharge-request',
};

function stripTrailingSlash(value) {
  if (!value || !value.endsWith('/')) {
    return value;
  }
  return value.substring(0, value.length - 1);
}
