<template>
  <div class="div mt-4">
    <!-- <h1 class="module-title">Applications</h1> -->
    <div class="progress-summary-container">
      <div class="header-wrapper flex align-items-center justify-content-between pr-4">
        <div class="header p-d-flex p-ai-center">
          <h3
            @click="changingTab('Progress')"
            class="title"
            :class="this.component === 'Progress' ? 'active' : 'lt-txt-color'"
          >
            Progress
          </h3>
          <h3
            @click="changingTab('Summary')"
            class="title"
            :class="this.component === 'Summary' ? 'active' : 'lt-txt-color'"
          >
            Summary
          </h3>
        </div>
        <div v-if="!isMD">
          <Button
            v-if="this.component === 'Summary'"
            class="mt-3"
            label="View loan progress"
            type="primary"
            iconPos="right"
            icon="pi pi-chevron-right"
            @click="toggleCmpStatus"
          />
        </div>
      </div>
      <!-- <Progress /> -->
      <!-- <Summary /> -->
      <component :is="component" @toggleCmp="toggleCmpStatus" :cmp="component"></component>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Progress from './Progress/index.vue';
import Screensize from '@/mixins/screensize.mixin';
import Summary from './Summary/index.vue';

export default {
  components: { Progress, Summary },
  // components: { Summary },
  mixins: [Screensize],
  data() {
    return {
      component: 'Progress',
    };
  },
  methods: {
    ...mapActions({
      setLoanReferenceNumber: 'applications/setLoanReferenceNumber',
    }),
    changingTab(cmp) {
      return (this.component = cmp);
    },
    toggleCmpStatus() {
      return (this.component = 'Progress');
    },
  },
  async created() {
    await this.setLoanReferenceNumber(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.progress-summary-container {
  font-family: 'HK-MEDIUM';
  background-color: $white-color;
  // display: inline-block;
  margin: 1rem 0;

  .header-wrapper {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #02003826;

    .header {
      gap: 2rem;
      padding: 0 1rem;

      .title {
        color: #adadad;
        cursor: pointer;
        font-size: 1.4rem;
        margin: 0;
        padding: 1rem 0 1.5rem 0;
      }
      .active {
        color: $primary-color;
        border-bottom: 3px solid $primary-color;
        font-weight: 500;
      }
    }
  }
}
</style>
