<template>
  <div class="bg authentication-wrapper-1">
    <div>
      <Header />
    </div>
    <div class="std-padding-rv">
      <div class="flex justify-content-center align-items-center report-content">
        <div>
          <div class="flex justify-content-center align-items-center">
            <img src="@/assets/signup-success.svg" alt="" />
          </div>
          <h4 class="text-field align-text-center module-title">Thank you for submitting your application!</h4>
          <div class="pt-1 application hk-grotest-medium">
            Your application is now being reviewed and you will be sent your login details shortly. <br />Our team will
            be in contact with you if there are any issues.
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../Login/Footer.vue';
import Header from '../Login/Header.vue';
export default {
  components: {
    Footer,
    Header,
  },
  methods: {},
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  color: $secondary-color !important;
  border-radius: 6px !important;
  height: 100% !important;
  font-family: HK Grotesk;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
}
.bg {
  background: #f1f6fb;
  height: 100vh;
}
:deep(.p-card-title) {
  padding: 1rem !important;
  color: $secondary-color !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
  color: $secondary-color !important;
  font-weight: 500;
  font-size: 14px;
}
:deep(.p-card-body) {
  padding: unset !important;
}
.label {
  color: $light-text-color;
  font-family: HK-semibold;
  font-size: 16px;
  white-space: nowrap;
}

.flex-gap {
  gap: 15px;
}

.report-content {
  // height: 90vh;
}
.text-field {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  font-size: 2rem;
  color: $secondary-color;
}

.title {
  font-size: 36px;
  font-family: HK-bold;
  color: $secondary-color;
}
.title-content {
  font-family: HK-semibold;
  font-size: 20px;
  color: $secondary-color;
}
.back {
  font-size: 1rem;
  color: rgba(2, 0, 56, 0.3);
}
:deep(.datepicker-layout .p-inputtext) {
  padding-left: 1rem !important;
}
.align-text-center {
  text-align: center;
}
.application {
  font-size: 1.125rem;
  color: $secondary-color;
  text-align: center;
  line-height: 1.5rem;
  margin-bottom: 1.075rem;
}
.authentication-wrapper-1 {
  background: linear-gradient(180deg, #eef4fa 0%, #ffffff 100%);
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}
</style>
