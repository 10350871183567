<template>
  <div class="hk-grotest-medium bold-600">
    <div class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Loan amount</p>
      <p class="text">${{ Math.round(selectedApplication.calculatedValue.loanAmount).toLocaleString() }}</p>
    </div>
    <div class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Loan term</p>
      <p class="text">{{ selectedApplication.calculatedValue.loanTermMonths }} months</p>
    </div>
    <div
      v-if="isPreSettlement && selectedApplication.calculatedValue.interestFreeTermMonths > 0"
      class="flex mt-3 align-items-center justify-content-between"
    >
      <p class="heading">Interest rate</p>
      <p class="text">
        {{ (interestRate * 100).toFixed(2) }}% (First
        {{ selectedApplication.calculatedValue.interestFreeTermMonths }} months free)
      </p>
    </div>
    <div v-else-if="isPreSettlement" class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Interest rate</p>
      <p class="text">{{ (interestRate * 100).toFixed(2) }}%</p>
    </div>
    <div class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Live repayment balance</p>
      <p class="text primary-color">$</p>
    </div>
    <div class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Date repaid</p>
      <p class="text">-</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      selectedApplication: 'applications/selectedApplication',
    }),
    isPreSettlement() {
      return !['loan_funded', 'loan_repaid'].includes(this.selectedApplication.applicationStatus);
    },
    interestRate() {
      const introRate = this.selectedApplication.assessmentRates.find(
        (rate) => rate.assessmentRateType === 'introductory_variable_interest_rate',
      );
      if (introRate) {
        return introRate?.assessmentRate;
      } else {
        const bridgingRate = this.selectedApplication.assessmentRates.find(
          (rate) => rate.assessmentRateType === 'bridging_variable_interest_rate',
        );
        return bridgingRate?.assessmentRate;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.heading {
  font-size: 14px;
  font-family: HK Grotesk;
  color: $light-text-color;
}

.text {
  font-family: HK Grotesk;
  color: $secondary-color;
  font-size: 14px;
}

p {
  margin-bottom: 0;
}
</style>
