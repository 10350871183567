export const NUMBERS_OBJ = {
  ZERO: 0,
  FOUR: 4,
  FIVE: 5,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  PLUS_SIXTY_ONE: +61,
  SIXTY_ONE: 61,
  TWELVE: 12,
};

export const TOKEN = 'broker-token';

export const AUTH_TYPES = {
  SIGNUP: 'signup',
  LOGIN: 'login',
};

// pre-prod url
export const ROUTES = {
  SIGNIN: '/login',
  DASHBOARD: '/dashboard',
  AUTH: '/login',
};

export const BROKER_LICENSE_TYPE = [
  {
    name: 'I am a Credit Representative (CR) of a holder of an Australian Credit Licence (ACL)',
    value: 'credit_representative_of_acl_holder',
  },
  {
    name: 'I have been issued with an ACL',
    value: 'acl_holder',
  },
  {
    name: 'I am a director or a holder of an ACL',
    value: 'director_or_acl_holder',
  },
  {
    name: 'I am an employee of a holder of an ACL',
    value: 'employee_of_acl_holder',
  },
];

export const STATES = ['NSW', 'VIC', 'WA', 'QLD', 'SA', 'ACT', 'TAS', 'NT'];

export const SUPPORTING_DOCUMENT_STATUS = {
  not_yet_started: 'Click to upload',
  in_progress: 'Submitted',
  completed: 'Completed',
};

export const SPECIAL_CONDITION_STATUS = {
  not_yet_started: 'Pending',
  in_progress: 'Pending',
  completed: 'Completed',
};

export const WRONG_PORTAL_ERROR = 'Unable to log you in, please call 1300 836 048 for assistance';

export const BRIDGIT_URL = 'https://www.bridgit.com.au';
