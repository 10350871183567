<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="hidden">
    <symbol id="arrow-left" viewBox="0 0 6 9" fill="currentColor">
      <path
        d="M5.99987 4.4903C5.99804 4.28876 5.90703 4.09575 5.74612 3.95214L1.5096 0.228648C1.34428 0.0827036 1.11977 0.000458704 0.885451 1.91287e-06C0.651135 -0.000454878 0.42621 0.080916 0.260156 0.226215C0.0941022 0.371514 0.000521923 0.568842 2.17655e-06 0.774783C-0.00051757 0.980723 0.0920656 1.17841 0.257384 1.32435L3.87057 4.5L0.257384 7.67565C0.0920656 7.82159 -0.000517561 8.01928 2.17655e-06 8.22522C0.000521915 8.43116 0.0941022 8.62848 0.260156 8.77378C0.42621 8.91907 0.651135 9.00045 0.885451 9C1.11977 8.99954 1.34428 8.9173 1.5096 8.77135L5.74612 5.04785C5.82845 4.9744 5.8933 4.88726 5.93686 4.79153C5.98043 4.6958 6.00185 4.59339 5.99987 4.4903Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="loan" viewBox="0 0 61 70" fill="none" stroke="currentColor">
      <g clip-path="url(#clip0_57_86841)">
        <path
          d="M41.4229 55.7288L45.5275 59.8426L52.2217 53.1484"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.5039 51.1283H4.36157C2.33219 51.1283 0.688477 49.4846 0.688477 47.4552V4.36059C0.688477 2.33121 2.33219 0.6875 4.36157 0.6875H37.6214C39.6508 0.6875 41.2945 2.33121 41.2945 4.36059V38.098"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.37451 9.375H22.8465"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.37451 15.168H33.6179"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6011 24.3984H33.0666"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6011 31.6875H33.0666"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6011 38.9883H33.0666"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.37451 24.3984H10.5141"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.37451 31.6875H10.5141"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.37451 38.9883H10.5141"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M45.877 9.88672H47.0799C49.1093 9.88672 50.753 11.5304 50.753 13.5598V38.4909"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.0813 60.3303H13.8196C11.7902 60.3303 10.1465 58.6866 10.1465 56.6572V55.6562"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.8225 69.3101C53.9023 69.3101 59.6416 63.5708 59.6416 56.491C59.6416 49.4112 53.9023 43.6719 46.8225 43.6719C39.7427 43.6719 34.0034 49.4112 34.0034 56.491C34.0034 63.5708 39.7427 69.3101 46.8225 69.3101Z"
          stroke="#54B7F9"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_57_86841">
          <rect width="60.3306" height="70" fill="white" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="todo-completed" viewBox="0 0 145 116" fill="none" stroke="currentColor">
      <path
        d="M36.1479 24.6948V8.37145C36.1479 5.60702 38.3858 3.36914 41.1503 3.36914H108.418C111.183 3.36914 113.421 5.60702 113.421 8.37145V94.9904C113.421 97.7549 111.183 99.9928 108.418 99.9928H41.1503C38.3858 99.9928 36.1479 97.7549 36.1479 94.9904V76.5609"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M119.081 18.377H124.083C126.848 18.377 129.086 20.6148 129.086 23.3793V109.998C129.086 112.763 126.848 115.001 124.083 115.001H56.8153C54.0509 115.001 51.813 112.763 51.813 109.998V107.365"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.806 69.1892C47.3479 69.1892 55.8938 60.6433 55.8938 50.1014C55.8938 39.5596 47.3479 31.0137 36.806 31.0137C26.2641 31.0137 17.7183 39.5596 17.7183 50.1014C17.7183 60.6433 26.2641 69.1892 36.806 69.1892Z"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.8105 43.3887L33.3833 56.8159L26.8013 50.3656"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.0762 16.6641H74.7182"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.0762 27.7227H99.2032"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.8174 40.4922H99.2031"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.8174 53.1289H99.2031"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.8174 65.7676H99.2031"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.8174 78.4043H99.2031"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.189 1V5.34411"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M128.427 3.10547H124.083"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.10596 54.5781V58.9222"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.34411 56.6836H1"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M141.196 95.6484V100.124"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M143.434 97.8867H139.09"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="dashboard" viewBox="0 0 32 25" fill="none" stroke="currentColor">
      <path
        d="M25.7836 12.0254V24.2547H6.52246V12.0254"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.81543 11.5151L15.6944 0.814453L31.1849 11.9227"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.6499 15.5918H13.3506V24.2542H18.6499V15.5918Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="application" viewBox="0 0 32 25" fill="none" stroke="currentColor">
      <path
        d="M27.3 1H5V18.3H27.3V1Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1001 23H20.1001"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1001 18.1992V22.9992"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 13.5H27.3"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="contact" viewBox="0 0 32 25" fill="none" stroke="currentColor">
      <path
        d="M22.6812 18.8646H24.607C26.441 18.8646 28 17.3974 28 15.4716V6.39301C28 4.55895 26.5328 3 24.607 3H10.393C8.55895 3 7 4.46725 7 6.39301V15.4716V22.2576L13.3275 18.8646H17.5459"
        stroke="#currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="settings" viewBox="0 0 32 25" fill="none" stroke="currentColor">
      <g clip-path="url(#clip0_319_68556)">
        <path
          d="M12.0693 4.78355L9.77922 2.99134L6.99134 5.77922L8.78355 8.06926C8.38528 8.76623 8.08658 9.4632 7.88745 10.1602L5 10.5584V14.5411L7.88745 14.9394C8.08658 15.6364 8.38528 16.3333 8.78355 17.0303L6.99134 19.2208L9.77922 22.0087L12.0693 20.2165C12.6667 20.6147 13.3636 20.9134 14.1602 21.1126L14.5584 24H18.5411L18.9394 21.1126C19.6364 20.9134 20.3333 20.6147 21.0303 20.2165L23.3203 22.0087L26.1082 19.2208L24.2165 16.9307C24.6147 16.3333 24.9134 15.6364 25.1126 14.8398L28 14.4416V10.4589L25.1126 10.0606C24.9134 9.36364 24.6147 8.66667 24.2165 7.9697L26.0087 5.67965L23.2208 2.99134L20.9307 4.78355C20.3333 4.38528 19.6364 4.08658 18.8398 3.88745L18.4416 1H14.4589"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5499 15.7356C18.3096 15.7356 19.7361 14.3091 19.7361 12.5494C19.7361 10.7898 18.3096 9.36328 16.5499 9.36328C14.7903 9.36328 13.3638 10.7898 13.3638 12.5494C13.3638 14.3091 14.7903 15.7356 16.5499 15.7356Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_319_68556">
          <rect width="32" height="24.9682" fill="white" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="faq" viewBox="0 0 32 25" fill="none" stroke="currentColor">
      <g clip-path="url(#clip0_319_68565)">
        <path
          d="M20.2338 23.2035C19.039 23.6017 17.7446 23.8009 16.4502 23.8009C10.0779 23.8009 5 18.7229 5 12.3507C5 5.97835 10.1775 1 16.5498 1C22.9221 1 28 6.07792 28 12.4502C28 15.3377 26.9048 17.9264 25.2121 19.9178"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.4634 8.86664C13.4634 8.86664 13.7621 6.67617 16.4504 6.47703C18.7404 6.2779 20.2339 7.57227 20.4331 10.0614C20.5326 11.7541 18.5413 13.2476 17.5456 13.4467C16.8487 13.6459 16.3508 14.2433 16.3508 15.0398V15.3385"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.251 18.0273V18.4256"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_319_68565">
          <rect width="32" height="24.9682" fill="white" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="app-sub-active" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9569 12.1379V5.25C21.9569 2.90517 20.0517 1 17.7069 1H5.25C2.75862 1 1 2.75862 1 5.25V30.75C1 33.0948 2.75862 35 5.25 35H17.8534C20.1983 35 22.1034 33.0948 22.1034 30.75V23.569"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.9569 7.1543H1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.9569 28.6992H1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.707 18H29.2846"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.3276 14.043L29.4311 18.1464L25.3276 22.2499"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9917 22.25H17.4141"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8706 13.1641H17.8534"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="app_sub_icon" viewBox="0 0 31 36" fill="none" stroke="currentColor">
      <g>
        <path
          d="M21.9569 12.1379V5.25C21.9569 2.90517 20.0517 1 17.7069 1H5.25C2.75862 1 1 2.75862 1 5.25V30.75C1 33.0948 2.75862 35 5.25 35H17.8534C20.1983 35 22.1034 33.0948 22.1034 30.75V23.569"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.9569 7.1543H1"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.9569 28.6992H1"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.707 18H29.2846"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.3276 14.043L29.4311 18.1464L25.3276 22.2499"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9917 22.25H17.4141"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.8706 13.1641H17.8534"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </symbol>
    <symbol id="conditonal_off_icon" viewBox="0 0 31 36" fill="none" stroke="currentColor">
      <path
        d="M25.4464 9.92704V6.21888L20.0901 1H16.382H1V32.8627L25.4464 33V30.6652"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0903 1V7.18026H24.7599"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.18018 10.6133H19.266"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.18018 15.832H14.5965"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.0171 17.8926L24.4849 22.4248L22.4248 20.3647"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.4463 27.3692C29.3905 27.3692 32.5879 24.1718 32.5879 20.2276C32.5879 16.2834 29.3905 13.0859 25.4463 13.0859C21.5021 13.0859 18.3047 16.2834 18.3047 20.2276C18.3047 24.1718 21.5021 27.3692 25.4463 27.3692Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="supporting_doc_icon" viewBox="0 0 28 34" fill="none" stroke="currentColor">
      <path
        d="M3.82352 27.3085H17.7512C19.2532 27.3085 20.6187 26.0796 20.6187 24.441V3.82255C20.6187 2.32054 19.3898 0.955078 17.7512 0.955078H3.82352C2.32152 0.955078 0.956055 2.18399 0.956055 3.82255V24.5776C1.0926 26.0796 2.32152 27.3085 3.82352 27.3085Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.51025 30.5849V31.131C7.51025 32.0869 8.32953 32.9061 9.28535 32.9061H25.1247C26.0805 32.9061 26.8998 32.0869 26.8998 31.131V8.32783C26.8998 7.37201 26.0805 6.55273 25.1247 6.55273H24.1689"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5542 7.91797H15.0201"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5542 12.1523H15.0201"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5542 16.5215H15.0201"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="unconditonal_off_icon" viewBox="0 0 32 34" fill="none" stroke="currentColor">
      <path
        d="M25.4464 9.92704V6.21888L20.0901 1H16.382H1V32.8627L25.4464 33V30.6652"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0903 1V7.18026H24.7599"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.18018 10.6133H19.266"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.18018 15.832H14.5965"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.6652 19.8145H20.3647V27.6428H30.6652V19.8145Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.4634 19.8148V16.7934C21.4634 14.596 23.2488 12.8105 25.4462 12.8105C27.6436 12.8105 29.429 14.596 29.429 16.7934V19.8148"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.4464 24.8966C26.0532 24.8966 26.5451 24.4047 26.5451 23.7979C26.5451 23.1911 26.0532 22.6992 25.4464 22.6992C24.8396 22.6992 24.3477 23.1911 24.3477 23.7979C24.3477 24.4047 24.8396 24.8966 25.4464 24.8966Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="loan_settle_active" viewBox="0 0 32 32" fill="none" stroke="currentColor">
      <path
        d="M23.9036 9.38076V5.91088L18.8915 1.02734H15.4217H1.02808V30.8426L23.9036 30.9711V28.7864"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8916 1.02734V6.81048H23.2611"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.81128 10.0234H18.1205"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.81128 14.9062H13.751"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.9036 12.3379C27.502 12.3379 30.5863 15.2937 30.5863 19.0206C30.5863 22.7475 27.6305 25.7034 23.9036 25.7034"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.9037 25.5744C20.3053 25.5744 17.2209 22.6186 17.2209 18.8917C17.2209 15.1648 20.1768 12.209 23.9037 12.209"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.2331 20.5609C22.2331 21.4605 23.1327 21.9745 24.1608 21.9745C25.1889 21.9745 25.703 21.2035 25.703 20.4324C25.703 19.6613 24.8034 19.1472 23.9038 19.1472C22.8757 19.1472 21.9761 18.3761 21.9761 17.4765C21.9761 16.5769 22.8757 16.1914 23.9038 16.1914C24.9319 16.1914 25.5745 16.7055 25.5745 17.348"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0322 16.0637V15.1641"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0322 22.7473V21.8477"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="uncomplete_task_icon" viewBox="0 0 108 112" fill="none" stroke="currentColor">
      <path
        d="M84.125 81.375V105.625C84.125 108.5 81.75 111 78.75 111H6.375C3.5 111 1 108.625 1 105.625V11.25C1 8.375 3.375 5.875 6.375 5.875H20.125"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.5 6H78.875C81.75 6 84.25 8.375 84.25 11.375V41.875"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.5 11.25H22.625C21.375 11.25 20.375 10.25 20.375 9V3.25C20.375 2 21.375 1 22.625 1H62.5C63.75 1 64.75 2 64.75 3.25V9C64.75 10.25 63.75 11.25 62.5 11.25Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.625 79.125L63.375 79.625L63.875 69.375L96.375 36.875L106.125 46.625L73.625 79.125Z"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.625 35.25H69.375"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.625 59.875H63.375"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.625 84.5H69.375"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 29.875H11.125V42.5H23.75V29.875Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 34.25L16.5 38.375L14.625 36.5"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 53.25H11.125V65.875H23.75V53.25Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 57.625L16.5 61.625L14.625 59.875"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 76.625H11.125V89.25H23.75V76.625Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 81L16.5 85L14.625 83.25"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="loan_summary_icon" viewBox="0 0 25 26" fill="none" stroke="currentColor">
      <g clip-path="url(#clip0_57_73278)">
        <path
          d="M18.5934 11.727V4.32045L14.813 0.617188H0.617188V23.1454H10.724"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.5934 4.32045L14.813 0.617188V4.32045H18.5934Z"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.552 7.63867H14.0416"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.552 11.6504H14.0416"
          stroke="#54B7F9"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.552 15.7383H8.40957"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.2819 23.8402C19.8811 23.8402 21.9881 21.7331 21.9881 19.134C21.9881 16.5348 19.8811 14.4277 17.2819 14.4277C14.6827 14.4277 12.5757 16.5348 12.5757 19.134C12.5757 21.7331 14.6827 23.8402 17.2819 23.8402Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.6765 22.6055L23.5311 25.3829"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_57_73278">
          <rect width="24.1484" height="26" fill="white" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="supporting_document" viewBox="0 0 23 27" fill="none" stroke="currentColor">
      <path
        d="M3.24335 21.6197H14.1408C15.316 21.6197 16.3844 20.6581 16.3844 19.3761V3.24359C16.3844 2.06838 15.4228 1 14.1408 1H3.24335C2.06813 1 0.999756 1.96154 0.999756 3.24359V19.4829C1.10659 20.6581 2.06813 21.6197 3.24335 21.6197Z"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.12802 24.1843V24.6116C6.12802 25.3595 6.76905 26.0005 7.51691 26.0005H19.9101C20.6579 26.0005 21.299 25.3595 21.299 24.6116V6.76975C21.299 6.02188 20.6579 5.38086 19.9101 5.38086H19.1622"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3799 6.44922H12.0038"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3799 9.75977H12.0038"
        stroke="#54B7F9"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3799 13.1797H12.0038"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
  </svg>
</template>
