<template>
  <div class="mt-4">
    <!-- <h1 class="module-title">Servicing Calculator</h1> -->
    <Spinner v-if="isLoading" class="flex" />
    <Card v-else class="mt-3">
      <template #content>
        <Fees @validateFees="validateFees" :setup_fee="setup_fee" :brokerFeeIncGst="brokerFeeIncGst" />
        <div class="divider-line m-3 mt-0"></div>
        <div :class="isMD ? '' : 'flex'">
          <IncomingProperties @validateIncoming="validateIncoming" class="col" :class="isMD ? '' : 'col-6'" />
          <OutgoingProperties @validateOutgoing="validateOutgoing" class="col" :class="isMD ? '' : 'col-6'" />
        </div>
        <div class="divider-line m-3"></div>
        <AdditionalInformation
          @validateApplicant="validateApplicant"
          class="mt-2"
          :total_commitments="total_commitments"
          :new_loan_repayment_monthly="new_loan_repayment_monthly"
          :hem_value="hem_value"
        />
        <div class="divider-line ml-4 mr-4"></div>
        <div class="module-sub-title ml-4 mt-3">Loan calculations</div>
        <div class="ml-3 mt-3" v-if="loanAmount.toString().length < 6" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-5'">
            <div class="sub-title">Loan amount</div>
            <div class="loan-amount-inactive">
              <span v-if="showValue" class="loan-amount-active">${{ Math.round(loanAmount).toLocaleString() }}</span>
              <span v-else>$0 </span>
            </div>
          </div>
          <div class="col" :class="loanAmount.toString().length > 6 ? 'col-8' : isMD ? 'col-12' : 'col-4'">
            <div class="sub-title">Net monthly surplus</div>
            <div class="loan-amount-inactive">
              <span v-if="showValue" class="loan-amount-active">${{ Math.round(netSurplus).toLocaleString() }}</span>
              <span v-else>$0 </span>
            </div>
          </div>

          <div class="col" :class="isMD ? 'col-12' : 'col-3'">
            <div class="sub-title">LVR</div>
            <div class="loan-amount-inactive">
              <span v-if="showValue && lvr != null" class="loan-amount-active"
                >{{ parseFloat(Number(lvr) * 100).toFixed(2) }}%</span
              >
              <span v-else-if="!showValue || lvr == null">0%</span>
            </div>
          </div>
        </div>
        <div class="ml-3 mt-3" v-else>
          <div class="col">
            <div class="sub-title">Loan amount</div>
            <div class="loan-amount-inactive">
              <span v-if="showValue" class="loan-amount-active">${{ Math.round(loanAmount).toLocaleString() }}</span>
              <span v-else>$0 </span>
            </div>
          </div>
          <div class="flex">
            <div class="col">
              <div class="sub-title">Net monthly surplus</div>
              <div class="loan-amount-inactive">
                <span v-if="showValue" class="loan-amount-active">${{ Math.round(netSurplus).toLocaleString() }}</span>
                <span v-else>$0 </span>
              </div>
            </div>

            <div class="col">
              <div class="sub-title">LVR</div>
              <div class="loan-amount-inactive">
                <span v-if="showValue && lvr != null" class="loan-amount-active"
                  >{{ parseFloat(Number(lvr) * 100).toFixed(2) }}%</span
                >
                <span v-else-if="!showValue || lvr == null">0%</span>
              </div>
            </div>
          </div>
        </div>
        <Button class="m-4" type="primary" label="Run calculator" @click="save" />
        <Button
          class="m-4"
          type="primary"
          label="Generate application"
          @click="createApplication"
          :disabled="
            isOutValidate || isInValidate || isFeeValidate || isApplicantValidate || isButton2Loading || loanAmount < 0
          "
        />
      </template>
    </Card>
  </div>
</template>

<script>
import { TECHLEND_FE_URL } from '@/constants.api';
import { mapActions, mapGetters } from 'vuex';
import AdditionalInformation from './AdditionalInformation.vue';
import Card from 'primevue/card';
import Fees from './Fees.vue';
import IncomingProperties from './IncomingProperties.vue';
import OutgoingProperties from './OutgoingProperties.vue';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  name: 'ServicingCalculator',
  mixins: [ScreenSize],
  components: {
    AdditionalInformation,
    IncomingProperties,
    Card,
    OutgoingProperties,
    Fees,
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  data() {
    return {
      lvr: 0,
      loanAmount: -1.1,
      isLoading: true,
      netSurplus: 0,
      isOutValidate: true,
      isInValidate: true,
      isFeeValidate: true,
      isApplicantValidate: true,
      isButtonLoading: false,
      isButton2Loading: false,
      showValue: false,
      setup_fee: '',
      brokerFeeIncGst: 0.25,
      total_commitments: '',
      new_loan_repayment_monthly: '',
      hem_value: '',
    };
  },
  methods: {
    ...mapActions({
      generateCalc: 'dashboard/generateCalc',
      fetchCalculatorFields: 'dashboard/fetchCalculatorFields',
      setOnSave: 'dashboard/setOnSave',
      generateApplication: 'dashboard/generateApplication',
      resetCalculator: 'dashboard/resetCalculator',
    }),
    validateOutgoing(value) {
      this.isOutValidate = value;
    },
    validateIncoming(value) {
      this.isInValidate = value;
    },
    validateFees(value) {
      this.isFeeValidate = value;
    },
    validateApplicant(value) {
      this.isApplicantValidate = value;
    },
    async save() {
      this.setOnSave(true);

      alert(1);

      if (
        this.isOutValidate ||
        this.isInValidate ||
        this.isFeeValidate ||
        this.isApplicantValidate ||
        this.isButtonLoading
      ) {
        return;
      }
      try {
        this.isButtonLoading = true;

        const result = await this.generateCalc();
        if (result) {
          this.lvr = result.data.initialLvr;
          this.netSurplus = result.data.netLoanRepaymentMonthly;
          this.loanAmount = result.data.mortgagePayoutEstimate;
          this.setup_fee = result.data.setupFee;
          this.brokerFeeIncGst = result.data.brokerFeeIncGst;

          if (result.data.applicant_1_hem) this.total_commitments = result.data.total_commitments || '';
          this.new_loan_repayment_monthly = result.data.netLoanRepaymentMonthly;
          this.hem_value = Number(result.data.applicant_1_hem) + Number(result.data.applicant_2_hem);
        }
        this.isButtonLoading = false;
        this.showValue = true;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
        this.isButtonLoading = false;
      }
    },
    async createApplication() {
      try {
        this.isButton2Loading = true;
        const result = await this.generateApplication();
        this.isButton2Loading = false;
        window.open(
          `${TECHLEND_FE_URL}application/buying/loan-purpose?appId=` +
            result.data.Id +
            '&brokerId=' +
            window.btoa(this.profileDetails.Id),
          '_blank',
        );
      } catch (err) {
        console.log(err, 'err msg');
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
        this.isButton2Loading = false;
      }
    },
  },
  async created() {
    this.resetCalculator();
    this.setOnSave(false);

    try {
      this.isLoading = true;
      await this.fetchCalculatorFields();
      this.isLoading = false;
    } catch (err) {
      this.$toast.add({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-content) {
  font-size: 1rem !important;
}
.divider-line {
  border-top: 1px solid rgba(2, 0, 56, 0.15);
}
.loan-amount-inactive {
  font-weight: bold;
  font-size: 60px;
  color: rgba(2, 0, 56, 0.2);
}
.loan-amount-active {
  color: $primary-color;
}
.sub-title {
  font-weight: 600;
}
</style>
