<template>
  <Card class="card-wrapper">
    <template #title>
      <div class="pm-txt-color title">
        {{ item.title }}
      </div>
    </template>
    <template #content>
      <img width="80" height="80" class="mb-2" :src="require(`@/assets/${item.icon}.svg`)" />
      <div class="mt-2 font-size-1 pm-txt-color">{{ item.subheading }}</div>
      <div class="mt-2 font-size-1 pm-txt-color">{{ item.content }}</div>
    </template>
  </Card>
</template>

<script>
import Card from 'primevue/card';
export default {
  props: ['item'],
  components: {
    Card,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-size: 1.25rem;
}

.p-card {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  padding: 1.875rem 0;
  height: 100%;
}
:deep(.p-card-title) {
  text-align: center !important;
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  text-align: center !important;
  font-size: 14px !important;
}
img {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
</style>
