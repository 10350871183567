<template>
  <iframe :src="src" :style="{ height: iframeHeight + 'px' }" @load="onIframeLoad" frameborder="0"></iframe>
</template>

<script>
export default {
  name: 'Jotform',
  props: {
    src: {
      type: String,
      required: true,
    },
    initialHeight: {
      type: Number,
      default: 1100,
    },
  },
  data() {
    return {
      iframeHeight: this.initialHeight,
    };
  },
  methods: {
    onIframeLoad() {
      window.addEventListener('message', this.handleIframeMessage, false);
    },
    handleIframeMessage(event) {
      if (typeof event.data === 'string' && event.data.includes('setHeight:')) {
        const [, height] = event.data.split(':');
        const newHeight = parseInt(height, 10);

        if (isNaN(newHeight)) return;

        this.iframeHeight = newHeight;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleIframeMessage);
  },
};
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
}
</style>
