const currencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const numberFormatter = new Intl.NumberFormat('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const integerFormatter = new Intl.NumberFormat('en-AU', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
const dateFormatter = new Intl.DateTimeFormat('en-AU');

export const formatCurrency = (val) => currencyFormatter.format(val ?? 0);
export const formatNumber = (val) => numberFormatter.format(val ?? 0);
export const formatInt = (val) => integerFormatter.format(val ?? 0);
export const formatDate = (val) =>
  !val ? '-' : val instanceof Date ? dateFormatter.format(val) : dateFormatter.format(new Date(val));

/**
 * Converts an ENUM value of the format "some_string" to a presentable
 * format "Some string", with a special exception for Contract of Sale
 * abbreviations becoming uppercase
 * @param {*} val
 * @returns
 */
export const fromSnakeCase = (val) => {
  if (!val) return '';
  const sentence = val.replace(/_/g, ' ').trim();
  return (sentence.charAt(0).toUpperCase() + sentence.slice(1)).replace(' cos ', ' COS ');
};

export const formatAddress = (address) => {
  const location = [address.city, address.state?.toUpperCase(), address.postCode].filter(Boolean).join(' ');
  return [address.line1, address.line2, location].filter(Boolean).join(', ');
};
