<template>
  <div>
    <span class="p-input-icon-left position-relative">
      <i class="pi pi-filter" @click="showList = !showList" />
      <InputText @click="showList = !showList" type="text" placeholder="Filter applications" readonly />
    </span>
    <Card v-if="showList" class="filter-style">
      <template #content>
        <div class="option-title">Filter by application status</div>
        <SingleCheckbox label="Open" id="Open" :value="formData.open" @getInputValue="getFormData($event, 'open')" />
        <SingleCheckbox
          label="Repaid"
          id="Repaid"
          :value="formData.repaid"
          @getInputValue="getFormData($event, 'repaid')"
        />
        <SingleCheckbox
          label="Dropped off"
          id="Droppedoff"
          :value="formData.droppedOff"
          @getInputValue="getFormData($event, 'droppedOff')"
        />
        <SingleCheckbox
          label="Withdrawn"
          id="Withdrawn"
          :value="formData.withdrawn"
          @getInputValue="getFormData($event, 'withdrawn')"
        />
        <SingleCheckbox
          label="Rejected"
          id="Rejected"
          :value="formData.rejected"
          @getInputValue="getFormData($event, 'rejected')"
        />
        <div class="mt-3 option-title">Filter by loan stage</div>
        <SingleCheckbox
          label="Application submission"
          id="Applicationsubmission"
          :value="created"
          @getInputValue="getForm2Data($event, 'created')"
        />
        <SingleCheckbox
          label="Conditional offer"
          id="Conditionaloffer"
          :value="conditional_offer"
          @getInputValue="getForm2Data($event, 'conditional_offer')"
        />
        <SingleCheckbox
          label="Supporting documents"
          id="Supportingdocuments"
          :value="supporting_documents"
          @getInputValue="getForm2Data($event, 'supporting_documents')"
        />
        <SingleCheckbox
          label="Unconditional offer"
          id="Unconditionaloffer"
          :value="unconditional_offer"
          @getInputValue="getForm2Data($event, 'unconditional_offer')"
        />
        <SingleCheckbox
          label="Loan settlement"
          id="Loansettlement"
          :value="loan_settlement"
          @getInputValue="getForm2Data($event, 'loan_settlement')"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';

export default {
  name: 'Filter',
  components: {
    Card,
    InputText,
  },
  data() {
    return {
      formData: {
        open: false,
        repaid: false,
        droppedOff: false,
        withdrawn: false,
        rejected: false,
      },
      showList: false,
      form2Data: {
        created: false,
        conditional_offer: false,
        supporting_documents: false,
        unconditional_offer: false,
        loan_settlement: false,
      },
    };
  },
  methods: {
    getFormData(value, field) {
      this.formData[field] = value;
      this.$emit('status', this.formData);
    },
    getForm2Data(value, field) {
      this.form2Data[field] = value;
      this.$emit('stage', this.form2Data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  min-height: 282px !important;
}

:deep(.p-card-content) {
  font-size: 15px !important;
}
.filter-style {
  position: absolute !important;
  z-index: 10;
  width: 240px;
}
.option-title {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 14px;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem #ced4da !important;
  border-color: #ced4da !important;
}
:deep(.p-card .p-card-body) {
  padding: 1rem;
  padding-bottom: 0rem;
  margin-bottom: -0.5rem;
}
</style>
