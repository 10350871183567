<template>
  <div>
    <Card v-if="!isLoading" class="notification-wrapper">
      <template #content>
        <div>
          <div class="title-text">Please enter the details of the account you would like to receive payment.</div>
          <TextField
            class="mt-4"
            width="100%"
            placeholder="Your name as registered on the account"
            label="Account name"
            @onInput="getFormData($event, 'bankAccountName')"
            :value="formData.bankAccountName"
          />
          <div :class="isMD ? 'mt-3' : 'flex mt-4'">
            <TextField
              class="col"
              width="100%"
              placeholder="Your BSB code"
              label="BSB"
              @onInput="getFormData($event, 'bankBSBNumber')"
              :value="formData.bankBSBNumber"
              type="number"
            />
            <TextField
              :class="isMD ? 'mt-3 mb-2' : 'ml-4'"
              class="col"
              width="100%"
              placeholder="Your account number"
              label="Account number"
              @onInput="getFormData($event, 'bankAccountNumber')"
              :value="formData.bankAccountNumber"
              type="number"
            />
          </div>
          <div @click="save">
            <Button :disabled="isSubmitDisabled()" class="mt-3" label="Save changes" type="primary" />
          </div>
        </div>
      </template>
    </Card>
    <Spinner class="flex align-items-center" v-else />
  </div>
</template>

<script>
import { integer, maxLength, minLength, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Card from 'primevue/card';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  name: 'Payment',
  mixins: [ScreenSize],
  components: {
    Card,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        accountName: { required },
        bsbNumber: {
          required,
          integer,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
        accountNumber: {
          required,
          integer,
          greaterThanZero(value) {
            return parseInt(value) > 0;
          },
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      formData: {
        accountName: '',
        bsbNumber: '',
        accountNumber: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  methods: {
    ...mapActions({
      updatePaymentDetails: 'settings/updateProfile',
      getBrokerById: 'settings/getBrokerById',
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },

    setFormData() {
      for (const [key, value] of Object.entries(this.profileDetails)) {
        this.formData[key] = value;
      }
      this.stateKey += 1;
      this.countryKey += 1;
    },

    isSubmitDisabled() {
      return this.v$.$invalid;
    },

    async save() {
      try {
        if (!this.v$.$invalid) {
          await this.updatePaymentDetails(this.formData);
          await this.getBrokerById();
          this.$toast.add({
            severity: 'success',
            summary: 'Payment details updated successfully',
            life: 2000,
          });
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.getBrokerById();
      await this.setFormData();
      this.isLoading = false;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: err.response.data.message,
          life: 3000,
        });
      } else {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong',
          life: 3000,
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.notification-wrapper {
  padding: 1.875rem;
}
.title-text {
  color: rgba(2, 0, 56, 0.3);
  font-size: 1rem !important;
}

:deep(.p-card-body) {
  padding: 0 !important;
}

.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $secondary-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
}
.col {
  padding: unset;
}
</style>
