<template>
  <div class="welcome-container">
    <iframe
      :src="bookingUrl"
      name="booking"
      scrolling="no"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      height="100%"
      referrerpolicy="unsafe-url"
      allowfullscreen
      style="border: none; min-height: 700px; width: 100%; min-width: 100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  computed: {
    bookingUrl() {
      return process.env.VUE_APP_CUSTOM_THEME_WELCOME_BOOKING_URL ?? 'https://letsmeet.io/bridgit6/welcome-bridgit';
    },
  },
};
</script>

<style lang="scss" scoped></style>
