<template>
  <Card>
    <template #title>
      <div class="flex full-width">
        <div class="module-sub-title col" :class="isMD ? '' : 'col-8'">Income and expenses</div>
        <div class="col flex justify-content-between full-width">
          <div>Applicants</div>
          <div>
            <span>
              <img v-if="count === 2" src="@/assets/minus.svg" @click="count = 1" />
              <img v-else src="@/assets/minus-inactive.svg" />
            </span>
            <span class="pl-3 pr-3">{{ count }}</span>
            <span>
              <img v-if="count === 1" src="@/assets/plus.svg" @click="count = 2" />
              <img v-else src="@/assets/plus-inactive.svg" />
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="justify-content-between" :class="isMD ? '' : 'flex'">
        <div class="col" :class="isMD ? '' : 'col-8'" v-if="count == 1">
          <div>Applicant 1 relationship<span class="required-class">*</span></div>
          <Dropdown
            placeholder="-Select-"
            width="100%"
            optionLabel="name"
            @change="getFormData($event.value, 'applicant_1_relationship')"
            :inValid="count == 1 && v$.formData.applicant_1_relationship.$invalid && onSave"
            :items="relationshipStatus"
          />
        </div>
        <div class="col" :class="isMD ? '' : 'col-8'" v-if="count == 2">
          <div>Relationship between applicants<span class="required-class">*</span></div>
          <Dropdown
            placeholder="-Select-"
            width="100%"
            optionLabel="name"
            @change="getFormData($event.value, 'applicant_2_relationship_with_applicant_1')"
            :inValid="count == 2 && v$.formData.applicant_2_relationship_with_applicant_1.$invalid && onSave"
            :items="relationList"
            :value="formData.applicant_2_relationship_with_applicant_1"
          />
        </div>
        <div class="col" :class="isMD ? '' : 'col-4'">
          <img v-if="count === 2" class="mt-4" src="@/assets/refer-client.svg" />
          <img v-else class="mt-4" src="@/assets/refer-client-1.svg" />
        </div>
      </div>
      <!-- Two applicants -->
      <div v-if="count === 2 && formData.applicant_2_relationship_with_applicant_1 !== 'married'">
        <div class="justify-content-between" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Applicant 1 relationship<span class="required-class">*</span></div>
            <Dropdown
              placeholder="-Select-"
              width="100%"
              optionLabel="name"
              @change="getFormData($event.value, 'applicant_1_relationship')"
              :items="relationshipStatus"
              :inValid="v$.formData.applicant_1_relationship.$invalid && onSave"
              :value="formData.applicant_1_relationship"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Applicant 2 relationship<span class="required-class">*</span></div>
            <Dropdown
              placeholder="-Select-"
              :items="relationshipStatusFor2"
              optionLabel="name"
              width="100%"
              @change="getFormData($event.value, 'applicant_2_relationship')"
              :inValid="v$.formData.applicant_2_relationship.$invalid && onSave"
              :value="formData.applicant_2_relationship"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Application monthly expenses</div>
            <InputWithIcon
              placeholder="TBD"
              width="100%"
              :format="true"
              :icon="dollar"
              @onInput="getFormData($event ? Number($event) : 0, 'application_expenses')"
              :value="formData.application_expenses"
              :disable="true"
              :key="hemKey"
              mode="decimal"
              type="number"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Applicant 1 annual income</div>
            <InputWithIcon
              placeholder="Enter amount"
              width="100%"
              :format="true"
              :icon="dollar"
              @onInput="getFormData($event ? Number($event) : 0, 'applicant_1_annual_income')"
              type="number"
              :inValid="v$.formData.applicant_1_annual_income.$invalid && onSave"
              :value="formData.applicant_1_annual_income"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Applicant 2 annual income</div>
            <InputWithIcon
              placeholder="Enter amount"
              width="100%"
              :format="true"
              :icon="dollar"
              @onInput="getFormData($event ? Number($event) : 0, 'applicant_2_annual_income')"
              type="number"
              :inValid="v$.formData.applicant_2_annual_income.$invalid && onSave"
              :value="formData.applicant_2_annual_income"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Net income per month</div>
            <InputWithIcon
              placeholder="Enter amount"
              :icon="dollar"
              width="100%"
              class="disable-input"
              @onInput="getFormData($event ? Number($event) : 0, 'net_income_per_month')"
              type="number"
              :value="formData.net_income_per_month"
              :disable="true"
              :key="netIncomeKey"
              :format="true"
              mode="decimal"
              :inValid="v$.formData.net_income_per_month.$invalid && onSave"
              :minFractionDigits="2"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div class="col margin-top" :class="isMD ? '' : 'col-4'">
            <div>Applicant 1 date of birth<span class="required-class">*</span></div>
            <DatePicker
              placeholder="DD/MM/YYYY"
              @change="getApplicant1Data($event, 'applicant_1_dob')"
              :inValid="v$.formData.applicant_1_dob.$invalid && onSave"
              yearRange="1937:2022"
            />
            <span v-if="age1 !== '' && (age1 == 0 || age1 < 18)" class="error">Age should be atleast 18 years</span>
          </div>
          <div class="col" :class="isMD ? '' : 'col-4 margin-top'">
            <div>Applicant 2 date of birth<span class="required-class">*</span></div>
            <DatePicker
              placeholder="DD/MM/YYYY"
              @change="getApplicant2Data($event, 'applicant_2_dob')"
              :inValid="v$.formData.applicant_2_dob.$invalid && onSave"
              yearRange="1937:2022"
            />
            <span v-if="age2 !== '' && (age2 == 0 || age2 < 18)" class="error">Age should be atleast 18 years</span>
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>New loan repayment monthly</div>
            <InputWithIcon
              placeholder="TBD"
              :format="true"
              mode="decimal"
              type="number"
              :icon="dollar"
              class="disable-input"
              :disable="true"
              width="100%"
              @onInput="getFormData($event ? Number($event) : 0, 'new_loan_repayment_monthly')"
              :value="formData.new_loan_repayment_monthly"
              :inValid="v$.formData.new_loan_repayment_monthly.$invalid && onSave"
              :key="repaymentKey"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-4 margin-top'">
            <div>Applicant 1 dependants<span class="required-class">*</span></div>
            <Dropdown
              placeholder="-Select-"
              width="100%"
              @change="getFormData($event ? $event.value : '', 'applicant_1_dependants')"
              optionLabel="name"
              :items="dependents"
              :inValid="v$.formData.applicant_1_dependants.$invalid && onSave"
              :value="formData.applicant_1_dependants"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4 margin-top'">
            <div>Applicant 2 dependants<span class="required-class">*</span></div>
            <Dropdown
              placeholder="-Select-"
              width="100%"
              @change="getFormData($event ? $event.value : '', 'applicant_2_dependants')"
              :items="dependents"
              optionLabel="name"
              :inValid="v$.formData.applicant_2_dependants.$invalid && onSave"
              :value="formData.applicant_2_dependants"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Total commitments</div>
            <InputWithIcon
              :disable="true"
              placeholder="TBD"
              class="disable-input"
              :icon="dollar"
              width="100%"
              :value="total_commitments"
              :key="commitMentsKey"
              mode="decimal"
              :format="true"
              type="number"
            />
          </div>
        </div>
      </div>

      <div v-if="count === 2 && formData.applicant_2_relationship_with_applicant_1 === 'married'">
        <div class="justify-content-between" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Applicant 1 annual income</div>
            <InputWithIcon
              placeholder="Enter amount"
              width="100%"
              :format="true"
              :icon="dollar"
              @onInput="getFormData($event ? Number($event) : 0, 'applicant_1_annual_income')"
              type="number"
              :inValid="v$.formData.applicant_1_annual_income.$invalid && onSave"
              :value="formData.applicant_1_annual_income"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Applicant 2 annual income</div>
            <InputWithIcon
              placeholder="Enter amount"
              width="100%"
              :format="true"
              :icon="dollar"
              @onInput="getFormData($event ? Number($event) : 0, 'applicant_2_annual_income')"
              type="number"
              :inValid="count === 2 && v$.formData.applicant_2_annual_income.$invalid && onSave"
              :value="formData.applicant_2_annual_income"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Application monthly expenses</div>
            <InputWithIcon
              placeholder="TBD"
              width="100%"
              :format="true"
              :icon="dollar"
              @onInput="getFormData($event ? Number($event) : 0, 'application_expenses')"
              :value="formData.application_expenses"
              :disable="true"
              class="disable-input"
              :key="hemKey"
              mode="decimal"
              type="number"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div class="col margin-top" :class="isMD ? '' : 'col-4'">
            <div>Applicant 1 date of birth<span class="required-class">*</span></div>
            <DatePicker
              placeholder="DD/MM/YYYY"
              @change="getApplicant1Data($event, 'applicant_1_dob')"
              :inValid="count === 2 && v$.formData.applicant_1_dob.$invalid && onSave"
              yearRange="1937:2022"
            />
            <span v-if="age1 !== '' && (age1 == 0 || age1 < 18)" class="error">Age should be atleast 18 years</span>
          </div>
          <div class="col" :class="isMD ? '' : 'col-4 margin-top'">
            <div>Applicant 2 date of birth<span class="required-class">*</span></div>
            <DatePicker
              placeholder="DD/MM/YYYY"
              @change="getApplicant2Data($event, 'applicant_2_dob')"
              :inValid="count === 2 && v$.formData.applicant_2_dob.$invalid && onSave"
              yearRange="1937:2022"
            />
            <span v-if="age2 !== '' && (age2 == 0 || age2 < 18)" class="error">Age should be atleast 18 years</span>
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Net income per month</div>
            <InputWithIcon
              placeholder="Enter amount"
              :icon="dollar"
              width="100%"
              class="disable-input"
              @onInput="getFormData($event ? Number($event) : 0, 'net_income_per_month')"
              type="number"
              :value="formData.net_income_per_month"
              :disable="true"
              :key="netIncomeKey"
              :format="true"
              mode="decimal"
              :inValid="count === 2 && v$.formData.net_income_per_month.$invalid && onSave"
              :minFractionDigits="2"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-8 margin-top'">
            <div>Total number of dependants<span class="required-class">*</span></div>
            <Dropdown
              placeholder="-Select-"
              width="100%"
              @change="getFormData($event ? $event.value : '', 'applicant_1_dependants')"
              :items="dependents"
              optionLabel="name"
              :inValid="count === 2 && v$.formData.applicant_1_dependants.$invalid && onSave"
              :value="formData.applicant_1_dependants"
            />
          </div>
          <!-- <div class="col" :class="isMD ? '' : 'col-4 margin-top'">
            <div>Applicant 2 dependants</div>
            <Dropdown
              placeholder="-Select-"
              width="100%"
              @change="getFormData($event, 'applicant_2_dependants')"
              :items="dependents"
              :value="formData.applicant_2_dependants"
            />
          </div> -->
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>New loan repayment monthly</div>
            <InputWithIcon
              placeholder="TBD"
              :format="true"
              :icon="dollar"
              class="disable-input"
              :disable="true"
              mode="decimal"
              type="number"
              width="100%"
              @onInput="getFormData($event ? Number($event) : 0, 'new_loan_repayment_monthly')"
              :inValid="count === 2 && v$.formData.new_loan_repayment_monthly.$invalid && onSave"
              :value="formData.new_loan_repayment_monthly"
              :key="repaymentKey"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div v-if="!isMD" class="col" :class="isMD ? '' : 'col-8'"></div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Total commitments</div>
            <InputWithIcon
              :disable="true"
              placeholder="TBD"
              class="disable-input"
              :icon="dollar"
              width="100%"
              :value="total_commitments"
              :key="commitMentsKey"
              mode="decimal"
              type="number"
              :format="true"
            />
          </div>
        </div>
      </div>

      <!-- One applicant -->
      <div v-if="count === 1">
        <div class="justify-content-between" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-8'">
            <div>Applicant 1 annual income<span class="required-class">*</span></div>
            <InputWithIcon
              placeholder="Enter amount"
              width="100%"
              :format="true"
              :icon="dollar"
              @onInput="getFormData($event ? Number($event) : 0, 'applicant_1_annual_income')"
              :inValid="count === 1 && v$.formData.applicant_1_annual_income.$invalid && onSave"
              type="number"
              :value="formData.applicant_1_annual_income"
            />
          </div>

          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Application monthly expenses</div>
            <InputWithIcon
              placeholder="TBD"
              width="100%"
              :format="true"
              :icon="dollar"
              @onInput="getFormData($event ? Number($event) : 0, 'application_expenses')"
              :value="formData.application_expenses"
              :disable="true"
              class="disable-input"
              :key="hemKey"
              mode="decimal"
              type="number"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div class="col margin-top" :class="isMD ? '' : 'col-8'">
            <div>Applicant 1 date of birth<span class="required-class">*</span></div>
            <DatePicker
              placeholder="DD/MM/YYYY"
              @change="getApplicant1Data($event, 'applicant_1_dob')"
              :inValid="count === 1 && v$.formData.applicant_1_dob.$invalid && onSave"
              yearRange="1937:2022"
            />
            <span v-if="age1 !== '' && (age1 == 0 || age1 < 18)" class="error">Age should be atleast 18 years</span>
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Net income per month</div>
            <InputWithIcon
              placeholder="Enter amount"
              :icon="dollar"
              width="100%"
              :inValid="count === 1 && v$.formData.net_income_per_month.$invalid && onSave"
              class="disable-input"
              @onInput="getFormData($event ? Number($event) : 0, 'net_income_per_month')"
              type="number"
              :value="formData.net_income_per_month"
              :disable="true"
              :key="netIncomeKey"
              :format="true"
              mode="decimal"
              :minFractionDigits="2"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-8 margin-top'">
            <div>Applicant 1 dependants<span class="required-class">*</span></div>
            <Dropdown
              placeholder="-Select-"
              :inValid="count === 1 && v$.formData.applicant_1_dependants.$invalid && onSave"
              width="100%"
              @change="getFormData($event ? $event.value : '', 'applicant_1_dependants')"
              :items="dependents"
              optionLabel="name"
              :value="formData.applicant_1_dependants"
            />
          </div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>New loan repayment monthly</div>
            <InputWithIcon
              placeholder="TBD"
              :format="true"
              :icon="dollar"
              :inValid="count === 1 && v$.formData.new_loan_repayment_monthly.$invalid && onSave"
              class="disable-input"
              :disable="true"
              mode="decimal"
              type="number"
              width="100%"
              @onInput="getFormData($event ? Number($event) : 0, 'new_loan_repayment_monthly')"
              :value="new_loan_repayment_monthly"
              :key="repaymentKey"
            />
          </div>
        </div>
        <div class="align-items-center" :class="isMD ? '' : 'flex'">
          <div v-if="!isMD" class="col" :class="isMD ? '' : 'col-4'"></div>
          <div v-if="!isMD" class="col" :class="isMD ? '' : 'col-4'"></div>
          <div class="col" :class="isMD ? '' : 'col-4'">
            <div>Total commitments</div>
            <InputWithIcon
              :disable="true"
              placeholder="TBD"
              class="disable-input"
              :icon="dollar"
              width="100%"
              :value="total_commitments"
              :key="commitMentsKey"
              mode="decimal"
              type="number"
              :format="true"
            />
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { integer, maxValue, minValue, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Card from 'primevue/card';
import Dollar from '@/assets/dollar.svg';
import ScreenSize from '@/mixins/screensize.mixin';
import moment from 'moment';

export default {
  name: 'AdditionalInformation',
  setup: () => ({ v$: useVuelidate() }),
  mixins: [ScreenSize],
  props: ['item', 'total_commitments', 'new_loan_repayment_monthly', 'hem_value'],
  components: {
    Card,
  },
  data() {
    return {
      commitMentsKey: 20,
      age1: '',
      age2: '',
      dollar: Dollar,
      formData: {
        // joint_application: "",
        applicant_2_relationship_with_applicant_1: '',
        application_expenses: '',
        applicant_1_annual_income: 0,
        applicant_2_annual_income: 0,
        net_income_per_month: 0,
        applicant_1_relationship: '',
        applicant_2_relationship: '',
        new_loan_repayment_monthly: 0,
        applicant_1_dependants: '',
        applicant_2_dependants: '',
        applicant_1_dob: '',
        applicant_2_dob: '',
      },
      relationship: [
        { name: 'Married/Defacto', value: 'married' },
        { name: 'Friends', value: 'friend' },
        { name: 'Sibilings', value: 'sibilings' },
        { name: 'Other', value: 'other' },
      ],
      relationList: [
        { name: 'Married/Defacto', value: 'married' },
        { name: 'Friends', value: 'friend' },
        { name: 'Sibilings', value: 'sibilings' },
        { name: 'Other', value: 'other' },
      ],
      relationshipStatus: [
        { name: 'Married/Defacto', value: 'married' },
        { name: 'Single', value: 'single' },
      ],
      relationshipStatusFor2: [
        { name: 'Married/Defacto', value: 'marriedstatus' },
        { name: 'Single', value: 'single' },
      ],
      dependents: [
        { value: '0', name: '0' },
        { value: '1', name: '1' },
        { value: '2', name: '2' },
        { value: '3', name: '3+' },
      ],
      netIncomeKey: 10,
      repaymentKey: 20,
      count: 1,
      form2key: 30,
      hemKey: 40,
    };
  },
  validations() {
    if (this.count == 1) {
      return {
        formData: {
          // application_expenses: {
          //   required,
          //   minValue: minValue(0),
          //   integer,
          //   maxValue: maxValue(999999999),
          // },
          applicant_1_annual_income: {
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          net_income_per_month: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(999999999),
          },
          applicant_1_relationship: {
            required,
          },
          new_loan_repayment_monthly: {
            required,
            minValue: minValue(0),
            integer,
            maxValue: maxValue(999999999),
          },
          applicant_1_dependants: { required },
          applicant_1_dob: { required },
        },
      };
    } else if (this.count == 2) {
      if (this.formData.applicant_2_relationship_with_applicant_1 === 'married') {
        return {
          formData: {
            applicant_2_relationship_with_applicant_1: {
              required,
            },
            // application_expenses: {
            //   required,
            //   minValue: minValue(0),
            //   integer,
            //   maxValue: maxValue(999999999),
            // },
            applicant_1_annual_income: {
              minValue: minValue(0),
              maxValue: maxValue(999999999),
            },
            applicant_2_annual_income: {
              minValue: minValue(0),
              maxValue: maxValue(999999999),
            },
            net_income_per_month: {
              required,
              minValue: minValue(0),
              maxValue: maxValue(999999999),
            },
            // applicant_1_relationship: {
            //   required,
            // },
            // applicant_2_relationship: {
            //   required,
            // },
            new_loan_repayment_monthly: {
              required,
              minValue: minValue(0),
              integer,
              maxValue: maxValue(999999999),
            },
            applicant_1_dependants: { required },
            // applicant_2_dependants: {
            //   required,
            // },
            applicant_1_dob: { required },
            applicant_2_dob: { required },
          },
        };
      } else {
        return {
          formData: {
            applicant_2_relationship_with_applicant_1: {
              required,
            },
            // application_expenses: {
            //   required,
            //   minValue: minValue(0),
            //   integer,
            //   maxValue: maxValue(999999999),
            // },
            applicant_1_annual_income: {
              minValue: minValue(0),
              integer,
              maxValue: maxValue(999999999),
            },
            applicant_2_annual_income: {
              minValue: minValue(0),
              integer,
              maxValue: maxValue(999999999),
            },
            net_income_per_month: {
              required,
              minValue: minValue(0),
              maxValue: maxValue(999999999),
            },
            applicant_1_relationship: {
              required,
            },
            applicant_2_relationship: {
              required,
            },
            new_loan_repayment_monthly: {
              required,
              minValue: minValue(0),
              integer,
              maxValue: maxValue(999999999),
            },
            applicant_1_dependants: { required },
            applicant_2_dependants: {
              required,
            },
            applicant_1_dob: { required },
            applicant_2_dob: { required },
          },
        };
      }
    }
  },
  watch: {
    total_commitments() {
      this.commitMentsKey += 1;
    },
    new_loan_repayment_monthly() {
      this.repaymentKey += 1;
    },
    hem_value() {
      this.formData.application_expenses = this.hem_value;
      this.hemKey += 1;
      this.setApplicantDetails({
        key: 'application_expenses',
        value: Number(this.formData.application_expenses),
      });
    },
    count() {
      // if (this.count === 1) {
      this.$emit('validateApplicant', this.v$.$invalid);
      this.formData.applicant_2_relationship_with_applicant_1 = '';
      this.formData.applicant_2_annual_income = 0;
      this.formData.applicant_2_relationship = '';
      this.formData.applicant_2_dependants = '';
      this.formData.applicant_1_annual_income = 0;
      this.formData.applicant_1_relationship = '';
      this.formData.new_loan_repayment_monthly = 0;
      this.formData.applicant_1_dependants = '';
      this.formData.applicant_1_dob = '';
      this.formData.applicant_2_dob = '';
      // this.formData.application_expenses = 0;
      this.age2 = '';
      this.age1 = '';
      this.setApplicantDetails({
        key: 'applicant_2_relationship_with_applicant_1',
        value: this.formData.applicant_2_relationship_with_applicant_1,
      });
      this.setApplicantDetails({
        key: 'applicant_2_annual_income',
        value: this.formData.applicant_2_annual_income,
      });
      this.setApplicantDetails({
        key: 'applicant_2_relationship',
        value: this.formData.applicant_2_relationship,
      });
      this.setApplicantDetails({
        key: 'applicant_2_dependants',
        value: this.formData.applicant_2_dependants,
      });
      this.setApplicantDetails({
        key: 'applicant_2_dob',
        value: this.formData.applicant_2_dob,
      });
      this.formData.net_income_per_month =
        (this.formData.applicant_1_annual_income + this.formData.applicant_2_annual_income) / 12;
      this.netIncomeKey += 1;
      this.setApplicantDetails({
        key: 'net_income_per_month',
        value: this.formData.net_income_per_month,
      });
      this.$emit('validateApplicant', this.v$.$invalid);
      this.setApplicant2Details({ dob: '', age: 0 });
      this.setApplicant1Details({ dob: '', age: 0 });
      // }
    },
  },
  computed: {
    ...mapGetters({
      onSave: 'dashboard/onSave',
    }),
  },
  methods: {
    ...mapActions({
      setApplicantDetails: 'dashboard/setApplicantDetails',
      setApplicant2Details: 'dashboard/setApplicant2Details',
      setApplicant1Details: 'dashboard/setApplicant1Details',
    }),
    getFormData(value, fieldName) {
      if (fieldName === 'applicant_2_relationship_with_applicant_1' && value === 'married') {
        this.formData.applicant_1_relationship = 'married';
        this.setApplicantDetails({
          key: 'applicant_1_relationship',
          value: this.formData.applicant_1_relationship,
        });
        this.formData.applicant_2_relationship = 'married';
        this.setApplicantDetails({
          key: 'applicant_2_relationship',
          value: this.formData.applicant_2_relationship,
        });
      }
      this.formData[fieldName] = value;
      // account for instances where we get 3+ we treat same as 3
      if (fieldName === 'applicant_1_dependants' && value === '3+') {
        this.formData[fieldName] = '3';
      }

      this.formData = { ...this.formData };
      this.setApplicantDetails({
        key: fieldName,
        value: value,
      });
      if (fieldName === 'applicant_1_annual_income' || fieldName === 'applicant_2_annual_income') {
        if (value == 0) {
          this.formData[fieldName] = '';
          this.form2key += 1;
        }
        this.formData.net_income_per_month =
          (this.formData.applicant_1_annual_income + this.formData.applicant_2_annual_income) / 12;
        this.netIncomeKey += 1;
        this.setApplicantDetails({
          key: 'net_income_per_month',
          value: this.formData.net_income_per_month,
        });
      }
      this.$emit('validateApplicant', this.v$.$invalid);
      this.form2key += 1;
    },
    getApplicant1Data(value) {
      this.age1 = this.getAge(value);
      if (this.age1 >= 18) {
        this.formData.applicant_1_dob = value;
        this.setApplicant1Details({ dob: value, age: this.age1 });
      } else {
        this.formData.applicant_1_dob = '';
      }
      this.$emit('validateApplicant', this.v$.$invalid);
    },
    getApplicant2Data(value) {
      this.age2 = this.getAge(value);
      if (this.age2 >= 18) {
        this.formData.applicant_2_dob = value;
        this.setApplicant2Details({ dob: value, age: this.age2 });
      } else {
        this.formData.applicant_2_dob = '';
      }
      this.$emit('validateApplicant', this.v$.$invalid);
    },
    getAge(dob) {
      let currentDate = moment();
      let birthDate = moment(dob);
      var diff = currentDate.diff(birthDate, 'years');
      return diff;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.error {
  color: red;
  font-size: 12px;
}
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  min-height: 282px !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 1rem !important;
}
.margin-top {
  margin-top: -1rem;
}
</style>
