<template>
  <div class="authentication-wrapper">
    <Header />
    <div class="login-screen flex justify-content-center"><Login /></div>
    <Footer v-if="!isMD" />
  </div>
</template>

<script>
import Footer from './Footer.vue';
import Header from './Header.vue';
import Login from './Login.vue';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  name: 'index',
  mixins: [ScreenSize],
  components: {
    Header,
    Footer,
    Login,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.login-screen {
  width: 100%;
  margin: 2rem auto;
}
.authentication-wrapper {
  flex-direction: column;
  height: 100vh;
  display: flex;
  background: linear-gradient(180deg, #eef4fa 0%, #ffffff 100%);

  @media (min-width: 992px) {
    justify-content: space-between;
  }
}
</style>
