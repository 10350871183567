<template>
  <div class="p-toolbar">
    <div class="logo">
      <img v-if="customLogo" :src="customLogo" class="cursor-pointer logo-icon" @click="goToLogin" />
      <img v-else src="@/assets/bridgit.svg" class="cursor-pointer logo-icon" @click="goToLogin" />
    </div>
    <div class="header-toolbar flex">
      <img src="@/assets/phone.svg" class="p-mr-3" />
      <div class="header-info">
        <p class="phone-info">
          <a :href="`tel:+${phoneInfo.replace(' ', '')}`" target="_blank">{{ phoneInfo }}</a>
        </p>
        <span class="week-info">Weekdays 9am - 5:30pm</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(['setLogout']),
    goToLogin() {
      this.setLogout({ token: '' });
      history.pushState(null, null, `${location.origin}/login`);
      history.go(1);
      location.reload();
    },
  },
  computed: {
    customLogo() {
      return process.env.VUE_APP_CUSTOM_THEME_LOGO;
    },
    phoneInfo() {
      return process.env.VUE_APP_CUSTOM_THEME_CONTACT ?? '1300 141 161';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.header-toolbar {
  font-family: Gordita;
}
.p-toolbar {
  background: $white-color;
  padding: 0 2rem;
  height: 80px;
  display: flex;
  align-items: center;

  .logo {
    height: 40px;
    padding-top: 4px;
    padding-right: 1rem;
    flex: 1;

    @media (min-width: 768px) {
      height: 50px;
      padding-top: 5px;
    }

    img {
      height: 100%;
    }
  }

  .phone {
    height: 44px;
    flex-shrink: 1;

    @media (min-width: 768px) {
      height: 55px;
    }

    img {
      height: 100%;
      max-width: 100%;
    }
  }
}

.phone-info {
  margin: 0px;
  font-size: 24px;
  color: $secondary-color;
  line-height: 27px;

  a {
    color: $secondary-color !important;
    cursor: pointer;
    text-decoration: none;
  }
}
.week-info {
  font-size: 11px;
  color: $secondary-color;
  position: absolute;
}
.logo-icon {
  height: 50px;
}
@media (max-width: 480px) {
  .phone-info a {
    cursor: pointer;
    color: $secondary-color !important;
    pointer-events: auto;
  }
  .phone-info {
    font-size: 14px;
  }
  .week-info {
    font-size: 9px;
    display: none;
  }
  .header-info {
    display: flex;
    align-items: center;
  }
}
</style>
