<template>
  <svg :style="iconStyle">
    <use :xlink:href="`#${name}`" />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    iconStyle: function () {
      const iconsSize = this.size ?? '1em';
      return `
          width: ${iconsSize};
          min-width: ${iconsSize};
          height: ${iconsSize};
        `;
    },
  },
};
</script>
