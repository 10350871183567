<template>
  <div>
    <h1 class="module-title">Licensing/registration details</h1>
    <Dropdown
      width="100%"
      class="mt-3"
      label="Licensing/registration details"
      @change="getFormData($event, 'licensingType')"
      :value="formData.licensingType"
      :items="items"
      placeholder="-Select-"
    />
    <div class="mt-2" :class="isMD ? '' : 'flex'">
      <div class="col" :class="isMD ? '' : 'col-6'">
        <TextField
          :width="isMD ? '100%' : '95%'"
          class="mt-3"
          label="Business name"
          @onInput="getFormData($event, 'businessName')"
          :value="formData.businessName"
          placeholder="Name of business"
        />
      </div>
      <div class="col" :class="isMD ? '' : 'col-6 ml-2'">
        <TextField
          class="mt-3"
          :width="isMD ? '100%' : '95%'"
          label="ABN/ACN"
          @onInput="getFormData($event, 'abn/acn')"
          :value="formData['abn/acn']"
          placeholder="Your ABN/ACN number"
        />
      </div>
    </div>
    <div class="flex mt-2">
      <div>
        <p class="mt-3 p-float-label label hk-grotest-medium">Are you GST Registered?</p>
        <form action class="flex align-items-center">
          <div class="radio-input flex align-items-center">
            <input v-model="formData.gstRegistered" id="tes" type="radio" name="gst" value="yes" />
            <label class="radio-label ml-2 hk-grotest-medium" for="yes">Yes</label>
          </div>
          <div class="radio-input flex align-items-center ml-3">
            <input v-model="formData.gstRegistered" id="no" type="radio" name="gst" value="no" />
            <label class="radio-label ml-2 hk-grotest-medium" for="no">No</label>
          </div>
        </form>
      </div>
    </div>
    <div class="flex align-items-center">
      <div @click="goToNext">
        <Button
          class="mt-4"
          icon="pi pi-chevron-right"
          iconPos="right"
          label="Next"
          type="primary"
          :disabled="isSubmitDisabled()"
        />
      </div>
      <div class="back mt-3 ml-4 cursor-pointer" @click="goBack">Back</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'Licensing',
  mixins: [Screensize],
  components: {},
  data() {
    return {
      formData: {
        businessName: '',
        'abn/acn': '',
        licensingType: '',
        gstRegistered: '',
      },
      items: [
        'I am a Credit Representative (CR) of a holder of an Australian Credit Licence (ACL)',
        'I have been issued with an ACL',
        'I am a director or a holder of an ACL',
        'I am an employee of a holder of an ACL',
      ],
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        businessName: { required },
        'abn/acn': { required },
        licensingType: { required },
        gstRegistered: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      'referralUpdateSubProgressList',
      'referralUpdateApplicantProgress',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedSubProgressIndex',
      'referralUpdateSelectedApplicantPrgressIndex',
      'referralUpdateIsActiveValue',
    ]),
    ...mapActions({
      setReferralFormData: 'brokerSignup/setReferralFormData',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    goBack() {
      let currentObj = this.referralSubProgressList[2];
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      this.referralUpdateSubProgressList(currentObj);
      this.referralUpdateSelectedSubProgressIndex(1);
      let prevObj = this.referralSubProgressList[1];
      prevObj[0].isActive = true;
      prevObj[0].color = '#54B7F9';
      prevObj[0].isCompleted = false;
      this.referralUpdateSubProgressList(prevObj);
      this.referralUpdateApplicantProgress(25);
      this.referralUpdateSelectedApplicantPrgressIndex(1);
      this.$router.push('/referral-signup/proof-of-identity');
    },
    goToNext() {
      let currentObj = this.referralSubProgressList[this.referralSelectedSubProgressIndex];
      currentObj[0].isCompleted = true;
      currentObj[0].isActive = false;
      currentObj[0].color = 'rgba(255, 255, 255, 0.3)';
      currentObj[1].isActive = true;
      currentObj[1].color = '#54B7F9';
      this.referralUpdateSubProgressList(currentObj);
      this.setReferralFormData(this.formData);
      this.$router.push('/referral-signup/company-address');
    },
  },
  computed: {
    ...mapGetters(['referralSelectedSubProgressIndex', 'referralSubProgressList']),
    ...mapGetters('brokerSignup', ['referralFormData']),
  },
  created() {
    this.formData.businessName = this.referralFormData.businessName ? this.referralFormData.businessName : '';
    this.formData['abn/acn'] = this.referralFormData['abn/acn'] ? this.referralFormData['abn/acn'] : '';
    this.formData.licensingType = this.referralFormData.licensingType ? this.referralFormData.licensingType : '';
    this.formData.gstRegistered = this.referralFormData.gstRegistered ? this.referralFormData.gstRegistered : '';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title-text {
  max-width: 350px;
  color: $secondary-color;
  font-size: 16px;
}
.col {
  padding: unset;
}
.back {
  color: rgba(2, 0, 56, 0.4);
  font-weight: bold;
  font-size: 18px;
}
input[type='radio'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(2, 0, 56, 0.3);
  border-radius: 50%;
  outline: none;
}
input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: $secondary-color;
}
</style>
