import { httpClient } from './Authentication/interceptor';

/**
 * Get API Service
 * @param {String} path API URL
 * @param {Object} params Query Parameters
 * @returns
 */
export const _get = async (path, params = {}) => {
  return await httpClient.get(path, params);
};

/**
 * Post API Service
 * @param {String} path API URL
 * @param {any} body Body Data
 * @param {Object} params Query Parameters
 * @returns
 */
export const _post = async (path, body, params = {}) => {
  return await httpClient.post(path, body, params);
};

/**
 * Post API Service
 * @param {String} path API URL
 * @param {any} body Body Data
 * @param {Object} params Query Parameters
 * @returns
 */
export const _patch = async (path, body, params = {}) => {
  return await httpClient.patch(path, body, params);
};

/**
 * Update/Put API Service
 * @param {String} path API URL
 * @param {any} body Body Data
 * @param {Object} params Query Parameters
 * @returns
 */
export const _put = async (path, body, params = {}) => {
  return await httpClient.put(path, body, params);
};

/**
 * Delete API Service
 * @param {String} path API URL
 * @param {Object} params Query Parameters
 * @returns
 */
export const _delete = async (path, params = {}) => {
  return await httpClient.delete(path, params);
};
