<template>
  <div id="app">
    <Toast position="top-right" />
    <SvgSprites />
    <router-view></router-view>
  </div>
</template>

<script>
import SvgSprites from './components/common/SvgSprites.vue';
import Toast from 'primevue/toast';
export default {
  name: 'App',
  components: {
    Toast,
    SvgSprites,
  },
  mounted() {
    const href = window.location.href;
    const envClass = href.includes('/staging-')
      ? 'env-staging'
      : href.includes('/dev-')
      ? 'env-dev'
      : href.includes('/localhost:')
      ? 'env-local'
      : null;

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    if (process.env.VUE_APP_CUSTOM_THEME_ICON) {
      link.href = process.env.VUE_APP_CUSTOM_THEME_ICON;
    } else {
      link.href = `/favicon-${envClass || 'env-prod'}.png`;
    }
    if (process.env.VUE_APP_CUSTOM_THEME_AGGREGATOR) {
      document.title = `${process.env.VUE_APP_CUSTOM_THEME_AGGREGATOR} Broker Portal`;
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/global.scss';
#app {
  font-family: HK Grotesk !important;
}
</style>
