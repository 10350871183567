<template>
  <div class="completed-task-container">
    <h3 class="title">Completed</h3>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else>
      <div v-if="!completedTasksList.length" class="completed">
        <div class="icon-wrapper mt-6">
          <img class="mt-5" :src="require(`@/assets/uncomplete_task_icon.svg`)" alt="" />
        </div>
        <p class="text lt-txt-color">You have not completed any tasks</p>
      </div>
      <div v-else class="list-wrapper mt-3 mr-4">
        <div v-for="task of completedTasksList" :key="task.id" class="list p-d-flex p-ai-center">
          <div
            :style="
              width < 552 && {
                border: `1.5px solid ${
                  task.status === 'open' ? '#ffbcbd' : task.status == 'review' ? '#a4bfe0' : '#2cdd80'
                } !important`,
                color: `${task.status === 'open' ? '#ffbcbd' : task.status == 'review' ? '#a4bfe0' : '#2cdd80'}`,
                background: '#ffffff',
              }
            "
            @click="width < 552 && task.status === 'review' && task.isButton && amendTask(task.id)"
            class="icon-content-wrapper col-7"
          >
            <img v-if="task.status === 'review'" :src="require(`@/assets/clock.svg`)" alt="" class="mb-none" />
            <img v-if="task.status === 'completed'" :src="require(`@/assets/tick_icon.svg`)" class="mb-none" alt="" />

            <div class="content">
              <p class="heading">
                {{ fromSnakeCase(task.taskTitle) }}
              </p>
              <p class="review" v-if="task.status === 'review'">Currently under review</p>
              <p class="review" v-if="task.status === 'completed'">Complete</p>
            </div>
          </div>
          <Button
            v-if="
              (task.status === 'review' || task.status === 'in_progress') &&
              task.taskTitle != 'accept_unconditional_offer'
            "
            @click="amendTask(task)"
            class="ml-2 mb-none"
            :label="'Amend'"
            type="primary"
            width="120px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get } from '@/services/crudService';
import { fromSnakeCase } from '@/services/formatHelper';
import { mapGetters } from 'vuex';

export default {
  props: ['completed', 'progressStatus', 'loanStatus'],
  data() {
    return {
      completedTasksList: [],
      isLoading: false,
      width: '',
    };
  },
  computed: {
    ...mapGetters({
      selectedApplicationId: 'applications/selectedApplicationId',
    }),
  },
  async created() {
    this.isLoading = true;
    const result = await _get(TECHLEND_BE_URL + API.TASKS.LIST_TASKS + `?applicationId=${this.selectedApplicationId}`);
    this.completedTasksList =
      result && result.data && result.data.tasks
        ? result?.data?.tasks.filter((task) => task.status !== 'not_yet_started')
        : [];
    this.isLoading = false;
    this.width = window.innerWidth;
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    fromSnakeCase,
    onResize() {
      this.width = window.innerWidth;
    },
    async amendTask(selectedTask) {
      this.$emit('amend', selectedTask);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.completed-task-container {
  width: 50%;
  border-left: 1px solid #cecece;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (max-width: 922px) {
    width: 100%;
  }

  .title {
    margin-top: 2rem;
  }

  .completed {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }

  .icon-wrapper {
    .icon {
      font-size: 4.5em;
      color: $primary-text-color;
    }
  }

  .text {
    font-size: 1rem;
    color: #808080;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .list {
    border-bottom: 1px solid #bbbbbb;
    gap: 1.5rem;
    padding-bottom: 1rem;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .icon-content-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .icon {
        font-size: 1.7rem;
        color: $primary-color;
      }
    }

    .content {
      .heading {
        margin: 0;
        color: $primary-text-color;
        font-weight: bold;
        font-size: 1rem;
      }

      .text {
        margin: 0;
        color: #808080;
        font-size: 0.9rem;
      }
    }
  }
}

.review {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(2, 0, 56, 0.3);
}

@media (max-width: 545px) {
  .mb-none {
    display: none;
  }

  .icon-content-wrapper {
    width: 100% !important;
    display: block !important;
    text-align: center;
    padding: 0;
    flex: 100%;
    border: 1px solid black;
    border-radius: 4px;
    padding: 1rem;
  }

  .text,
  .review {
    margin-bottom: 0;
  }

  .list {
    justify-content: center !important;
  }
}
</style>
