<template>
  <div class="container">
    <Card class="card-width shadow-lg mt-4 mb-4">
      <template #content>
        <div class="text-center p-2">
          <h1 class="font-bold text-navy mb-4">Thank you for booking a call with us.</h1>
          <p class="text-lg text-navy mb-6">Our team will get in touch with you as requested.</p>
          <div class="justify-center mb-6">
            <img class="icon" :src="require('@/assets/completed-check-mark.svg')" width="80" height="80" />
          </div>
          <h2 class="font-bold text-navy mb-4">We’re here to help, if you have any questions get in touch.</h2>
          <h3 class="font-bold text-navy">Weekdays 9am - 5:30pm | 1300 836 048</h3>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card';

export default {
  components: {
    Card,
  },
};
</script>

<style scoped>
.text-navy {
  color: #0c1744;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
}

.card-width {
  width: 100%;
  max-width: 980px;
}
</style>
