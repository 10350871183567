<template>
  <div class="schedule-call-container">
    <JotForm src="https://form.jotform.com/242668717037868" frameborder="0" />
  </div>
</template>

<script>
import JotForm from '@/components/common/JotForm.vue';

export default {
  components: {
    JotForm,
  },
};
</script>

<style lang="scss" scoped>
.schedule-call-container {
  width: 100%;
}
</style>
