<template>
  <div class="mb-3">
    <label class="p-float-label hk-grotest-medium">{{ label }}</label>
    <div class="input-wrapper" :style="{ width: width }">
      <span v-if="icon" class="icon-container"
        ><img :class="disable ? 'disable-input-bg' : ''" width="10" height="10" :src="icon" class="icons field"
      /></span>
      <InputNumber
        :mode="mode"
        :minFractionDigits="minFractionDigits"
        id="integeronly"
        v-if="type === 'number'"
        :class="inValid && 'p-invalid'"
        class="input-field"
        :placeholder="placeholder"
        :format="format ? format : false"
        :max="max"
        min="0"
        v-model="editValue"
        @input="onNumberInput"
        :disabled="disable"
        @keydown="keydown"
        autocomplete="off"
      />
      <InputText
        v-else
        v-model="editValue"
        :class="inValid && 'p-invalid'"
        class="input-field"
        @input="onInput"
        :placeholder="placeholder"
        :type="type"
        :disabled="disable"
        :key="inputKey"
        min="0"
        :style="{
          width: '100%',
          height: `${height}`,
          border: `1px solid ${color} !important`,
          color: `${color}`,
        }"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';

export default {
  name: 'TextField',
  props: [
    'icon',
    'placeholder',
    'label',
    'height',
    'width',
    'border',
    'color',
    'value',
    'required',
    'errorMessages',
    'type',
    'disable',
    'format',
    'max',
    'mode',
    'minFractionDigits',
    'maxDigit',
    'maxValue',
    'inValid',
  ],
  data() {
    return {
      editValue: '',
      inputKey: 0,
    };
  },
  components: { InputText, InputNumber },
  methods: {
    onInput({ target }) {
      this.$emit('onInput', target.value);
    },
    onNumberInput(value) {
      // if (this.maxValue) {
      //   if (value.value > this.maxValue) {
      //     this.editValue = 0.25;
      //     this.refreshKey += 1;
      //     this.$emit("onInput", this.editValue);
      //     return;
      //   }
      // }
      this.editValue = value.value;
      this.$emit('onInput', value.value);
    },
    // onNumberInput(value) {
    //   if (this.maxValue) {
    //     if (value.value < this.maxValue) {
    //       this.editValue = value.value;
    //       this.$emit("onInput", this.editValue);
    //       this.refreshKey += 1;
    //       return;
    //     }
    //   }
    //   this.editValue = value.value;
    //   this.$emit("onInput", value.value);
    // },
    keydown(e) {
      let max = this.maxDigit ? this.maxDigit : 8;
      if (this.editValue && this.editValue.toString().length > max) {
        e.preventDefault();
        return false;
      }
    },
  },
  watch: {
    editValue() {
      this.$emit('change', this.editValue);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

:deep(.p-inputnumber.p-invalid.p-component > .p-inputtext) {
  border: 1px solid $pink-color !important;
}

.input-wrapper {
  display: flex;
  align-items: center;
}
.input-field {
  border-color: unset;
  outline: 0 none;
  width: 100%;
  outline-offset: 0;
  box-shadow: unset !important;
  border-radius: unset !important;
}

.icons {
  font-weight: bold !important;
  height: 42px;
  font-size: 1.2rem;
  padding: 10px;
  min-width: 40px;
  border: 1px solid #ced4da;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-bottom: 11px;
  background: #ffffff;
}

:deep(.field) {
  margin-bottom: unset !important;
}

:deep(.p-inputtext) {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  height: 42px;
  width: 100%;
}
:deep(.p-inputtext:enabled:focus) {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
:deep(.p-inputtext:enabled:hover) {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
:deep(.p-inputtext:enabled:focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
.required {
  color: red;
}
.error-text {
  font-size: 14px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
