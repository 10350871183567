<template>
  <div>
    <div>
      <div v-if="isDashboard">
        <!-- <h1 class="module-title">Dashboard</h1> -->
        <div class="justify-content-between mt-3" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-9'">
            <Application />
            <ContactCards @refer="isDashboard = false" />
          </div>
          <div class="col" :class="isMD ? 'mt-2' : 'col-3 ml-3'">
            <ToDo />
          </div>
        </div>
      </div>
      <!-- <div v-else>
        <h1 class="module-title">Refer a client</h1>
        <div class="justify-content-between mt-3" :class="isMD ? '' : 'flex'">
          <div class="col" :class="isMD ? '' : 'col-8'">
            <ReferAndEarn />
            <div class="mt-3" :class="isMD ? '' : 'flex'">
              <SendReferral class="col" :class="isMD ? '' : 'col-7'" />
              <ReferanceLink class="col" :class="isMD ? 'mt-3' : 'ml-2'" />
            </div>
          </div>
          <div class="col" :class="isMD ? '' : 'col-4 ml-3'">
            <Earnings />
            <Payment class="mt-4" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Application from './Applications.vue';
import ToDo from './ToDo.vue';
// import Earnings from "./Earnings.vue";
// import SendReferral from "./SendReferral.vue";
// import ReferAndEarn from "./ReferAndEarn.vue";
// import ReferanceLink from "./ReferanceLink.vue";
import ContactCards from './Contact.vue';
// import Payment from "./Payment.vue";
import { mapGetters } from 'vuex';
import ScreenSize from '@/mixins/screensize.mixin';

export default {
  name: 'index',
  mixins: [ScreenSize],
  components: {
    Application,
    ToDo,
    // Earnings,
    // SendReferral,
    // ReferAndEarn,
    // ReferanceLink,
    ContactCards,
    // Payment,
  },
  data() {
    return {
      isDashboard: true,
    };
  },
  computed: {
    ...mapGetters(['brokerType']),
  },
  methods: {
    goToCalculator() {
      this.$router.push('/servicing-calculator');
    },
    goToClient() {
      this.isDashboard = false;
    },
  },
  created() {
    this.isDashboard = this.brokerType === 'broker';
    if (this.$route.query && this.$route.query.type) {
      this.isDashboard = this.$route.query.type === 'broker' ? true : false;
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
  text-align: center !important;
}
:deep(.p-card-content) {
  font-size: 1rem !important;
  text-align: center !important;
}

.card-a {
  height: 250px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  p {
    font-family: HK Grotesk !important;
    text-align: center;
    font-weight: 700;
    color: $secondary-color;
    font-size: 20px;
    margin: 0;
  }
}

.flex-gap {
  gap: 30px;
}

.white-sp-nowrap {
  white-space: nowrap;
}
</style>
